import React, { useState, useEffect, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { IoTrashOutline } from "react-icons/io5";
import { LuPencilLine } from "react-icons/lu";
import { FaFilePdf } from "react-icons/fa6";
import { FaEye } from "react-icons/fa";
import { Modal, Button, Spinner } from 'react-bootstrap';
import EditarNotaFiscal from './EditarNotaFiscal';
import * as XLSX from 'xlsx';

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = date.getUTCDate().toString().padStart(2, '0');
    return `${day}-${month}-${year}`;
};

const NotasFiscales = () => {
    const [loading, setLoading] = useState(true);
    const [dataNF, setDataNF] = useState([]);
    const [dataProveedores, setDataProveedores] = useState([]);
    const [dataDetalle, setDataDetalle] = useState([]);
    const firstRender = useRef(true);
    const [showAgregar, setShowAgregar] = useState(false);
    const [showEditar, setShowEditar] = useState(false);
    const [selected, setSelected] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({ title: '', body: '', variant: '' });
    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedYear, setSelectedYear] = useState('');

    const fetchData = async () => {
        const token = localStorage.getItem('token');
        try {
            const responseNF = await fetch('https://api.dvstarlab.com/notasfiscales', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (responseNF.status === 401) {
                throw new Error('Unauthorized');
            }
            const dataNF = await responseNF.json();
            setDataNF(dataNF);

            const responseProveedores = await fetch('https://api.dvstarlab.com/proveedores', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (responseProveedores.status === 401) {
                throw new Error('Unauthorized');
            }
            const dataProveedores = await responseProveedores.json();
            setDataProveedores(dataProveedores);

            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
            alert("Error recibiendo los datos.");
        }
    };

    useEffect(() => {
        if (firstRender.current) {
            fetchData();
            firstRender.current = false;
        }
    }, []);

    useEffect(() => {
        const filterByText = dataNF.filter(row =>
            Object.values(row).some(value =>
                value?.toString().toLowerCase().includes(filterText.toLowerCase())
            )
        );

        const filterByDate = filterByText.filter(row => {
            const date = new Date(row.emision);
            const monthMatch = selectedMonth ? date.getMonth() + 1 === parseInt(selectedMonth) : true;
            const yearMatch = selectedYear ? date.getFullYear() === parseInt(selectedYear) : true;
            return monthMatch && yearMatch;
        });

        const filteredWithProveedor = filterByDate.map(row => ({
            ...row,
            proveedor_nombre: dataProveedores.find(prov => prov.id_proveedor === row.id_proveedor)?.nombre || 'Desconocido'
        }));

        setFilteredData(filteredWithProveedor);
    }, [dataNF, filterText, selectedMonth, selectedYear, dataProveedores]);

    const columns = [
        {
            name: 'Nro NF',
            selector: row => row.nro_nf,
            sortable: true,
        },
        {
            name: 'Proveedor',
            selector: row => row.proveedor_nombre,
            sortable: true,
        },
        {
            name: 'Fecha Emisión',
            selector: row => formatDate(row.emision),
            sortable: true,
        },
        {
            name: 'Valor Total',
            selector: row => row.valor_total,
            sortable: true,
        },
        {
            name: 'Acciones',
            cell: row => (
                <div className="d-flex gap-2">
                    <button onClick={() => handleEditar(row)} className="btn btn-sm btn-outline-warning">
                        <LuPencilLine />
                    </button>
                    <button onClick={() => handleEliminar(row.id_nota_fiscal)} className="btn btn-sm btn-outline-danger">
                        <IoTrashOutline />
                    </button>
                    <button onClick={() => handleVerDetalles(row.id_nota_fiscal)} className="btn btn-sm btn-outline-primary">
                        <FaEye />
                    </button>
                </div>
            ),
        },
    ];

    const handleAgregar = () => {
        setShowAgregar(true);
    };

    const handleEditar = (nf) => {
        setSelected(nf);
        setShowEditar(true);
    };

    const handleCloseEditar = () => {
        setSelected(null);
        setShowEditar(false);
    };

    const handleSaveEditar = (edited) => {
        const token = localStorage.getItem('token');
        fetch(`https://api.dvstarlab.com/editarnotafiscal/${edited.id_nota_fiscal}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(edited),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Error al actualizar datos');
                }
                return response.json();
            })
            .then(data => {
                alert('Datos actualizados exitosamente');
                window.location.reload();
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    const handleExportExcel = async () => {
        const token = localStorage.getItem('token');
        const allData = [];

        // Recorre cada nota fiscal filtrada y obtén sus detalles
        for (const nf of filteredData) {
            try {
                const response = await fetch(`https://api.dvstarlab.com/notasfiscales/${nf.id_nota_fiscal}/detalles`, {
                    headers: { 'Authorization': `Bearer ${token}` },
                });
                const detalles = await response.json();

                // Añadir los detalles al objeto de la nota fiscal
                detalles.forEach(detalle => {
                    allData.push({
                        'Nro NF': nf.nro_nf,
                        'Proveedor': nf.proveedor_nombre,
                        'Fecha Emisión': formatDate(nf.emision),
                        'Valor Total NF': nf.valor_total,
                        'Producto': detalle.producto,
                        'Calidad': detalle.calidad,
                        'Medida': detalle.medida,
                        'NCM': detalle.ncm,
                        'M3': detalle.m3,
                        'USD/M3': detalle.usd_m3,
                        'Total': detalle.total,
                        'Descuento': detalle.descuento,
                    });
                });
            } catch (error) {
                console.error('Error al obtener detalles para la nota fiscal:', nf.nro_nf, error);
            }
        }

        // Generar y descargar el archivo Excel
        const ws = XLSX.utils.json_to_sheet(allData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Detalles_Notas_Fiscales");
        XLSX.writeFile(wb, "Detalles_Notas_Fiscales.xlsx");
    };

    const handleEliminar = (idAEliminar) => {
        const token = localStorage.getItem('token');
        fetch(`https://api.dvstarlab.com/eliminarnotafiscal/${idAEliminar}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (response.ok) {
                    alert('Datos Eliminados Correctamente.');
                    window.location.reload();
                } else {
                    alert('Error al intentar eliminar datos.')
                }
            })
            .catch(error => {
                console.error('Error de red:', error);
            });
    };

    const handleVerDetalles = async (notaFiscalId) => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`https://api.dvstarlab.com/notasfiscales/${notaFiscalId}/detalles`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error('Error al obtener los detalles');
            }
            const detalles = await response.json();
            setDataDetalle(detalles);
            setShowModal(true);
            setModalContent({
                title: `Detalles de Nota Fiscal ${notaFiscalId}`,
                body: (
                    <ul>
                        {detalles.map((detalle, index) => (
                            <li key={index}>{detalle.producto} - Total: {detalle.total}</li>
                        ))}
                    </ul>
                ),
                variant: 'primary',
            });
        } catch (error) {
            console.error('Error al obtener detalles:', error);
        }
    };

    return (
        <div className='container-fluid'>
            <div className='card'>
                <div className='card-header'>
                    <h3 className='card-title' style={{ textAlign: 'center' }}>Notas Fiscales</h3>
                </div>
                <div className='card-body mb-3'>
                    {loading ? (
                        <>
                            <div className="text-center">
                                <Spinner animation="border" role="status" />
                            </div>
                            <div className='text-center'>
                                <span className="sr-only">Cargando...</span>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='input-group mb-3'>
                                <input
                                    type="text"
                                    placeholder='Buscar...'
                                    value={filterText}
                                    onChange={(e) => setFilterText(e.target.value)}
                                    className='form-control'
                                />
                                <select
                                    value={selectedMonth}
                                    onChange={(e) => setSelectedMonth(e.target.value)}
                                    className='form-select'
                                >
                                    <option value="">Mes</option>
                                    {[...Array(12)].map((_, i) => (
                                        <option key={i + 1} value={i + 1}>
                                            {new Date(0, i).toLocaleString('default', { month: 'long' })}
                                        </option>
                                    ))}
                                </select>
                                <select
                                    value={selectedYear}
                                    onChange={(e) => setSelectedYear(e.target.value)}
                                    className='form-select'
                                >
                                    <option value="">Año</option>
                                    {[...Array(5)].map((_, i) => {
                                        const year = new Date().getFullYear() - i;
                                        return <option key={year} value={year}>{year}</option>;
                                    })}
                                </select>
                            </div>
                            <div className='input-group mb-3'>
                                <button type='button' className='btn btn-outline-primary' onClick={() => handleAgregar()}>Agregar Nota Fiscal</button>
                                <button type='button' className='btn btn-outline-success' onClick={handleExportExcel}>Extraer Excel</button>
                            </div>
                            <div className='card'>
                                <div className='card-body'>
                                    <DataTable
                                        columns={columns}
                                        data={filteredData}
                                        noHeader
                                        responsive
                                        pagination
                                        paginationPerPage={30}
                                        highlightOnHover
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>

            {/* Modal para Detalles */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalContent.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalContent.body}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
            {showEditar && (
                <EditarNotaFiscal
                    notaFiscal={selected}
                    cerrar={handleCloseEditar}
                    guardar={handleSaveEditar}
                    proveedores={dataProveedores}
                />
            )}
        </div>
    );
};

export default NotasFiscales;
