// frontend/src/About.js
import React from 'react';

function About() {
  return (
    <div className="About">
      <header className="About-header">
        <h1>About</h1>
        <p>This is the about page.</p>
      </header>
    </div>
  );
}

export default About;
