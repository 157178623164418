import React, { useState, useEffect, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { IoTrashOutline } from "react-icons/io5";
import { LuPencilLine } from "react-icons/lu";
import { FaFilePdf } from "react-icons/fa6";
import { FaEye } from "react-icons/fa";
import AgregarProforma from './AgregarProforma';
import EditarProforma from './EditarProforma';
import VerProforma from './VerProforma';
import GenerarPDF from './GenerarPDF';
import { Modal, Button, Spinner } from 'react-bootstrap';

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = date.getUTCDate().toString().padStart(2, '0');
    return `${day}-${month}-${year}`;
};

const Proformas = () => {
    const [loading, setLoading] = useState(true);
    const [dataProformas, setDataProformas] = useState([]);
    const [dataClientes, setDataClientes] = useState([]);
    const [dataSubClientes, setDataSubClientes] = useState([]);
    const [dataIncoTerms, setDataIncoTerms] = useState([]);
    const [dataPuertos, setDataPuertos] = useState([]);
    const [dataProductos, setDataProductos] = useState([]);
    const [dataDocumentos, setDataDocumentos] = useState([]);
    const [dataUsuarios, setDataUsuarios] = useState([]);
    const [dataCategorias, setDataCategorias] = useState([]);
    const [dataMedidas, setDataMedidas] = useState([]);
    const [dataCalidades, setDataCalidades] = useState([]);
    const [dataEspecies, setDataEspecies] = useState([]);
    const [dataShippingDocs, setDataShippingDocs] = useState([]);
    const [dataPaymentTerms, setDataPaymentTerms] = useState([]);
    const firstRender = useRef(true);
    const [showAgregar, setShowAgregar] = useState(false);
    const [showEditar, setShowEditar] = useState(false);
    const [showVer, setShowVer] = useState(false);
    const [selected, setSelected] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [showPDF, setShowPDF] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({ title: '', body: '', variant: '' });


    const fetchData = async () => {
        const token = localStorage.getItem('token'); // Obtener token de autenticación
        try {
            const responseProformas = await fetch('https://api.dvstarlab.com/proformas', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (responseProformas.status === 401) {
                throw new Error('Unauthorized');
            }
            const dataProformas = await responseProformas.json();
            setDataProformas(dataProformas);

            const responseClientes = await fetch('https://api.dvstarlab.com/clientes', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (responseClientes.status === 401) {
                throw new Error('Unauthorized');
            }
            const dataClientes = await responseClientes.json();
            setDataClientes(dataClientes);

            const responseincoterms = await fetch('https://api.dvstarlab.com/incoterms', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (responseincoterms.status === 401) {
                throw new Error('Unauthorized');
            }
            const dataincoterms = await responseincoterms.json();
            setDataIncoTerms(dataincoterms);

            const responsePuertos = await fetch('https://api.dvstarlab.com/puertos', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (responsePuertos.status === 401) {
                throw new Error('Unauthorized');
            }
            const dataPuertos = await responsePuertos.json();
            setDataPuertos(dataPuertos);

            const responseProductos = await fetch('https://api.dvstarlab.com/productos', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (responseProductos.status === 401) {
                throw new Error('Unauthorized');
            }
            const dataProductos = await responseProductos.json();
            setDataProductos(dataProductos);

            const responseDocumentos = await fetch('https://api.dvstarlab.com/documentos', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (responseDocumentos.status === 401) {
                throw new Error('Unauthorized');
            }
            const dataDocumentos = await responseDocumentos.json();
            setDataDocumentos(dataDocumentos);

            const responseUsuarios = await fetch('https://api.dvstarlab.com/usuarios', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (responseUsuarios.status === 401) {
                throw new Error('Unauthorized');
            }
            const dataUsuarios = await responseUsuarios.json();
            setDataUsuarios(dataUsuarios);

            const responseCategorias = await fetch('https://api.dvstarlab.com/categorias', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (responseCategorias.status === 401) {
                throw new Error('Unauthorized');
            }
            const dataCategorias = await responseCategorias.json();
            setDataCategorias(dataCategorias);

            const responseMedidas = await fetch('https://api.dvstarlab.com/medidas', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (responseMedidas.status === 401) {
                throw new Error('Unauthorized');
            }
            const dataMedidas = await responseMedidas.json();
            setDataMedidas(dataMedidas);

            const responseCalidades = await fetch('https://api.dvstarlab.com/calidades', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (responseCalidades.status === 401) {
                throw new Error('Unauthorized');
            }
            const dataCalidades = await responseCalidades.json();
            setDataCalidades(dataCalidades);

            const responseEspecies = await fetch('https://api.dvstarlab.com/especies', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (responseEspecies.status === 401) {
                throw new Error('Unauthorized');
            }
            const dataEspecies = await responseEspecies.json();
            setDataEspecies(dataEspecies);

            const responseShippingDocs = await fetch('https://api.dvstarlab.com/shippingdocs', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (responseShippingDocs.status === 401) {
                throw new Error('Unauthorized');
            }
            const dataShippingDocs = await responseShippingDocs.json();
            setDataShippingDocs(dataShippingDocs);

            const responseSubClientes = await fetch('https://api.dvstarlab.com/subclientes', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (responseSubClientes.status === 401) {
                throw new Error('Unauthorized');
            }
            const dataSubClientes = await responseSubClientes.json();
            setDataSubClientes(dataSubClientes);

            const ptResponse = await fetch('https://api.dvstarlab.com/paymentterms', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (ptResponse.status === 401) {
                throw new Error('Unauthorized');
            }
            const ptData = await ptResponse.json();
            setDataPaymentTerms(ptData);

            setLoading(false);
            showModalPopup('Éxito', 'Datos recibidos exitosamente', 'success');
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
            showModalPopup('Error', 'Error recibiendo los datos', 'danger');
        }
    };

    const showModalPopup = (title, body, variant) => {
        setModalContent({ title, body, variant });
        setShowModal(true);
    };

    const handleCloseModal = () => setShowModal(false);

    useEffect(() => {
        if (firstRender.current) {
            fetchData();
            firstRender.current = false;
        }
    }, []);

    useEffect(() => {
        const sortedData = dataProformas.slice().sort((a, b) => a.ID_OV - b.ID_OV);
        const filtered = sortedData.filter((row) =>
            Object.values(row).some((value) =>
                value.toString().toLowerCase().includes(filterText.toLowerCase())
            )
        );
        setFilteredData(filtered);
    }, [dataProformas, filterText]);

    const columns = [
        {
            name: 'Editar',
            cell: (row) => <button className='btn btn-outline-primary' onClick={() => handleEditar(row)}><LuPencilLine /></button>,
        },
        {
            name: 'Nro PI',
            selector: row => row.correlativo,
            width: '80px',
        },
        {
            name: 'Cliente',
            selector: row => {
                let cliente = row.cliente ? dataClientes.find(cli => cli.id_cliente === row.cliente) : null;
                return cliente ? cliente.nombre : "undefined"
            },
            width: '300px',
        },
        {
            name: 'Fecha',
            selector: row => formatDate(row.fecha),
        },
        {
            name: 'Fecha Embarque',
            selector: row => formatDate(row.fecha_embarque),
        },
        {
            name: 'Valor',
            selector: row => row.valor_proforma ? parseFloat(row.valor_proforma).toFixed(2) : 'error',
        },
        
        {
            name: 'Moneda',
            selector: row => row.moneda === 0 ? "USD" : row.moneda === 1 ? "EUR" : row.moneda === 2 ? "CLP" : row.moneda === 3 ? "BRL" : row.moneda === 4 ? "PYG" : "Indefinido",
        },
        {
            name: 'Estado',
            selector: row => row.estado === 0 ? "borrador" : row.estado === 1 ? "Por aprobar" : row.estado === 2 ? "Aprobada" : row.estado === 3 ? "Aprobada x Cliente" : row.estado === 4 ? "Rechazada" : "Indefinido",
        },
        {
            name: 'Acciones',
            cell: row => (
                <div>
                    <button className='btn btn-outline-primary mr-2' onClick={() => handleVerProforma(row)}>
                        <FaEye />
                    </button>
                    <button className='btn btn-outline-primary mr-2' onClick={() => handleGenerarPDF(row)}>
                        <FaFilePdf />
                    </button>
                    <button
                        className='btn btn-outline-danger'
                        onClick={() => {
                            if(window.confirm('¿Estás seguro?')){
                                handleEliminar(row['id_proforma']);
                            }
                        }}
                    >
                        <IoTrashOutline />
                    </button>
                </div>
            ),
            ignoreRowClick: true,
        }
    ]

    const handleAgregar = () => {
        setShowAgregar(true);
    };

    const handleEditar = (proforma) => {
        setSelected(proforma);
        setShowEditar(true);
    };

    const handleSaveEditar = (edited) => {
        const token = localStorage.getItem('token');
        fetch(`https://api.dvstarlab.com/editarproforma/${edited.id_proforma}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(edited),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Error al actualizar datos');
            }
            return response.json();
        })
        .then(data => {
            console.log('Datos actualizados:', data);
            alert('Datos actualizados exitosamente');
            window.location.reload();
        })
        .catch(error => {
            console.error('Error:', error);
        });
    };

    const handleCloseEditar = () => {
        setSelected(null);
        setShowEditar(false)
    };

    const handleEliminar = (idAEliminar) => {
        const token = localStorage.getItem('token'); // Obtener token de autenticación
        fetch(`https://api.dvstarlab.com/eliminarproforma/${idAEliminar}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            if(response.ok){
                alert('Datos Eliminados Correctamente.');
                window.location.reload();
            }else{
                alert('Error al intentar eliminar datos.')
            }
        })
        .catch(error => {
            console.error('Error de red:', error);
        });
    }

    const handleVerProforma = (proforma) => {
        setSelected(proforma);
        setShowVer(true);
    }
    const handleCloseVerProforma = () => {
        setSelected(null);
        setShowVer(false)
    }

    const handleGenerarPDF = (proforma) => {
        setSelected(proforma);
        setShowPDF(true);
    }

    return(
        <div className='container-fluid'>
            <div className='card'>
                <div className='card-header'>
                    <h3 className='card-title' style={{textAlign: 'center'}}>Proformas Invoice</h3>
                </div>
                <div className='card-body mb-2'>
                    {/* Modal para mostrar el pop-up */}
                    <Modal show={showModal} onHide={handleCloseModal} centered>
                        <Modal.Header closeButton className={modalContent.variant === 'success' ? 'title-success' : 'title-danger'}>
                        <Modal.Title>
                            {modalContent.title}
                        </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>{modalContent.body}</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseModal}>
                                Cerrar
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    {loading ? (
                        <>
                        <div className="text-center">
                            <Spinner animation="border" role="status" />
                        </div>
                        <div className='text-center'>
                            <span className="sr-only">Cargando...</span>
                        </div>
                        </>
                    ) : (
                        <>
                        <div className="input-group mb-3">
                            <input
                            type="text"
                            placeholder="Buscar..."
                            value={filterText}
                            onChange={(e) => setFilterText(e.target.value)}
                            className='form-control'
                            />
                        </div>
                        <div className="input-group mb-3">
                            <button type="button" className="btn btn-outline-primary"onClick={() => handleAgregar()}>Agregar Proforma</button>
                        </div>
                        {showAgregar && 
                            <AgregarProforma clientes={dataClientes} subclientes={dataSubClientes} incoterms={dataIncoTerms} puertos={dataPuertos} productos = {dataProductos} documentos={dataDocumentos} usuarios={dataUsuarios} categorias={dataCategorias} especies={dataEspecies} medidas={dataMedidas} calidades={dataCalidades} pt={dataPaymentTerms} proformas={dataProformas} cerrar={() => setShowAgregar(false) && fetchData()} />
                        }
                        <div className="card">
                            <div className="card-body">
                                <DataTable
                                  columns={columns}
                                  data={filteredData}
                                  noHeader
                                  responsive
                                  pagination
                                  highlightOnHover
                                />
                            </div>
                        </div>
                        {selected && showEditar && (selected.estado === 0 || selected.estado === 1 || (selected.estado === 2 && parseInt(localStorage.getItem('Rol')) === 1) || selected.estado === 4) &&(
                          <EditarProforma proforma={selected} clientes={dataClientes} subclientes={dataSubClientes} incoterms={dataIncoTerms} puertos={dataPuertos} productos={dataProductos} documentos={dataDocumentos} usuarios={dataUsuarios} categorias={dataCategorias} especies={dataEspecies} medidas={dataMedidas} calidades={dataCalidades} pt={dataPaymentTerms} cerrar={handleCloseEditar} guardar={handleSaveEditar}
                          />
                        )}
                        {selected && showVer &&(
                          <VerProforma proforma={selected} clientes={dataClientes} incoterms={dataIncoTerms} puertos={dataPuertos} productos={dataProductos} documentos={dataDocumentos} usuarios={dataUsuarios} categorias={dataCategorias} especies={dataEspecies} medidas={dataMedidas} calidades={dataCalidades} proformas={dataProformas} pt={dataPaymentTerms} guardar={handleSaveEditar} cerrar={handleCloseVerProforma} />
                        )}
                        {!showVer && !showEditar && showPDF && selected &&(
                            <GenerarPDF proforma={selected} clientes={dataClientes} incoterms={dataIncoTerms} puertos={dataPuertos} productos={dataProductos} documentos={dataDocumentos} usuarios={dataUsuarios} categorias={dataCategorias} especies={dataEspecies} medidas={dataMedidas} calidades={dataCalidades} proformas={dataProformas} shippingdocs={dataShippingDocs} pt={dataPaymentTerms} cerrar={handleCloseVerProforma}/>
                        )}
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Proformas