import React, { useState } from 'react';

function EditarSubCliente({ subcliente, cerrar, guardar }) {
    const [botonDeshabilitado, setBotonDeshabilitado] = useState(false);
    const [nombre, setNombre] = useState(subcliente.nombre || '');
    const [tax, setTax] = useState(subcliente.tax || '');
    const [siglas, setSiglas] = useState(subcliente.siglas || '');
    const [direccion, setDireccion] = useState(subcliente.direccion || '');
    const [ciudad, setCiudad] = useState(subcliente.ciudad || '');
    const [pais, setPais] = useState(subcliente.pais || '');
    const [correo, setCorreo] = useState(subcliente.correo || '');
    const [telefono, setTelefono] = useState(subcliente.telefono || '');
    const [contacto, setContacto] = useState(subcliente.contacto || '');
    const [rol, setRol] = useState(subcliente.rol || '');
    const [indicador, setIndicador] = useState(subcliente.indicador || '');
    const [giro, setGiro] = useState(subcliente.giro || '');
    const [contactofinanzas, setContactoFinanzas] = useState(subcliente.contactofinanzas || '');
    const [lineacredito, setLineaCredito] = useState(subcliente.lineacredito || '');

    const handleSubmit = (event) => {
        event.preventDefault();
        setBotonDeshabilitado(true);

        const editedSubCliente = {
            ...subcliente,
            nombre,
            tax,
            siglas,
            direccion,
            ciudad,
            pais,
            correo,
            telefono,
            contacto,
            rol,
            indicador: parseInt(indicador),
            giro,
            contactofinanzas,
            lineacredito
        };

        guardar(editedSubCliente);
        cerrar();
    };

    const handleRadioChange = (event) => {
        const selectedValue = parseInt(event.target.value); // Convertir el valor a entero
        setIndicador(selectedValue);
    };

    const handleRadioChange2 = (event) => {
        const selectedValue = parseInt(event.target.value); // Convertir el valor a entero
        setLineaCredito(selectedValue);
    };

    return (
        <div className='modal-overlay'>
            <div className='modal1'>
                <div className='card'>
                    <form onSubmit={handleSubmit}>
                        <div className='card-header'>
                            <h3 style={{ textAlign: 'center' }}>Editar SubCliente</h3>
                        </div>
                        <div className='card-body'>
                        <div className='input-group mb-3'>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value={0} checked={indicador === 0} onChange={handleRadioChange}/>
                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                        Nacional
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value={1} checked={indicador === 1} onChange={handleRadioChange}/>
                                    <label className="form-check-label" htmlFor="flexRadioDefault2">
                                        OffShore
                                    </label>
                                </div>
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Nombre</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese nombre subcliente"
                                  value={nombre}
                                  onChange={(e) => setNombre(e.target.value)}
                                  required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Tax</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese tax"
                                  value={tax}
                                  onChange={(e) => setTax(e.target.value)}
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Siglas</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese siglas"
                                  value={siglas}
                                  onChange={(e) => setSiglas(e.target.value)}
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Dirección</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese dirección"
                                  value={direccion}
                                  onChange={(e) => setDireccion(e.target.value)}
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Ciudad</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese ciudad"
                                  value={ciudad}
                                  onChange={(e) => setCiudad(e.target.value)}
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>País</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese país"
                                  value={pais}
                                  onChange={(e) => setPais(e.target.value)}
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Correo</span>
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="Ingrese correo"
                                  value={correo}
                                  onChange={(e) => setCorreo(e.target.value)}
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Teléfono</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese teléfono"
                                  value={telefono}
                                  onChange={(e) => setTelefono(e.target.value)}
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Contacto</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese contacto"
                                  value={contacto}
                                  onChange={(e) => setContacto(e.target.value)}
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Rol</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese rol"
                                  value={rol}
                                  onChange={(e) => setRol(e.target.value)}
                                />
                            </div>
                            {indicador == 0 &&     
                                <div className='input-group mb-3'>
                                    <span className='input-group-text'>Giro</span>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Ingrese giro cliente"
                                      value={giro}
                                      onChange={(e) => setGiro(e.target.value)}
                                      required
                                    />
                                </div>                            
                            }
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Contacto Finanzas</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese contacto finanzas"
                                  value={contactofinanzas}
                                  onChange={(e) => setContactoFinanzas(e.target.value)}
                                />
                            </div>
                            <div className='input-group mb-2'>
                                <h5 style={{ margin: '0 auto', textAlign: 'center' }}>Línea de Crédito</h5>
                            </div>
                            <div className='input-group mb-3'>
                                <div className="form-check" style={{ margin: '0 auto', textAlign: 'center' }}>
                                    <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault3" value={0} checked={lineacredito === 0} onChange={handleRadioChange2}/>
                                    <label className="form-check-label" htmlFor="flexRadioDefault3">
                                        No tiene
                                    </label>
                                </div>
                                <div className="form-check" style={{ margin: '0 auto', textAlign: 'center' }}>
                                    <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault4" value={1} checked={lineacredito === 1} onChange={handleRadioChange2}/>
                                    <label className="form-check-label" htmlFor="flexRadioDefault4">
                                        Solicitada
                                    </label>
                                </div>
                                <div className="form-check" style={{ margin: '0 auto', textAlign: 'center' }}>
                                    <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault5" value={2} checked={lineacredito === 2} onChange={handleRadioChange2}/>
                                    <label className="form-check-label" htmlFor="flexRadioDefault5">
                                        Aprobada
                                    </label>
                                </div>
                                <div className="form-check" style={{ margin: '0 auto', textAlign: 'center' }}>
                                    <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault6" value={3} checked={lineacredito === 3} onChange={handleRadioChange2}/>
                                    <label className="form-check-label" htmlFor="flexRadioDefault6">
                                        Rechazada
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='card-footer' style={{ textAlign: 'center' }}>
                            <button type="submit" className="btn btn-outline-success mb-3 mt-3" disabled={botonDeshabilitado}>Guardar</button>
                            <button type="button" className="btn btn-outline-danger mb-3 mt-3" onClick={cerrar}>Cancelar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default EditarSubCliente;
