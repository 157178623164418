import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';

const obtenerFechaActual = () => {
  const today = new Date();
  const year = today.getFullYear();
  let month = today.getMonth() + 1;
  let day = today.getDate();

  month = month < 10 ? '0' + month : month;
  day = day < 10 ? '0' + day : day;

  return `${year}-${month}-${day}`;
};

const AgregarOrdenCompra = ({ ordenesCompra, proformas, proveedores, incoterms, puertos, productos, documentos, usuarios, categorias, especies, medidas, calidades, clientes, almacenes, cerrar }) => {
    const [botonDeshabilitado, setBotonDeshabilitado] = useState(false);
    const [emision, setEmision] = useState(obtenerFechaActual());
    const [ordenCompraBase, setOrdenCompraBase] = useState('');
    const [proforma, setProforma] = useState('');
    const [proveedor, setProveedor] = useState(0);
    const [entrega, setEntrega] = useState('');
    const [almacen, setAlmacen] = useState(0);
    const [condicion_pago, setCondicionPago] = useState('');
    const [requisitos_calidad, setRequisitosCalidad] = useState('');
    const [requisitos_etiquetado, setRequisitosEtiquetado] = useState('');
    const [incoterm, setIncoTerm] = useState(0);
    const [puerto, setPuerto] = useState(0);
    const [descripcion_producto, setDescripcionProducto] = useState('');
    const [Productos, setProductos] = useState([]);
    const [valor_ordencompra, setValorOrdenCompra] = useState(0);
    const [documentosRequeridos, SetDocumentosRequeridos] = useState([]);
    const estado = 1;
    const id = localStorage.getItem('ID');
    const [remitente, setRemitente] = useState(parseInt(id));
    const [aprobador, setAprobador] = useState(0);
    const [informacion_adicional, setInformacionAdicional] = useState('');
    const [nuevoDocumento, setNuevoDocumento] = useState(0);
    const [observaciones, setObservaciones] = useState('');
    const [productosRequeridos, setProductosRequeridos] = useState([]);
    const [productoSeleccionado, setProductoSeleccionado] = useState(null);
    const [calidadSeleccionada, setCalidadSeleccionada] = useState(null);
    const [productosSeleccionados, setProductosSeleccionados] = useState([]); // Para manejar la lista de productos agregados
    const [productosDisponibles, setProductosDisponibles] = useState([]);
    const [indicadorIT, setIndicadorIT] = useState(null);
    const [origen, setOrigen] = useState('Brazil');   
    const [proveedoresFiltrados, setProveedoresFiltrados] = useState([]);
    const [allowance, setAllowance] = useState(0);
    const [moneda, setMoneda] = useState(0);
    const [fechaEmbarqueProforma, setFechaEmbarqueProforma] = useState('');
    const [correlativo, setCorrelativo] = useState(0);
    const [empresaEmisor, setEmpresaEmisor] = useState(0);

    useEffect(() => {
      recalcularProductosDisponibles();
      setProductoSeleccionado(null);
    }, [productosSeleccionados, Productos]);

    useEffect(() => {
        const totalUSD = Productos.reduce((acc, producto) => acc + parseFloat(producto.usd_total || 0), 0);
        setValorOrdenCompra(totalUSD.toFixed(3));
    }, [Productos]);

    const cargarDatosOrdenCompra = (idOrdenCompra) => {
        recalcularProductosDisponibles();
        if (idOrdenCompra) {
            const ordenCompra = ordenesCompra.find(oc => oc.id_ordencompra === parseInt(idOrdenCompra));
            const proforma = proformas.find(p => p.id_proforma === ordenCompra.proforma)
            if (ordenCompra) {
                setProforma(ordenCompra.proforma)
                setProveedor(ordenCompra.proveedor);
                setAlmacen(ordenCompra.almacen);
                setIncoTerm(ordenCompra.incoterm);
                setPuerto(ordenCompra.puerto);
                setProductos(ordenCompra.productos.map((prod, index) => ({
                    id_producto: prod,
                    real_size: ordenCompra.real_size[index],
                    m3: ordenCompra.m3[index],
                    usd_m3: ordenCompra.usd_m3[index],
                    usd_total: ordenCompra.usd_total[index],
                    calidad: ordenCompra.calidades[index]
                })));
                setDescripcionProducto(ordenCompra.descripcion_producto);
                setCondicionPago(ordenCompra.condicion_pago);
                setRequisitosCalidad(ordenCompra.requisitos_calidad);
                setRequisitosEtiquetado(ordenCompra.requisitos_etiquetado);
                SetDocumentosRequeridos(ordenCompra.documentos);
                setInformacionAdicional(ordenCompra.informacion_adicional);
                setObservaciones(ordenCompra.observaciones);
                setRemitente(ordenCompra.remitente);
                setAprobador(ordenCompra.aprobador);
                setMoneda(ordenCompra.moneda);
                setAllowance(ordenCompra.allowance);
                setEmpresaEmisor(ordenCompra.empresa_emisor);
                if(proforma){
                  setFechaEmbarqueProforma(proforma.fecha_embarque);
                }
            }
        }
    };

    const getCurrencySymbol = (moneda) => {
      switch(moneda) {
          case 0: return 'USD';
          case 1: return 'EUR';
          case 2: return 'CLP';
          case 3: return 'BRL';
          case 4: return 'PYG';
          default: return 'USD';
      }
    };

    useEffect(() => {
      if (fechaEmbarqueProforma && entrega) {
        const fechaEmbarque = new Date(fechaEmbarqueProforma);
        const fechaEntrega = new Date(entrega);
    
        if (fechaEntrega >= fechaEmbarque) {
            alert("La fecha de embarque debe ser anterior a la fecha de la proforma.");
            setEntrega('')
            return;
        }
    }
    }, [entrega]);

    const handleSubmit = (event) => {
      event.preventDefault();

      console.log(entrega)
      console.log(fechaEmbarqueProforma)

      if (fechaEmbarqueProforma && entrega) {
        const fechaEmbarque = new Date(fechaEmbarqueProforma);
        const fechaEntrega = new Date(entrega);
    
        if (fechaEntrega >= fechaEmbarque) {
            alert("La fecha de embarque debe ser anterior a la fecha de la proforma.");
            return;
        }
      }

      setBotonDeshabilitado(true);

      const arrayProductos = [];
      const arrayRealSize = [];
      const arrayM3 = [];
      const arrayUSDM3 = [];
      const arrayUSDTotal = [];
      const arrayCalidad = [];

      Productos.forEach(producto => {
          arrayProductos.push(producto.id_producto)
          arrayRealSize.push(producto.real_size)
          arrayM3.push(producto.m3)
          arrayUSDM3.push(producto.usd_m3)
          arrayUSDTotal.push(producto.usd_total)
          arrayCalidad.push(parseInt(producto.calidad));
      })

      const arrayDocumentos = [];

      documentosRequeridos.forEach(doc => {
        arrayDocumentos.push(doc);
      });

      if(proforma === ''){
        return alert("Ingresa la proforma asociada o si es una PI por crear")
      }

      const nuevo = {
        proveedor,
        proforma,
        almacen,
        emision,
        entrega,
        incoterm,
        puerto,
        productos: arrayProductos,
        real_size: arrayRealSize,
        m3: arrayM3,
        usd_m3: arrayUSDM3,
        usd_total: arrayUSDTotal,
        documentos: arrayDocumentos,
        valor_ordencompra,
        condicion_pago,
        requisitos_calidad,
        informacion_adicional,
        requisitos_etiquetado,
        estado,
        remitente,
        aprobador,
        observaciones,
        descripcion_producto,
        calidades: arrayCalidad,
        origen,
        allowance,
        moneda,
        correlativo,
        empresa_emisor: empresaEmisor
      };

      const token = localStorage.getItem('token');

      fetch('https://api.dvstarlab.com/agregarordencompra', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify(nuevo),
      })
      .then(response => {
          if (!response.ok) {
              throw new Error('Error al agregar datos');
          }
          return response.json();
      })
      .then(data => {
          console.log('Datos agregados:', data);
          alert('Orden de compra agregada exitosamente')
          window.location.reload();
      })
      .catch(error => {
          console.error('Error:', error);
      })
      .finally(() => {
          setBotonDeshabilitado(false);
      });
    };

    const seleccionProveedor = (prov) =>{
        if(prov !== 0 && prov !== '0' && prov !== ''){
            const provSeleccionado = proveedores.find(pro => pro.id_proveedor === parseInt(prov));
            if(provSeleccionado){
                seleccionIT(provSeleccionado.incoterm);
                setPuerto(provSeleccionado.puerto);
                setAlmacen(provSeleccionado.almacen);
                setCondicionPago(provSeleccionado.condicion_pago);
                setRequisitosCalidad(provSeleccionado.requisitos_calidad);
                setRequisitosEtiquetado(provSeleccionado.requisitos_etiquetado);
                setInformacionAdicional(provSeleccionado.informacion_adicional);
                setProveedor(provSeleccionado.id_proveedor);
                setProductosRequeridos(provSeleccionado.productos || []);
            } else {
                setIncoTerm(0);
                setPuerto(0);
                setAlmacen(0);
                setCondicionPago('');
                setRequisitosCalidad('');
                setRequisitosEtiquetado('');
                setInformacionAdicional('');
                setProveedor(0);
                setProductosRequeridos([]);
            }
        }
    }


    const seleccionProforma = (proforma) => {
        if (proforma !== 0 && proforma !== '0' && proforma !== '') {
            const proformaSeleccionada = proformas.find(ov => ov.id_proforma === parseInt(proforma));
            if (proformaSeleccionada) {
                const cliente = clientes.find(cli => cli.id_cliente === parseInt(proformaSeleccionada.cliente));
                if(cliente){
                    SetDocumentosRequeridos(cliente.documentos);
                    setProforma(proformaSeleccionada.id_proforma);
                    setProductosRequeridos(proformaSeleccionada.productos || []);
                    setFechaEmbarqueProforma(proformaSeleccionada.fecha_embarque);
                }
            }
        } else {
            // Si se selecciona "Proforma por crear", mostrar todos los proveedores con productos
            setProforma(proforma);
            setProductosRequeridos([]); // Reiniciar productos requeridos
            setIncoTerm(0);
            setPuerto(0);
            SetDocumentosRequeridos([]);
            setProveedor(0);
            setProductos([]);
            setFechaEmbarqueProforma('');
        }
    };

    useEffect(() => {
        if (ordenCompraBase) {
            const ordenCompra = ordenesCompra.find(oc => oc.id_ordencompra === parseInt(ordenCompraBase));
            if (ordenCompra) {
                const proveedorSeleccionado = proveedores.find(prov => prov.id_proveedor === ordenCompra.proveedor);
                if (proveedorSeleccionado) {
                    setProveedoresFiltrados([proveedorSeleccionado]);
                }
            }
        } else {
            // Lógica para cuando no se ha seleccionado una orden de compra base
            if (proforma === '0') {
                setProveedoresFiltrados(proveedores);
            } else {
                const proveedoresFiltrados = proveedores.filter(proveedor => {
                    let productosProveedor = proveedor.productos;
                    if (productosProveedor) {
                        let proveedorConProducto = productosProveedor?.filter(prodpro =>
                            productosRequeridos?.includes(prodpro)
                        );
                        return proveedorConProducto.length > 0;
                    }
                    return false;
                });
                setProveedoresFiltrados(proveedoresFiltrados);
            }
        }
    }, [ordenCompraBase, proforma, productosRequeridos, proveedores, ordenesCompra]);
  

    const columnsProductos = [
      {
          name: 'Producto',
          selector: row => {
              if (row.id_producto) {
                  const productoEncontrado = productos.find(prod => parseInt(prod.id_producto) === parseInt(row.id_producto));
                  console.log(productoEncontrado)
                  if (productoEncontrado){
                      const categoriaEncontrada = categorias.find(categoria => parseInt(categoria.id_categoria) === parseInt(productoEncontrado.categoria))
                      const especieEncontrada = especies.find(especie => parseInt(especie.id_especie) === parseInt(productoEncontrado.especie))
                      const medidaEncontrada = medidas.find(medida => parseInt(medida.id_medida) === parseInt(productoEncontrado.medida))
                      return categoriaEncontrada.nombre + " de " + especieEncontrada.nombre + " " + medidaEncontrada.espesor + " X " + medidaEncontrada.ancho + " X " + medidaEncontrada.largo
                  }
              }
          },
      },
      {
        name: 'Calidad',
        selector: row => {
          if (row.calidad) {
            const calidadEncontrada = calidades.find(cal => parseInt(cal.id_calidad) === parseInt(row.calidad));
            if (calidadEncontrada) {
              return calidadEncontrada.calidad;
            }
          }
        },
        width: '15%',
      },
      {
        name: 'Real Size',
        selector: row => row.real_size,
        width: '15%',
      },
      {
        name: 'm3',
        selector: row => row.m3,
        width: '15%',
      },
      {
        name: `${getCurrencySymbol(moneda)}/m3`,
        selector: row => row.usd_m3,
        width: '15%',
      },
      {
        name: `Total ${getCurrencySymbol(moneda)}`,
        selector: row => row.usd_total,
        width: '15%',
      },
      {
        name: 'Eliminar',
        cell: row => (
          <div className="delete-button-container">
            <button
              type="button"
              className="btn btn-outline-danger delete-button"
              onClick={() => {
                if (window.confirm('¿Estás seguro?')) {
                  handleDeleteProducto(row.id_producto, row.calidad);
                }
              }}
            >
              X
            </button>
          </div>
        ),
        ignoreRowClick: true,
        width: '100px',
      },
    ];

    const columnsDocumentos = [
      {
          name: 'Documentos',
          selector: row => {
              if (row) {
                  const docEncontrado = documentos.find(d => parseInt(d.id_documento) === parseInt(row));
                  if (docEncontrado){
                      return docEncontrado.nombre
                  }
              }
          },
      },
      {
          name: 'Eliminar',
          cell: row => (
              <div className="delete-button-container">
                  <button 
                      type="button"
                      className="btn btn-outline-danger delete-button"
                      onClick={() => {
                          if(window.confirm('¿Estás seguro?')) {
                              handleDeleteDocumento(row)
                          }
                      }}
                  >
                      X
                  </button>
              </div>
          ),
          ignoreRowClick: true,
          width: '100px',
      },
    ];  

    const handleDeleteProducto = (id_producto, calidad) => {
        const updatedProductos = Productos.filter((producto) => 
            producto.id_producto !== id_producto || producto.calidad !== calidad
        );

        if (updatedProductos.length !== Productos.length) {
            setProductos(updatedProductos);
            if(updatedProductos.length === 0){
                setDescripcionProducto('');
            }
        } else {
            alert('El producto con la ID y calidad especificadas no se encontró.');
        }
    };

    const handleAgregarProducto = () => {
      if (productoSeleccionado && calidadSeleccionada) {
        const producto = productos.find(prod => parseInt(prod.id_producto) === parseInt(productoSeleccionado));
        const calidad = calidades.find(cal => parseInt(cal.id_calidad) === parseInt(calidadSeleccionada));
        setProductoSeleccionado(null);
        
        if (producto && calidad) {
          if (!document.getElementById("m3") || !calidad || !document.getElementById("usd_m3")) {
            alert('Por favor ingrese todos los detalles requeridos.');
            return;
          }
          
          const categoriaSeleccionada = categorias.find(categoria => parseInt(categoria.id_categoria) === parseInt(producto.categoria));
          const especieSeleccionada = especies.find(especie => parseInt(especie.id_especie) === parseInt(producto.especie));
          const medidaEncontrada = medidas.find(m => m.id_medida === producto.medida);
          
          if (medidaEncontrada && categoriaSeleccionada && especieSeleccionada) {
            // Encontrar el índice del espesor en la lista de espesores de la medida que coincida con el espesor del producto
            const indiceEspesor = medidaEncontrada.espesor.findIndex(e => e === producto.espesor);
            
            if (indiceEspesor !== -1) {
              // Obtener el espesorr correspondiente usando el índice
              const espesorrCorrespondiente = medidaEncontrada.espesorr[indiceEspesor];
    
              const nuevo = {
                id_producto: parseInt(producto.id_producto),
                real_size: `${espesorrCorrespondiente} x ${medidaEncontrada.anchor} x ${medidaEncontrada.largor}`,
                m3: parseFloat(parseFloat(document.getElementById("m3").value).toFixed(3)),
                usd_m3: parseFloat(parseFloat(document.getElementById("usd_m3").value).toFixed(3)),
                usd_total: parseFloat((parseFloat(document.getElementById("m3").value) * parseFloat(document.getElementById("usd_m3").value)).toFixed(3)),
                calidad: parseInt(calidadSeleccionada),
              };
    
              setProductos([...Productos, nuevo]);
              setDescripcionProducto(`${categoriaSeleccionada.nombre_portugues} ${especieSeleccionada.nombre_portugues}`);
              document.getElementById('m3').value = '';
              document.getElementById('usd_m3').value = '';
    
              recalcularProductosDisponibles();
              alert("Producto Agregado");
            } else {
              alert("El espesor seleccionado no coincide con ningún espesorr disponible.");
            }
          } else {
            alert("Medida no encontrada.");
          }
        } else {
          alert("Debe seleccionar tanto un producto como una calidad.");
        }
      } else {
        alert("Producto o medida no seleccionada.");
      }
    };

    const handleDeleteDocumento = (id) => {
      const updatedDocumentos = documentosRequeridos.filter((doc) => doc !== id);
      SetDocumentosRequeridos(updatedDocumentos);
    };

    const handleAgregarDocumento = () => {
      if (nuevoDocumento) {
          const documentoSeleccionado = documentos.find(d => parseInt(d.id_documento) === parseInt(nuevoDocumento));
          if (documentoSeleccionado) {
              const nuevo = documentoSeleccionado.id_documento
              SetDocumentosRequeridos([...documentosRequeridos, nuevo]);
              setNuevoDocumento(null);
          } else {
              alert("No se puede agregar");
          }
      }
    }

    const seleccionIT = (incoterm) =>{
      if(incoterm !== 0){
          const itSeleccionado = incoterms.find(i => i.id_incoterm === parseInt(incoterm))
          if(itSeleccionado){
              if(itSeleccionado.indicador === 1){
                  setIncoTerm(itSeleccionado.id_incoterm)
                  setIndicadorIT(1);
              }else if(itSeleccionado.indicador === 2){
                  setIncoTerm(itSeleccionado.id_incoterm)
                  setIndicadorIT(2);
              }else if(itSeleccionado.indicador === 3){
                  setIncoTerm(itSeleccionado.id_incoterm)
                  setIndicadorIT(3);
              }else{
                  console.log("Error con el indicador")
              }
          }else{
              console.log("No se encontro el incoterm")
          }
      }
    }

    const obtenerCalidadesDisponibles = (productoId) => {
        // Verificación para evitar errores si no hay productoId o productosp
        if (!productoId || !Productos || Productos.length === 0) {
            console.log(productoId)
            console.log(Productos)
            console.log(Productos.length)
            if(productoId){
              const calidadesProducto = productos.find(prod => prod.id_producto === productoId)
              if(calidadesProducto){
                return calidadesProducto.calidades
              }
            }
            return calidades; // Devolver todas las calidades si no hay restricciones
        }

        console.log(Productos);
        console.log(productoId);

        // Filtrar los productos usados para el producto específico
        const productosUsados = Productos.filter(prod => prod.id_producto === parseInt(productoId));
        console.log(productosUsados);

        // Crear un array para almacenar las calidades ya utilizadas
        let calidadesUsadas = [];

        // Añadir las calidades usadas al array
        productosUsados.forEach(prod => {
            if (prod) {
                calidadesUsadas.push(prod.calidad);
            }
        });

        console.log(calidadesUsadas);

        // Devolver las calidades que no están en el array de calidades usadas
        return calidades.filter(cal => !calidadesUsadas.includes(cal.id_calidad));
    };


    const recalcularProductosDisponibles = () => {
        const productosFiltrados = productos.filter(producto => {
            const calidadesDisponibles = obtenerCalidadesDisponibles(producto.id_producto);
            console.log(calidadesDisponibles)
            return calidadesDisponibles.length > 0;
        });
        console.log(productosFiltrados)
        setProductosDisponibles(productosFiltrados);
    };

    return (
      <div className="modal-overlay">
        <div className="modal1">
          <div className="card">
            <form onSubmit={handleSubmit}>
              <div className="card-header">
                <div className="card-title" style={{ textAlign: 'center' }}>Agregar Orden de compra</div>
              </div>
              <div className="card-body">
                <div className="input-group mb-3">
                  <span className="input-group-text">Basada en Orden de Compra</span>
                  <select
                    className="form-select"
                    value={ordenCompraBase}
                    onChange={(e) => {
                      setOrdenCompraBase(e.target.value);
                      cargarDatosOrdenCompra(e.target.value);
                    }}
                  >
                    <option value="">Seleccione una Orden de Compra</option>
                    {ordenesCompra.map((oc) => (
                      <option key={oc.id_ordencompra} value={oc.id_ordencompra}>
                        {oc.correlativo + ' - ' + proveedores.find(p => p.id_proveedor === oc.proveedor)?.nombre}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-group mb-3">
                  <span className='input-group-text'>Moneda</span>
                  <div className="form-check form-check-inline">
                      <input
                          className="form-check-input"
                          type="radio"
                          name="moneda"
                          id="usd"
                          value={0}
                          checked={moneda === 0}
                          onChange={(e) => setMoneda(parseInt(e.target.value))}
                      />
                      <label className="form-check-label" htmlFor="usd">USD</label>
                  </div>
                  <div className="form-check form-check-inline">
                      <input
                          className="form-check-input"
                          type="radio"
                          name="moneda"
                          id="eur"
                          value={1}
                          checked={moneda === 1}
                          onChange={(e) => setMoneda(parseInt(e.target.value))}
                      />
                      <label className="form-check-label" htmlFor="eur">EUR</label>
                  </div>
                  <div className="form-check form-check-inline">
                      <input
                          className="form-check-input"
                          type="radio"
                          name="moneda"
                          id="clp"
                          value={2}
                          checked={moneda === 2}
                          onChange={(e) => setMoneda(parseInt(e.target.value))}
                      />
                      <label className="form-check-label" htmlFor="clp">CLP</label>
                  </div>
                  <div className="form-check form-check-inline">
                      <input
                          className="form-check-input"
                          type="radio"
                          name="moneda"
                          id="brl"
                          value={3}
                          checked={moneda === 3}
                          onChange={(e) => setMoneda(parseInt(e.target.value))}
                      />
                      <label className="form-check-label" htmlFor="brl">BRL</label>
                  </div>
                  <div className="form-check form-check-inline">
                      <input
                          className="form-check-input"
                          type="radio"
                          name="moneda"
                          id="pyg"
                          value={4}
                          checked={moneda === 4}
                          onChange={(e) => setMoneda(parseInt(e.target.value))}
                      />
                      <label className="form-check-label" htmlFor="pyg">PYG</label>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <span className='input-group-text'>Empresa Emisor</span>
                  <div className="form-check form-check-inline">
                      <input
                          className="form-check-input"
                          type="radio"
                          name="empresaEmisor"
                          id="ptc"
                          value={0}
                          checked={empresaEmisor === 0}
                          onChange={(e) => setEmpresaEmisor(parseInt(e.target.value))}
                      />
                      <label className="form-check-label" htmlFor="ptc">PTC</label>
                  </div>
                  <div className="form-check form-check-inline">
                      <input
                          className="form-check-input"
                          type="radio"
                          name="empresaEmisor"
                          id="ptb"
                          value={1}
                          checked={empresaEmisor === 1}
                          onChange={(e) => setEmpresaEmisor(parseInt(e.target.value))}
                      />
                      <label className="form-check-label" htmlFor="ptb">PTB</label>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text">Proforma</span>
                  <select
                    className="form-select"
                    value={proforma}
                    onChange={(e) => seleccionProforma(e.target.value)}
                  >
                    <option value="">Seleccione una Proforma</option>
                    <option value={0}>Proforma por crear</option>
                    {proformas.map((Pi) => {
                      const clienteExistente = clientes.find(cli => cli.id_cliente === Pi.cliente);
                      if (clienteExistente) {
                        return (
                          <option key={Pi.id_proforma} value={Pi.id_proforma}>
                            {Pi.id_proforma + ' - ' + clienteExistente?.nombre}
                          </option>
                        );
                      }
                      return null;
                    })}
                  </select>
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text">Fecha de Emisión</span>
                  <input
                    type="date"
                    className="form-control"
                    value={emision}
                    onChange={(e) => setEmision(e.target.value)}
                  />
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text">Proveedor</span>
                  <select
                    className="form-select"
                    value={proveedor}
                    onChange={(e) => seleccionProveedor(e.target.value)}
                  >
                    <option value="">Seleccione un proveedor</option>
                    {proveedoresFiltrados.map((pro) => {
                      return (
                        <option key={pro.id_proveedor} value={pro.id_proveedor}>
                          {pro.nombre}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {/* Muestra la fecha de embarque de la proforma seleccionada */}
                {fechaEmbarqueProforma && (
                  <div className="mb-3">
                      <strong>Fecha de embarque de la Proforma seleccionada: {new Date(fechaEmbarqueProforma).toLocaleDateString('es-ES')}</strong>
                  </div>
                )}
                <div className="input-group mb-3">
                  <span className="input-group-text">Fecha Embarque</span>
                  <input
                    type="date"
                    className="form-control"
                    value={entrega}
                    onChange={(e) => setEntrega(e.target.value)}
                  />
                </div>
                <div className='input-group mb-3'>
                  <span className='input-group-text'>Origen</span>
                  <input
                      type="text"
                      className="form-control"
                      placeholder="Ingrese Origen"
                      value={origen}
                      onChange={(e) => setOrigen(e.target.value)}
                      required
                  />
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text">IncoTerm</span>
                  <select
                    className="form-select"
                    value={incoterm}
                    onChange={(e) => seleccionIT(e.target.value)}
                  >
                    <option value="">Seleccione un IncoTerm</option>
                    {incoterms.map((it) => (
                      <option key={it.id_incoterm} value={it.id_incoterm}>
                        {it.nombre}
                      </option>
                    ))}
                  </select>
                </div>
                {indicadorIT === 2 &&
                  <div className="input-group mb-3">
                    <span className="input-group-text">Almacén</span>
                    <select
                      className="form-select"
                      value={almacen}
                      onChange={(e) => setAlmacen(e.target.value)}
                    >
                      <option value="">Seleccione un almacén</option>
                      {almacenes.map((alm) => (
                        <option key={alm.id_almacen} value={alm.id_almacen}>
                          {alm.nombre}
                        </option>
                      ))}
                    </select>
                  </div>
                }
                {indicadorIT === 1 && 
                  <div className="input-group mb-3">
                    <span className="input-group-text">Puerto</span>
                    <select
                      className="form-select"
                      value={puerto}
                      onChange={(e) => setPuerto(e.target.value)}
                    >
                      <option value="">Seleccione un Puerto de Destino</option>
                      {puertos.map((puerto) => (
                        <option key={puerto.id_puerto} value={puerto.id_puerto}>
                          {puerto.nombre}
                        </option>
                      ))}
                    </select>
                  </div>
                }
                <div className="input-group mb-3">
                  <span className="input-group-text">Descripción Producto</span>
                  <input
                    type="text"
                    className="form-control"
                    value={descripcion_producto}
                    readOnly
                  />
                </div>
                <div className="mb-3">
                  <h5 style={{ textAlign: 'center' }}>Productos</h5>
                  <div className="input-group mb-3">
                    <span className="input-group-text">Producto</span>
                    <select
                        value={productoSeleccionado}
                        onChange={e => setProductoSeleccionado(e.target.value)}
                        className='form-control'
                    >
                      <option value={null}>Seleccionar Producto</option>
                      {productosDisponibles?.map((producto) => {
                        const productoEnProveedor = productosRequeridos.includes(producto.id_producto);
                        if (productoEnProveedor) {
                            const categoriaProducto = categorias.find(categoria => categoria.id_categoria === producto.categoria);
                            const especieProducto = especies.find(especie => especie.id_especie === producto.especie);
                            const medidaProducto = medidas.find(medida => medida.id_medida === producto.medida);
                            const Producto = productos.find(p => p.id_producto === producto.id_producto);

                            if (categoriaProducto && especieProducto && medidaProducto && Producto) {
                                if (!descripcion_producto || `${especieProducto.nombre_portugues} ${categoriaProducto.nombre_portugues}` === descripcion_producto) {
                                    return (
                                        <option key={producto.id_producto} value={producto.id_producto}>
                                            {`${categoriaProducto.nombre} de ${especieProducto.nombre} ${Producto.espesor} X ${medidaProducto.ancho} X ${medidaProducto.largo}`}
                                        </option>
                                    );
                                }else{
                                  return (
                                    <option key={producto.id_producto} value={producto.id_producto}>
                                        {`${categoriaProducto.nombre} de ${especieProducto.nombre} ${Producto.espesor} X ${medidaProducto.ancho} X ${medidaProducto.largo}`}
                                    </option>
                                  );
                                }
                            }
                        }
                        return null;
                      })}
                    </select>
                  </div>
                  <div className="input-group mb-3">
                    <span className="input-group-text">Calidad</span>
                    <select
                      id="calidad"
                      className="form-select"
                      value={calidadSeleccionada}
                      onChange={(e) => setCalidadSeleccionada(e.target.value)}
                    >
                      <option value="">Seleccione una calidad</option>
                      {obtenerCalidadesDisponibles(productoSeleccionado).map(cal => (
                        <option key={cal.id_calidad} value={cal.id_calidad}>
                            {cal.calidad}
                        </option>
                      ))}
                    </select>
                    <span className="input-group-text">m3</span>
                    <input type="number" id="m3" className="form-control" />
                    <span className="input-group-text">{getCurrencySymbol(moneda)}/m3</span>
                    <input type="number" id="usd_m3" className="form-control" />
                  </div>
                  <button type="button" onClick={handleAgregarProducto} className="btn btn-outline-primary" disabled={!productoSeleccionado || !calidadSeleccionada}>
                    Agregar Producto
                  </button>
                  <DataTable
                    columns={columnsProductos}
                    data={Productos}
                    highlightOnHover
                    dense
                    pagination={true}
                  />
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text">Valor Orden de Compra (${getCurrencySymbol(moneda)})</span>
                  <input
                    type="text"
                    className="form-control"
                    value={valor_ordencompra}
                    readOnly
                  />
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text" style={{ width: '170px' }}>Allowance</span>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Ingrese allowance"
                    value={allowance}
                    onChange={(e) => setAllowance(e.target.value)}
                    required
                  />
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text" style={{ width: '170px' }}>Condición de Pago</span>
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Ingrese condiciones de pago"
                    value={condicion_pago}
                    onChange={(e) => setCondicionPago(e.target.value)}
                    required
                  />
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text" style={{ width: '170px' }}>Requisitos Calidad</span>
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Ingrese Requisitos de calidad"
                    value={requisitos_calidad}
                    onChange={(e) => setRequisitosCalidad(e.target.value)}
                    required
                  />
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text" style={{ width: '170px' }}>Información Adicional</span>
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Ingrese Requisitos de etiquetado"
                    value={informacion_adicional}
                    onChange={(e) => setInformacionAdicional(e.target.value)}
                    required
                  />
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text" style={{ width: '170px' }}>Requisitos Etiquetado</span>
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Ingrese Requisitos de etiquetado"
                    value={requisitos_etiquetado}
                    onChange={(e) => setRequisitosEtiquetado(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <h5 style={{ textAlign: 'center' }}>Documentos Requeridos</h5>
                  <div className="input-group mb-3">
                    <span className="input-group-text">Documento</span>
                    <select
                      value={nuevoDocumento}
                      onChange={e => setNuevoDocumento(e.target.value)}
                      className="form-control"
                    >
                      <option value="">Seleccionar Documento</option>
                      {documentos.map((td) => {
                        const tdExistente = documentosRequeridos.some(dr => parseInt(dr) === parseInt(td.id_documento));
                        if (!tdExistente) {
                          return (
                            <option key={td.id_documento} value={td.id_documento}>
                              {td.nombre}
                            </option>
                          );
                        }
                        return null;
                      })}
                    </select>
                    <button type="button" onClick={handleAgregarDocumento} className="btn btn-outline-primary" disabled={!nuevoDocumento}>
                      Agregar Documento
                    </button>
                  </div>
                  <DataTable
                    columns={columnsDocumentos}
                    data={documentosRequeridos}
                    highlightOnHover
                    dense
                    pagination={true}
                  />
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text">Remitente</span>
                  <select
                    className="form-select"
                    value={remitente}
                    onChange={(e) => setRemitente(e.target.value)}
                  >
                    <option value="">Seleccione un Remitente</option>
                    {usuarios.map((user) => (
                      <option key={user.id_usuario} value={user.id_usuario}>
                        {user.nombre}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text">Aprobador</span>
                  <select
                    className="form-select"
                    value={aprobador}
                    onChange={(e) => setAprobador(e.target.value)}
                  >
                    <option value="">Seleccione un Aprobador</option>
                    {usuarios
                      .filter(user => user.rol === 1)
                      .map(user => (
                        <option key={user.id_usuario} value={user.id_usuario}>
                          {user.nombre}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text">Observaciones</span>
                  <textarea
                    type="text"
                    className="form-control"
                    value={observaciones}
                    onChange={(e) => setObservaciones(e.target.value)}
                  />
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text">Nro OC</span>
                  <textarea
                    type="text"
                    className="form-control"
                    value={correlativo}
                    onChange={(e) => setCorrelativo(e.target.value)}
                  />
                </div>
              </div>
              <div className="card-footer" style={{ textAlign: 'center' }}>
                <button
                  type="submit"
                  className="btn btn-outline-success mb-3 mt-3"
                  disabled={botonDeshabilitado}
                >
                  Guardar
                </button>
                <button type="button" className="btn btn-outline-danger" onClick={cerrar}>Cancelar</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
};

export default AgregarOrdenCompra;