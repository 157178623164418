import React, { useState, useEffect, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { IoTrashOutline } from "react-icons/io5";
import { LuPencilLine } from "react-icons/lu";
import AgregarEspecie from './AgregarEspecie';
import EditarEspecie from './EditarEspecie';

const SearchBar = ({ filterText, onFilter }) => (
    <div className='input-group mb-3'>
        <input
            type="text"
            placeholder="Buscar..."
            value={filterText}
            onChange={onFilter}
            className='form-control'
        />
    </div>
);

const ActionButtons = ({ onAgregar }) => (
    <div className="input-group mb-3">
        <button type="button" className="btn btn-outline-primary" onClick={onAgregar}>Agregar Especie</button>
    </div>
);

const Especies = () => {
    const [dataEspecies, setDataEspecies] = useState([])
    const firstRender = useRef(true);
    const [showAgregar, setShowAgregar] = useState(false);
    const [showEditar, setShowEditar] = useState(false);
    const [selected, setSelected] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [filteredData, setFilteredData] = useState([])

    const fetchData = async () => {
        const token = localStorage.getItem('token'); // Obtener token de autenticación
        try {
            const especieResponse = await fetch('https://api.dvstarlab.com/especies', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (especieResponse.status === 401) {
                throw new Error('Unauthorized');
            }
            const especieData = await especieResponse.json();
            setDataEspecies(especieData);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        if (firstRender.current) {
            fetchData();
            firstRender.current = false;
        }
    }, []);

    useEffect(() => {
        if (Array.isArray(dataEspecies)) {
            const filtered = dataEspecies.filter((row) =>
                Object.values(row).some((value) =>
                    value.toString().toLowerCase().includes(filterText.toLowerCase())
                )
            );
            setFilteredData(filtered);
        }
    }, [dataEspecies, filterText]);

    const columns = [
        {
            name: 'Editar',
            cell: (row) => <button className='btn btn-outline-primary' onClick={() => handleEditar(row)}><LuPencilLine /></button>,
        },
        {
            name: 'Nombre',
            selector: row => row.nombre ? row.nombre : 'Indefinido',
        },
        {
            name: 'Inglés',
            selector: row => row.nombre_ingles ? row.nombre_ingles : 'Indefinido'
        },
        {
            name: 'Portugués',
            selector: row => row.nombre_portugues ? row.nombre_portugues : 'Indefinido'
        },
        {
            name: 'Eliminar',
            cell: (row) => <button
                className='btn btn-outline-danger'
                onClick={() => {
                    if (window.confirm('¿Estás seguro?')) {
                        handleEliminar(row.id_especie);
                    }
                }}
            >
                <IoTrashOutline />
            </button>,
            ignoreRowClick: true,
        },
    ];

    const handleAgregar = () => {
        setShowAgregar(true);
    }

    const handleEditar = (especie) => {
        setSelected(especie);
        setShowEditar(true);
    };

    const handleSaveEditar = (editedEspecie) => {
        const token = localStorage.getItem('token'); // Obtener token de autenticación
        fetch(`https://api.dvstarlab.com/editarespecie/${editedEspecie.id_especie}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(editedEspecie),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Error al actualizar especie');
                }
                return response.json();
            })
            .then(data => {
                console.log('Especie actualizada:', data);
                alert('Especie actualizada exitosamente');
                window.location.reload();
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    const handleCloseEditar = () => {
        setSelected(null);
        setShowEditar(false);
    };

    const handleEliminar = (idEspecieAEliminar) => {
        const token = localStorage.getItem('token'); // Obtener token de autenticación
        fetch(`https://api.dvstarlab.com/eliminarespecie/${idEspecieAEliminar}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (response.ok) {
                    alert('Especie Eliminada Correctamente.');
                    window.location.reload();
                } else {
                    alert('Error al intentar eliminar la especie.')
                }
            })
            .catch(error => {
                console.error('Error de red:', error);
            });
    };

    return (
        <div className='container-fluid'>
            <div className='card'>
                <div className='card-header'>
                    <h3 className='card-title' style={{ textAlign: 'center' }}>Especies</h3>
                </div>
                <div className='card-body mb-2'>
                    <div className="input-group mb-3">
                        <input
                            type="text"
                            placeholder="Buscar..."
                            value={filterText}
                            onChange={(e) => setFilterText(e.target.value)}
                            className='form-control'
                        />
                    </div>
                    <div className="input-group mb-3">
                        <button type="button" className="btn btn-outline-primary" onClick={() => handleAgregar()}>Agregar Categoría</button>
                    </div>
                    {showAgregar &&
                        <AgregarEspecie cerrar={() => setShowAgregar(false)} />
                    }
                    <div className="card">
                        <div className="card-body">
                            <DataTable
                                columns={columns}
                                data={filteredData}
                                noHeader
                                responsive
                                pagination
                                highlightOnHover
                            />
                        </div>
                    </div>
                    {selected && showEditar && (
                        <EditarEspecie
                            especie={selected}
                            cerrar={handleCloseEditar}
                            guardar={handleSaveEditar}
                        />
                    )}
                </div>
            </div>
        </div>
    );

}

export default Especies