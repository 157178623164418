import React, { useState } from 'react';

const EditarPuerto = ({ puerto, cerrar, guardar }) => {
    const [botonDeshabilitado, setBotonDeshabilitado] = useState(false);
    const [nombre, setNombre] = useState(puerto.nombre || '');
    const [pais, setPais] = useState(puerto.pais || '');
    const [indicador, setIndicador] = useState(puerto.indicador || '');
    const [siglas, setSiglas] = useState(puerto.siglas || '');

    const handleSubmit = (event) => {
        event.preventDefault();
        setBotonDeshabilitado(true);

        const editedPuerto = {
            ...puerto,
            nombre,
            pais,
            indicador: parseInt(indicador),
            siglas
        };

        guardar(editedPuerto);
        cerrar();
    };

    const handleRadioChange = (event) => {
        const selectedValue = parseInt(event.target.value); // Convertir el valor a entero
        setIndicador(selectedValue);
    };

    return (
        <div className='modal-overlay'>
            <div className='modal1'>
                <div className='card'>
                    <form onSubmit={handleSubmit}>
                        <div className='card-header'>
                            <h3 style={{ textAlign: 'center' }}>Editar Puerto</h3>
                        </div>
                        <div className='card-body'>
                        <div className='input-group mb-3'>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value={1} checked={indicador === 1} onChange={handleRadioChange}/>
                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                        Origen
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value={2} checked={indicador === 2} onChange={handleRadioChange}/>
                                    <label className="form-check-label" htmlFor="flexRadioDefault2">
                                        Destino
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" value={3} checked={indicador === 3} onChange={handleRadioChange}/>
                                    <label className="form-check-label" htmlFor="flexRadioDefault3">
                                        Origen-Destino
                                    </label>
                                </div>
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Nombre</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese nombre puerto"
                                  value={nombre}
                                  onChange={(e) => setNombre(e.target.value)}
                                  required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Siglas</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese nombre puerto"
                                  value={siglas}
                                  onChange={(e) => setSiglas(e.target.value)}
                                  required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>País</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese país"
                                  value={pais}
                                  onChange={(e) => setPais(e.target.value)}
                                  required
                                />
                            </div>
                        </div>
                        <div className='card-footer' style={{ textAlign: 'center' }}>
                            <button type="submit" className="btn btn-outline-success mb-3 mt-3" disabled={botonDeshabilitado}>Guardar</button>
                            <button type="button" className="btn btn-outline-danger mb-3 mt-3" onClick={cerrar}>Cancelar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditarPuerto;
