// frontend/src/Contact.js
import React from 'react';

function Contact() {
  return (
    <div className="Contact">
      <header className="Contact-header">
        <h1>Contact</h1>
        <p>This is the contact page.</p>
      </header>
    </div>
  );
}

export default Contact;
