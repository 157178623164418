import React, { useState } from 'react';

const AgregarCartaCredito = ({ cerrar }) => {
    const [proforma, setProforma] = useState('');
    const [codigo, setCodigo] = useState('');
    const [emision, setEmision] = useState('');
    const [expiracion, setExpiracion] = useState('');
    const [ultimoEmbarque, setUltimoEmbarque] = useState('');
    const [tolerancia, setTolerancia] = useState('');
    const [valor, setValor] = useState('');
    const [botonDeshabilitado, setBotonDeshabilitado] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        setBotonDeshabilitado(true);

        const nuevaCartaCredito = {
            proforma,
            codigo,
            emision,
            expiracion,
            ultimo_embarque: ultimoEmbarque,
            tolerancia,
            valor
        };

        const token = localStorage.getItem('token');

        fetch('https://api.dvstarlab.com/agregarcartacredito', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(nuevaCartaCredito),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Error al agregar carta de crédito');
            }
            return response.json();
        })
        .then(data => {
            console.log('Carta de crédito agregada:', data);
            alert('Carta de crédito agregada exitosamente');
            window.location.reload();
        })
        .catch(error => {
            console.error('Error:', error);
        })
        .finally(() => {
            setBotonDeshabilitado(false);
        });
    };

    return (
        <div className='modal-overlay'>
            <div className='modal1'>
                <div className='card'>
                    <form onSubmit={handleSubmit}>
                        <div className='card-header'>
                            <h3 className='card-title' style={{ textAlign: 'center' }}>Agregar Carta de Crédito</h3>
                        </div>
                        <div className='card-body'>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Proforma</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese Proforma"
                                  value={proforma}
                                  onChange={(e) => setProforma(e.target.value)}
                                  required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Código</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese Código"
                                  value={codigo}
                                  onChange={(e) => setCodigo(e.target.value)}
                                  required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Emisión</span>
                                <input
                                  type="date"
                                  className="form-control"
                                  value={emision}
                                  onChange={(e) => setEmision(e.target.value)}
                                  required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Expiración</span>
                                <input
                                  type="date"
                                  className="form-control"
                                  value={expiracion}
                                  onChange={(e) => setExpiracion(e.target.value)}
                                  required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Último Embarque</span>
                                <input
                                  type="date"
                                  className="form-control"
                                  value={ultimoEmbarque}
                                  onChange={(e) => setUltimoEmbarque(e.target.value)}
                                  required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Tolerancia</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese Tolerancia"
                                  value={tolerancia}
                                  onChange={(e) => setTolerancia(e.target.value)}
                                  required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Valor</span>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Ingrese Valor"
                                  value={valor}
                                  onChange={(e) => setValor(e.target.value)}
                                  required
                                />
                            </div>
                        </div>
                        <div className='card-footer' style={{ textAlign: 'center' }}>
                            <button type="submit" className="btn btn-outline-success mb-3 mt-3" disabled={botonDeshabilitado}>Agregar</button>
                            <button type="button" className="btn btn-outline-danger mb-3 mt-3" onClick={cerrar}>Cancelar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AgregarCartaCredito;
