// frontend/src/Home.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaUser } from 'react-icons/fa';
import { IoMdLogOut } from 'react-icons/io';

const Logout = ({ hover }) => {
  return (
      <div className="logout-container">
          <div className="nav-item d-flex align-items-center user-info">
              {hover ? (
                  <span className="nav-link" style={{ cursor: 'default', textAlign: 'center' }}>
                      {localStorage.getItem('Nombre')}
                  </span>
              ) : (
                  <FaUser className="nav-link" />
              )}
          </div>
          <div className="nav-item">
              <button 
                  className="btn btn-outline-danger mt-2 logout-button" 
                  onClick={() => {
                      localStorage.removeItem('token');
                      localStorage.removeItem('loggedIn');
                      localStorage.removeItem('Nombre');
                      localStorage.removeItem('Rol');
                      localStorage.removeItem('ID');
                      window.location.href = '/';
                  }}>
                  {hover ? (
                      <span className='nav-link' style={{ cursor: 'default' }}>Cerrar Sesión</span>
                  ) : (
                      <IoMdLogOut />
                  )}
              </button>
          </div>
      </div>
  );
} 

function Home() {
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const fetchMessage = () => {
    setLoading(true);
    setSuccess(false);
    axios.get('https://api.dvstarlab.com/')
      .then(response => {
        setMessage(response.data.message);
        setError(''); // Limpiar cualquier error previo
        setSuccess(true); // Indicar que la solicitud fue exitosa
      })
      .catch(error => {
        if (error.response) {
          // El servidor respondió con un código de estado fuera del rango 2xx
          setError(`Error: ${error.response.status} - ${error.response.data}`);
        } else if (error.request) {
          // La solicitud fue hecha pero no hubo respuesta
          setError('Error: No response received from the server.');
        } else {
          // Algo ocurrió al preparar la solicitud
          setError(`Error: ${error.message}`);
        }
        console.error('There was an error fetching the data!', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchMessage();
  }, []);

  return (
    <div className="Home">
      <header className="Home-header">
        {loading && <p>Loading...</p>}
        {!loading && message && <p>{message}</p>}
        {!loading && error && <p style={{ color: 'red' }}>{error}</p>}
        {!loading && error && <button onClick={fetchMessage}>Retry</button>}
        {!loading && success && <p style={{ color: 'green' }}>Data fetched successfully!</p>}
        <Logout hover={true} />
      </header>
    </div>
  );
}

export default Home;
