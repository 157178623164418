// src/vistas/usuarios/Usuarios.js
import React, { useEffect, useState, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { IoTrashOutline } from "react-icons/io5";
import AgregarUsuario from './AgregarUsuario';

const Usuarios = () => {
    const [dataUsuarios, setDataUsuarios] = useState([]);
    const firstRender = useRef(true);
    const [filterText, setFilterText] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [selectedUsuario, setSelectedUsuario] = useState(null);
    const [showAgregarUsuario, setShowAgregarUsuario] = useState(false);
    const [showEditarUsuario, setShowEditarUsuario] = useState(false);
    const token = localStorage.getItem('token');

    const fetchData = async () => {
        try {
            const usuariosResponse = await fetch('https://api.dvstarlab.com/usuarios', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const usuariosData = await usuariosResponse.json();
            setDataUsuarios(usuariosData);
            console.log(usuariosData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        if (firstRender.current) {
            fetchData();
            firstRender.current = false;
        }
    }, []);

    useEffect(() => {
        const filtered = dataUsuarios.filter((row) => 
            Object.values(row).some((value) => 
                value.toString().toLowerCase().includes(filterText.toLowerCase())
            )
        );
        setFilteredData(filtered);
    }, [dataUsuarios, filterText]);

    const columns = [
        {
            name: 'ID',
            selector: row => row.id_usuario,
            sortable: true,
        },
        {
            name: 'Nombre',
            selector: row => row.nombre,
            sortable: true,
        },
        {
            name: 'Rol',
            selector: row => row.rol === 1 ? 'Administrador' : row.rol === 2 ? 'Operario OffShore' : 'Indefinido',
        },
        {
            name: 'Correo',
            selector: row => row.correo,
        },
        {
            name: 'Eliminar',
            cell: (row) => <button
                className='btn btn-outline-danger'
                onClick={() => {
                    if (window.confirm('¿Estás seguro?')) {
                        handleEliminar(row.id_usuario);
                    }
                }}
                disabled={false}
                >
                    <IoTrashOutline />
                </button>,
                ignoreRowClick: true,
        },
    ];

    const handleEliminar = async (idUsuarioAEliminar) => {
        try {
            const response = await fetch(`https://api.dvstarlab.com/eliminarusuario/${idUsuarioAEliminar}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const result = await response.json();
            if (response.ok) {
                setDataUsuarios(dataUsuarios.filter(usuario => usuario.id_usuario !== idUsuarioAEliminar));
                alert(result.message);
            } else {
                alert(result.error);
            }
        } catch (error) {
            console.error('Error eliminando usuario:', error);
        }
    };

    const handleAgregar = () => {
        setShowAgregarUsuario(true);
    };

    return (
        <div className='container-fluid'>
            <div className='card'>
                <div className='card-header'>
                    <h3 className='card-title' style={{ textAlign: 'center' }}>Usuarios</h3>
                    <div className='card-body mb-2'>
                        <div className="input-group mb-3">
                            <input
                                type="text"
                                placeholder="Buscar..."
                                value={filterText}
                                onChange={(e) => setFilterText(e.target.value)}
                                className='form-control'
                            />
                        </div>
                        <div className="input-group mb-3">
                            <button type="button" className="btn btn-outline-primary" onClick={() => handleAgregar()} >Agregar Usuario</button>
                        </div>
                        {showAgregarUsuario &&
                            <AgregarUsuario cerrar={() => setShowAgregarUsuario(false)} />
                        }
                        <div className='card'>
                            <div className='card-body'>
                                <DataTable
                                    columns={columns}
                                    data={filteredData}
                                    noHeader
                                    responsive
                                    pagination
                                    highlightOnHover
                                />
                            </div>
                        </div>
                        {selectedUsuario && showEditarUsuario && (
                            console.log('0')
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Usuarios;
