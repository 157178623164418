// frontend/src/vistas/clientes/Clientes.js
import React, { useState, useEffect, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { IoTrashOutline } from "react-icons/io5";
import { LuPencilLine } from "react-icons/lu";
import AgregarCliente from './AgregarCliente';
import EditarCliente from './EditarCliente';

const Clientes = () => {
    const [dataClientes, setDataClientes] = useState([]);
    const [opciones, setOpciones] = useState({
        subclientes: [],
        productos: [],
        incoterms: [],
        puertos: [],
        documentos: []
    });
    const [dataPaymentTerms, setDataPaymentTerms] = useState([]);
    const firstRender = useRef(true);
    const [showAgregar, setShowAgregar] = useState(false);
    const [showEditar, setShowEditar] = useState(false);
    const [selected, setSelected] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    const fetchData = async () => {
        const token = localStorage.getItem('token'); // Obtener token de autenticación
        try {
            const clienteResponse = await fetch('https://api.dvstarlab.com/clientes', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (clienteResponse.status === 401) {
                throw new Error('Unauthorized');
            }
            const clienteData = await clienteResponse.json();
            setDataClientes(clienteData);

            const opcionesResponse = await fetch('https://api.dvstarlab.com/opciones', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (opcionesResponse.status === 401) {
                throw new Error('Unauthorized');
            }
            const opcionesData = await opcionesResponse.json();
            setOpciones(opcionesData);

            const ptResponse = await fetch('https://api.dvstarlab.com/paymentterms', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (ptResponse.status === 401) {
                throw new Error('Unauthorized');
            }
            const ptData = await ptResponse.json();
            setDataPaymentTerms(ptData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        if (firstRender.current) {
            fetchData();
            firstRender.current = false;
        }
    }, []);

    useEffect(() => {
        const filtered = dataClientes.filter((row) =>
            Object.values(row).some((value) =>
                value.toString().toLowerCase().includes(filterText.toLowerCase())
            )
        );
        setFilteredData(filtered);
    }, [dataClientes, filterText]);

    const columns = [
        {
            name: 'Editar',
            cell: (row) => (
                <button className='btn btn-outline-primary' onClick={() => handleEditar(row)}>
                    <LuPencilLine />
                </button>
            ),
        },
        {
            name: 'Nombre',
            selector: row => row.nombre,
        },
        {
            name: 'Tax',
            selector: row => row.tax,
        },
        {
            name: 'Siglas',
            selector: row => row.siglas,
        },
        {
            name: 'Direccion',
            selector: row => row.direccion,
        },
        {
            name: 'Eliminar',
            cell: (row) => (
                <button
                    className='btn btn-outline-danger'
                    onClick={() => {
                        if (window.confirm('¿Estas seguro?')) {
                            handleEliminar(row.id_cliente);
                        }
                    }}
                >
                    <IoTrashOutline />
                </button>
            ),
            ignoreRowClick: true,
        },
    ];

    const handleAgregar = () => {
        setShowAgregar(true);
    };

    const handleEditar = (cliente) => {
        setSelected(cliente);
        setShowEditar(true);
    };

    const handleSaveEditar = (editedCliente) => {
        const token = localStorage.getItem('token'); // Obtener token de autenticación
        fetch(`https://api.dvstarlab.com/editarcliente/${editedCliente.id_cliente}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(editedCliente),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Error al actualizar cliente');
            }
            return response.json();
        })
        .then(data => {
            console.log('Cliente actualizado:', data);
            alert('Cliente actualizado exitosamente');
            window.location.reload();
        })
        .catch(error => {
            console.error('Error:', error);
        });
    };

    const handleCloseEditar = () => {
        setSelected(null);
        setShowEditar(false);
    };

    const handleEliminar = (idClienteAEliminar) => {
        const token = localStorage.getItem('token'); // Obtener token de autenticación
        fetch(`https://api.dvstarlab.com/eliminarcliente/${idClienteAEliminar}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
        .then(response => {
            if (response.ok) {
                alert('Cliente Eliminado Correctamente.');
                window.location.reload();
            } else {
                alert('Error al intentar eliminar el cliente.');
            }
        })
        .catch(error => {
            console.error('Error de red:', error);
        });
    };

    return (
        <div className='container-fluid'>
            <div className='card'>
                <div className='card-header'>
                    <h3 className='card-title' style={{ textAlign: 'center' }}>Clientes</h3>
                </div>
                <div className='card-body mb-2'>
                    <div className="input-group mb-3">
                        <input
                            type="text"
                            placeholder="Buscar..."
                            value={filterText}
                            onChange={(e) => setFilterText(e.target.value)}
                            className='form-control'
                        />
                    </div>
                    <div className="input-group mb-3">
                        <button type="button" className="btn btn-outline-primary" onClick={handleAgregar}>Agregar Cliente</button>
                    </div>
                    {showAgregar && <AgregarCliente cerrar={() => setShowAgregar(false)} opciones={opciones} pt={dataPaymentTerms}/>}
                    <div className="card">
                        <div className="card-body">
                            <DataTable
                                columns={columns}
                                data={filteredData}
                                noHeader
                                responsive
                                pagination
                                highlightOnHover
                            />
                        </div>
                    </div>
                    {selected && showEditar && (
                        <EditarCliente
                            cliente={selected}
                            cerrar={handleCloseEditar}
                            guardar={handleSaveEditar}
                            opciones={opciones}
                            pt={dataPaymentTerms}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Clientes;
