// frontend/src/vistas/calidades/AgregarCalidad.js
import React, {useState} from 'react';

const AgregarCalidad = ({cerrar}) => {
    const [calidad, setCalidad] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        const nuevaCalidad = {
            calidad,
        };

        const token = localStorage.getItem('token');

        fetch('https://api.dvstarlab.com/addcalidad', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(nuevaCalidad)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Error al agregar almacen');
            }
            return response.json();
        })
        .then(data => {
            alert('Calidad agregada exitosamente');
            window.location.reload();
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }

    return (
        <div className='modal-overlay'>
            <div className='modal1'>
                <div className='card'>
                    <form onSubmit={handleSubmit}>
                        <div className='card-header'>
                            <h3 className='card-title' style={{ textAlign: 'center' }}>Agregar Calidad</h3>
                        </div>
                        <div className='card-body'>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Calidad</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese nombre de la calidad"
                                  value={calidad}
                                  onChange={(e) => setCalidad(e.target.value)}
                                  required
                                />
                            </div>
                        </div>
                        <div className='card-footer' style={{ textAlign: 'center' }}>
                            <button 
                                type="submit" 
                                className="btn btn-outline-success mb-3 mt-3"
                            >
                                Agregar
                            </button>
                            <button type="button" className="btn btn-outline-danger" onClick={cerrar}>Cancelar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default AgregarCalidad