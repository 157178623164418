import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';

const EditarCliente = ({ cerrar, opciones, cliente, pt }) => {
    const [botonDeshabilitado, setBotonDeshabilitado] = useState(false);
    const [nombre, setNombre] = useState(cliente.nombre || '');
    const [tax, setTax] = useState(cliente.tax || '');
    const [siglas, setSiglas] = useState(cliente.siglas || '');
    const [direccion, setDireccion] = useState(cliente.direccion || '');
    const [ciudad, setCiudad] = useState(cliente.ciudad || '');
    const [pais, setPais] = useState(cliente.pais || '');
    const [indicador, setIndicador] = useState(cliente.indicador || 0);
    const [giro, setGiro] = useState(cliente.giro || '');
    const [indicadorsc, setIndicadorsc] = useState(cliente.indicadorsc || 0);
    const [subclientes, setSubClientes] = useState(cliente.subclientes || []);
    const [nuevoSubCliente, setNuevoSubCliente] = useState(null);
    const [contacto_finanza, setContactoFinanza] = useState(cliente.contacto_finanza || '');
    const [linea_credito, setLineaCredito] = useState(cliente.linea_credito || 0);
    const [estado, setEstado] = useState(cliente.estado || '');
    const [codigo_postal, setCodigoPostal] = useState(cliente.codigo_postal || '');
    const [productos, setProductos] = useState(cliente.productos || []);
    const [nuevoProducto, setNuevoProducto] = useState(null);
    const [payment_terms, setPaymentTerms] = useState(cliente.payment_terms || 0);
    const [incoterm, setIncoterm] = useState(cliente.incoterm || '');
    const [puerto, setPuerto] = useState(cliente.puerto || '');
    const [direccion_despacho, setDireccionDespacho] = useState(cliente.direccion_despacho || '');
    const [beneficiary_bank, setBeneficiaryBank] = useState(cliente.beneficiary_bank || '');
    const [bank_information, setBankInformation] = useState(cliente.bank_information || '');
    const [quality_standards, setQualityStandards] = useState(cliente.quality_standards || '');
    const [documentosRequeridos, SetDocumentosRequeridos] = useState(cliente.documentos || []);
    const [nuevoDocumento, setNuevoDocumento] = useState(null);
    const [especificaciones_lc, setEspecificacionesLC] = useState(cliente.especificaciones_lc || '');
    const [indicador1, setIndicador1] = useState(false);
    const [indicador2, setIndicador2] = useState(false);
    const [indicador3, setIndicador3] = useState(false);
    const [indicador4, setIndicador4] = useState(false);
    const [advance, setAdvance] = useState(cliente.advance || 0);
    const [cad, setCAD] = useState(cliente.cad || 0);
    const [contactoNombres, setContactoNombres] = useState(cliente.contacto || []);
    const [contactoRoles, setContactoRoles] = useState(cliente.rol || []);
    const [contactoCorreos, setContactoCorreos] = useState(cliente.correo || []);
    const [contactoTelefonos, setContactoTelefonos] = useState(cliente.telefono || []);
    const [nuevoContacto, setNuevoContacto] = useState({ nombre: '', rol: '', correo: '', telefono: '' });
    const [correlativo, setCorrelativo] = useState(cliente.correlativo || null);

    useEffect(() => {
        if (payment_terms) {
            seleccionPT(payment_terms);
        }
    }, [payment_terms]);

    const handleSubmit = (event) => {
        event.preventDefault();

        // Validación básica
        if (!nombre || !tax || !siglas || !direccion || !ciudad || !pais || contactoNombres.length === 0 || contactoRoles.length === 0 || contactoCorreos.length === 0 || contactoTelefonos.length === 0) {
            alert('Por favor, complete todos los campos obligatorios y asegúrese de que hay al menos un contacto.');
            return;
        }

        setBotonDeshabilitado(true);

        const updatedCliente = {
            nombre,
            tax,
            siglas,
            direccion,
            ciudad,
            pais,
            contacto: contactoNombres,
            rol: contactoRoles,
            correo: contactoCorreos,
            telefono: contactoTelefonos,
            indicador,
            giro,
            indicadorsc,
            subclientes: subclientes.map(sc => sc),
            contacto_finanza,
            linea_credito,
            estado,
            codigo_postal,
            productos: productos.map(p => p),
            payment_terms,
            incoterm,
            puerto,
            direccion_despacho,
            beneficiary_bank,
            bank_information,
            quality_standards,
            documentos: documentosRequeridos.map(d => d),
            especificaciones_lc,
            advance,
            cad,
            correlativo: correlativo ? parseInt(correlativo) : null,
        };

        const token = localStorage.getItem('token');

        fetch(`https://api.dvstarlab.com/editarcliente/${cliente.id_cliente}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(updatedCliente),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Error al editar cliente');
            }
            return response.json();
        })
        .then(data => {
            console.log('Cliente editado:', data);
            alert('Cliente editado exitosamente');
            window.location.reload();
        })
        .catch(error => {
            console.error('Error:', error);
        })
        .finally(() => {
            setBotonDeshabilitado(false);
        });
    };

    const handleAgregarProducto = () => {
        if (nuevoProducto) {
            setProductos([...productos, nuevoProducto]);
            setNuevoProducto(null);
        }
    };

    const handleDeleteProducto = (id) => {
        const updatedProductos = productos.filter((producto) => producto !== id);
        setProductos(updatedProductos);
    };

    const handleAgregarSC = () => {
        if (nuevoSubCliente) {
            const subClienteSeleccionado = opciones.subclientes.find(sub => sub.id_subcliente === parseInt(nuevoSubCliente));
            if (subClienteSeleccionado) {
                const subClienteExistente = subclientes.some(sub => sub === subClienteSeleccionado.id_subcliente);
                if (!subClienteExistente) {
                    setSubClientes([...subclientes, subClienteSeleccionado.id_subcliente]);
                    setNuevoSubCliente(null);
                } else {
                    alert('El subcliente ya ha sido agregado.');
                }
            } else {
                alert('Selecciona un subcliente válido.');
            }
        }
    };

    const handleDeleteSubCliente = (id) => {
        const updatedSubClientes = subclientes.filter((subcliente) => subcliente !== id);
        setSubClientes(updatedSubClientes);
    };

    const handleAgregarDocumento = () => {
        if (nuevoDocumento) {
            const documentoSeleccionado = opciones.documentos.find(td => parseInt(td.id_documento) === parseInt(nuevoDocumento));
            if (documentoSeleccionado) {
                SetDocumentosRequeridos([...documentosRequeridos, documentoSeleccionado.id_documento]);
                setNuevoDocumento(null);
            } else {
                alert("No se puede agregar");
            }
        }
    };

    const handleDeleteDocumento = (id) => {
        const updatedDocumentos = documentosRequeridos.filter((doc) => doc !== id);
        SetDocumentosRequeridos(updatedDocumentos);
    };

    const handleRadioChange = (event) => {
        const selectedValue = parseInt(event.target.value);
        setIndicador(selectedValue);
    };

    const handleRadioChange2 = (event) => {
        const selectedValue = parseInt(event.target.value);
        setLineaCredito(selectedValue);
    };

    const handleSwitchChange = (event) => {
        const value = event.target.checked;
        setIndicadorsc(value ? 1 : 0);
    };

    const columnsProductos = [
        {
            name: 'ID Producto',
            selector: (row) => row
        },
        {
            name: 'Producto',
            selector: row => {
                const productoEncontrado = opciones.productos.find(producto => parseInt(producto.id_producto) === parseInt(row));
                return productoEncontrado ? productoEncontrado.nombre + ' - ' + productoEncontrado.espesor : 'Producto No Encontrado';
            },
            width: 'flex',
        },
        {
            name: 'Eliminar',
            cell: row => (
                <button 
                    type="button"
                    className="btn btn-outline-danger"
                    onClick={() => handleDeleteProducto(row)}
                >
                    X
                </button>
            ),
            ignoreRowClick: true,
        },
    ];

    const columnsSubClientes = [
        {
            name: 'ID SubCliente',
            selector: (row) => row
        },
        {
            name: 'SubCliente',
            selector: row => {
                const subcliente = opciones.subclientes.find(sc => parseInt(sc.id_subcliente) === parseInt(row));
                return subcliente ? subcliente.nombre : '';
            },
            width: 'flex',
        },
        {
            name: 'Eliminar',
            cell: row => (
                <button 
                    type="button"
                    className="btn btn-outline-danger"
                    onClick={() => handleDeleteSubCliente(row)}
                >
                    X
                </button>
            ),
            ignoreRowClick: true,
        },
    ];

    const columnsDocumentos = [
        {
            name: 'Documentos',
            selector: row => {
                const docEncontrado = opciones.documentos.find(td => parseInt(td.id_documento) === parseInt(row));
                return docEncontrado ? docEncontrado.nombre : '';
            },
        },
        {
            name: 'Eliminar',
            cell: row => (
                <div className="delete-button-container">
                    <button 
                        type="button"
                        className="btn btn-outline-danger delete-button"
                        onClick={() => handleDeleteDocumento(row)}
                    >
                        X
                    </button>
                </div>
            ),
            ignoreRowClick: true,
            width: '100px',
        },
    ];

    const seleccionPT = (paymentterm) => {
        if (paymentterm !== 0) {
            const ptSeleccionado = pt.find(payment => payment.id_paymentterm === parseInt(paymentterm));
            if (ptSeleccionado) {
                setIndicador1(ptSeleccionado.reglas.includes(1));
                setIndicador2(ptSeleccionado.reglas.includes(2));
                setIndicador3(ptSeleccionado.reglas.includes(3));
                setIndicador4(ptSeleccionado.reglas.includes(4));
                setPaymentTerms(parseInt(paymentterm));
            }
        }
    };

    const handleAgregarContacto = () => {
        if (nuevoContacto.nombre && nuevoContacto.rol && nuevoContacto.correo && nuevoContacto.telefono) {
            setContactoNombres([...contactoNombres, nuevoContacto.nombre]);
            setContactoRoles([...contactoRoles, nuevoContacto.rol]);
            setContactoCorreos([...contactoCorreos, nuevoContacto.correo]);
            setContactoTelefonos([...contactoTelefonos, nuevoContacto.telefono]);
            setNuevoContacto({ nombre: '', rol: '', correo: '', telefono: '' });
        } else {
            alert('Todos los campos del contacto son requeridos.');
        }
    };
   
    const handleDeleteContacto = (index) => {
        setContactoNombres(contactoNombres.filter((_, i) => i !== index));
        setContactoRoles(contactoRoles.filter((_, i) => i !== index));
        setContactoCorreos(contactoCorreos.filter((_, i) => i !== index));
        setContactoTelefonos(contactoTelefonos.filter((_, i) => i !== index));
    };   

    return (
        <div className='modal-overlay'>
            <div className='modal1'>
                <div className='card'>
                    <form onSubmit={handleSubmit}>
                        <div className='card-header'>
                            <h3 className='card-title' style={{ textAlign: 'center' }}>Editar Cliente</h3>
                        </div>
                        <div className='card-body'>
                        <div className='input-group mb-3'>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value={0} checked={indicador === 0} onChange={handleRadioChange}/>
                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                        Nacional
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value={1} checked={indicador === 1} onChange={handleRadioChange}/>
                                    <label className="form-check-label" htmlFor="flexRadioDefault2">
                                        OffShore
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" value={2} checked={indicador === 2} onChange={handleRadioChange}/>
                                    <label className="form-check-label" htmlFor="flexRadioDefault3">
                                        Exportación
                                    </label>
                                </div>
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Nombre</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese nombre"
                                  value={nombre}
                                  onChange={(e) => setNombre(e.target.value)}
                                  required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Tax</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese tax"
                                  value={tax}
                                  onChange={(e) => setTax(e.target.value)}
                                  required
                                />
                            </div>
                            {indicador === 0 &&     
                                <div className='input-group mb-3'>
                                    <span className='input-group-text'>Giro</span>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Ingrese giro cliente"
                                      value={giro}
                                      onChange={(e) => setGiro(e.target.value)}
                                      required
                                    />
                                </div>                            
                            }
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Siglas</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese siglas cliente"
                                  value={siglas}
                                  onChange={(e) => setSiglas(e.target.value)}
                                  required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Dirección</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese dirección cliente"
                                  value={direccion}
                                  onChange={(e) => setDireccion(e.target.value)}
                                  required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Ciudad</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese ciudad cliente"
                                  value={ciudad}
                                  onChange={(e) => setCiudad(e.target.value)}
                                  required
                                />
                                <span className='input-group-text'>País</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese país cliente"
                                  value={pais}
                                  onChange={(e) => setPais(e.target.value)}
                                  required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Estado</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese estado"
                                  value={estado}
                                  onChange={(e) => setEstado(e.target.value)}
                                  required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Código Postal</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese código postal"
                                  value={codigo_postal}
                                  onChange={(e) => setCodigoPostal(e.target.value)}
                                  required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Nombre Contacto</span>
                                <input
                                type="text"
                                className="form-control"
                                placeholder="Ingrese nombre contacto"
                                value={nuevoContacto.nombre}
                                onChange={(e) => setNuevoContacto({ ...nuevoContacto, nombre: e.target.value })}
                                />
                                <span className='input-group-text'>Rol</span>
                                <input
                                type="text"
                                className="form-control"
                                placeholder="Ingrese rol contacto"
                                value={nuevoContacto.rol}
                                onChange={(e) => setNuevoContacto({ ...nuevoContacto, rol: e.target.value })}
                                />
                                <span className='input-group-text'>Correo</span>
                                <input
                                type="email"
                                className="form-control"
                                placeholder="Ingrese correo"
                                value={nuevoContacto.correo}
                                onChange={(e) => setNuevoContacto({ ...nuevoContacto, correo: e.target.value })}
                                />

                                <span className='input-group-text'>Teléfono</span>
                                <input
                                type="text"
                                className="form-control"
                                placeholder="Ingrese teléfono"
                                value={nuevoContacto.telefono}
                                onChange={(e) => setNuevoContacto({ ...nuevoContacto, telefono: e.target.value })}
                                />
                            </div>
                            <button type="button" className="btn btn-outline-primary" onClick={handleAgregarContacto}>
                                Agregar Contacto
                            </button>
                            <DataTable 
                                columns={[
                                    { name: 'Nombre', selector: (_, index) => contactoNombres[index] },
                                    { name: 'Rol', selector: (_, index) => contactoRoles[index] },
                                    { name: 'Correo', selector: (_, index) => contactoCorreos[index] },
                                    { name: 'Teléfono', selector: (_, index) => contactoTelefonos[index] },
                                    {
                                        name: 'Eliminar',
                                        cell: (_, index) => (
                                            <button
                                                type="button"
                                                className="btn btn-outline-danger"
                                                onClick={() => handleDeleteContacto(index)}
                                            >
                                                X
                                            </button>
                                        ),
                                        ignoreRowClick: true,
                                    },
                                ]}
                                data={contactoNombres.map((_, index) => index)} // Usa los índices como data
                                highlightOnHover
                                dense
                                pagination={true}
                            />

                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Contacto Finanzas</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese Contacto Finanzas"
                                  value={contacto_finanza}
                                  onChange={(e) => setContactoFinanza(e.target.value)}
                                />
                            </div>
                            <div className='input-group mb-3 form-check form-switch'>
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" onChange={handleSwitchChange} checked={indicadorsc === 1} />
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault" style={{ marginLeft: '10px' }}>Tiene sub-clientes</label>
                            </div> 
                            {indicadorsc === 1 &&
                                <>
                                <div className='input-group mb-3'>
                                    <span className='input-group-text'>SubClientes</span>
                                    <select 
                                        value={nuevoSubCliente}
                                        onChange={e => setNuevoSubCliente(e.target.value)}
                                        className='form-control'
                                    >
                                        <option value="">Seleccionar SubCliente</option>
                                        {opciones.subclientes.map(subcliente => (
                                            <option key={subcliente.id_subcliente} value={subcliente.id_subcliente}>
                                                {subcliente.nombre}
                                            </option>
                                        ))}
                                    </select>
                                    <button type="button" onClick={handleAgregarSC} className="btn btn-outline-primary" disabled={!nuevoSubCliente}>
                                        Agregar SubCliente
                                    </button>
                                </div>
                                <DataTable 
                                    columns={columnsSubClientes}
                                    data={subclientes}
                                    highlightOnHover
                                    dense
                                    pagination={true}
                                />
                                </>
                            }
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Productos</span>
                                <select 
                                    value={nuevoProducto}
                                    onChange={e => setNuevoProducto(e.target.value)}
                                    className='form-control'
                                >
                                    <option value="">Seleccionar Producto</option>
                                    {opciones.productos.map(producto => (
                                        <option key={producto.id_producto} value={producto.id_producto}>
                                            {producto.nombre}-{producto.espesor}
                                        </option>
                                    ))}
                                </select>
                                <button type="button" onClick={handleAgregarProducto} className="btn btn-outline-primary" disabled={!nuevoProducto}>
                                    Agregar Producto
                                </button>
                            </div>
                            <DataTable 
                                columns={columnsProductos}
                                data={productos}
                                highlightOnHover
                                dense
                                pagination={true}
                            />
                            <div className='input-group mb-2'>
                                <h5 style={{ margin: '0 auto', textAlign: 'center' }}>Línea de Crédito</h5>
                            </div>
                            <div className='input-group mb-3'>
                                <div className="form-check" style={{ margin: '0 auto', textAlign: 'center' }}>
                                    <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault3" value={0} checked={linea_credito === 0} onChange={handleRadioChange2}/>
                                    <label className="form-check-label" htmlFor="flexRadioDefault3">
                                        No tiene
                                    </label>
                                </div>
                                <div className="form-check" style={{ margin: '0 auto', textAlign: 'center' }}>
                                    <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault4" value={1} checked={linea_credito === 1} onChange={handleRadioChange2}/>
                                    <label className="form-check-label" htmlFor="flexRadioDefault4">
                                        Solicitada
                                    </label>
                                </div>
                                <div className="form-check" style={{ margin: '0 auto', textAlign: 'center' }}>
                                    <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault5" value={2} checked={linea_credito === 2} onChange={handleRadioChange2}/>
                                    <label className="form-check-label" htmlFor="flexRadioDefault5">
                                        Aprobada
                                    </label>
                                </div>
                                <div className="form-check" style={{ margin: '0 auto', textAlign: 'center' }}>
                                    <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault6" value={3} checked={linea_credito === 3} onChange={handleRadioChange2}/>
                                    <label className="form-check-label" htmlFor="flexRadioDefault6">
                                        Rechazada
                                    </label>
                                </div>
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Payment Term</span>
                                <select 
                                    className="form-control"
                                    value={payment_terms}
                                    onChange={(e) => seleccionPT(e.target.value)}
                                >
                                    <option value="">Seleccionar Payment Term</option>
                                    {pt.map(paymentterm => (
                                        <option key={paymentterm.id_paymentterm} value={paymentterm.id_paymentterm}>
                                            {paymentterm.nombre}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {indicador4 && 
                                <div className='input-group mb-3'>
                                    <span className='input-group-text'>Advance</span>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="Ingrese % advance"
                                      value={advance}
                                      onChange={(e) => setAdvance(e.target.value)}
                                    />
                                    <span className='input-group-text'>CAD</span>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="Ingrese % CAD"
                                      value={cad}
                                      onChange={(e) => setCAD(e.target.value)}
                                    />
                                </div>
                            }
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Puerto</span>
                                <select 
                                    className="form-control"
                                    value={puerto}
                                    onChange={(e) => setPuerto(e.target.value)}
                                >
                                    <option value="">Seleccionar Puerto</option>
                                    {opciones.puertos.map(puerto => (
                                        <option key={puerto.id_puerto} value={puerto.id_puerto}>
                                            {puerto.nombre}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>IncoTerm</span>
                                <select 
                                    className="form-control"
                                    value={incoterm}
                                    onChange={(e) => setIncoterm(e.target.value)}
                                >
                                    <option value="">Seleccionar IncoTerm</option>
                                    {opciones.incoterms.map(incoterm => (
                                        <option key={incoterm.id_incoterm} value={incoterm.id_incoterm}>
                                            {incoterm.nombre}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Dirección de Despacho</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese dirección de despacho"
                                  value={direccion_despacho}
                                  onChange={(e) => setDireccionDespacho(e.target.value)}
                                />
                            </div>
                            {indicador1 &&
                                <div className='input-group mb-3'>
                                    <span className='input-group-text'>Beneficiary Bank</span>
                                    <textarea
                                      type="text"
                                      className="form-control"
                                      placeholder="Ingrese beneficiary bank"
                                      value={beneficiary_bank}
                                      onChange={(e) => setBeneficiaryBank(e.target.value)}
                                    />
                                </div>
                            }
                            {indicador2 &&
                                <div className='input-group mb-3'>
                                    <span className='input-group-text'>Bank Information</span>
                                    <textarea
                                      type="text"
                                      className="form-control"
                                      placeholder="Ingrese Información del Banco"
                                      value={bank_information}
                                      onChange={(e) => setBankInformation(e.target.value)}
                                    />
                                </div>
                            }
                            {indicador3 &&
                                <div className='input-group mb-3'>
                                    <span className='input-group-text'>Especificaciones Línea de Crédito</span>
                                    <textarea
                                      type="text"
                                      className="form-control"
                                      placeholder="Ingrese especificaciones de línea de crédito"
                                      value={especificaciones_lc}
                                      onChange={(e) => setEspecificacionesLC(e.target.value)}
                                    />
                                </div>
                            }
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Quality Standards</span>
                                <textarea
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese estandares de calidad"
                                  value={quality_standards}
                                  onChange={(e) => setQualityStandards(e.target.value)}
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Documentos</span>
                                <select 
                                    value={nuevoDocumento}
                                    onChange={e => setNuevoDocumento(e.target.value)}
                                    className='form-control'
                                >
                                    <option value="">Seleccionar Documento</option>
                                    {opciones.documentos.map(documento => (
                                        <option key={documento.id_documento} value={documento.id_documento}>
                                            {documento.nombre}
                                        </option>
                                    ))}
                                </select>
                                <button type="button" onClick={handleAgregarDocumento} className="btn btn-outline-primary" disabled={!nuevoDocumento}>
                                    Agregar Documento
                                </button>
                            </div>
                            <DataTable 
                                columns={columnsDocumentos}
                                data={documentosRequeridos}
                                highlightOnHover
                                dense
                                pagination={true}
                            />
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Correlativo PI</span>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Ingrese correlativo ultima PI generada"
                                  value={correlativo}
                                  onChange={(e) => setCorrelativo(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='card-footer' style={{ textAlign: 'center' }}>
                            <button type="submit" className="btn btn-outline-success mb-3 mt-3" disabled={botonDeshabilitado}>Guardar</button>
                            <button type="button" className="btn btn-outline-danger mb-3 mt-3" onClick={cerrar}>Cancelar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditarCliente;
