import React, { useState } from 'react';

const AgregarShippingDoc = ({ opciones, cerrar }) => {
    const [botonDeshabilitado, setBotonDeshabilitado] = useState(false);
    const [documentos, setDocumentos] = useState([]);
    const [proforma, setProforma] = useState(null);
    const [direccion1, setDireccion1] = useState('');
    const [direccion2, setDireccion2] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        setBotonDeshabilitado(true);

        const tdocumentos = [];
        const contenidos = [];
        const destinos = [];

        documentos.forEach(documento => {
            tdocumentos.push(documento.documento);
            contenidos.push(documento.contenido);
            destinos.push(documento.destino);
        });

        const nuevoShippingDoc = {
            documentos: tdocumentos,
            destinos: destinos,
            contenidos: contenidos,
            proforma: parseInt(proforma),
            direccion1,
            direccion2
        };

        const token = localStorage.getItem('token');

        fetch('https://api.dvstarlab.com/agregarshippingdoc', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(nuevoShippingDoc),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Error al agregar documento de envío');
            }
            return response.json();
        })
        .then(data => {
            console.log('Documento de envío agregado:', data);
            alert('Documento de envío agregado exitosamente');
            window.location.reload();
        })
        .catch(error => {
            console.error('Error:', error);
        })
        .finally(() => {
            setBotonDeshabilitado(false);
        });
    };

    const handleAgregarDocumento = () => {
        // Verificar si se ha seleccionado una PI
        if (!proforma) {
            alert('Seleccione primero una proforma.');
            return; // Salir de la función si no se ha seleccionado una proforma
        }

        // Verificar si ya se han agregado todos los documentos requeridos
        const proformaExistente = opciones.proformas.find(p => p.id_proforma === parseInt(proforma)) || [];
        const documentosRequeridos = proformaExistente?.documentos
        if (documentos.length >= documentosRequeridos.length) {
            alert('Ya se han agregado todos los documentos requeridos.');
            return; // Salir de la función si ya se han agregado todos los documentos requeridos
        }
        
        setDocumentos([
            ...documentos,
            { documento: 0, destino: '', contenido: '' }
        ]);
    };

    const handleDocumentoChange = (index, key, value) => {
        const newDocumentos = [...documentos];
        newDocumentos[index][key] = value;
        setDocumentos(newDocumentos);
    };

    return (
        <div className='modal-overlay'>
            <div className='modal1'>
                <div className='card'>
                    <form onSubmit={handleSubmit}>
                        <div className='card-header'>
                            <h3 className='card-title' style={{ textAlign: 'center' }}>Agregar Documento de Envío</h3>
                        </div>
                        <div className='card-body'>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Proforma</span>
                                <select 
                                    className="form-control"
                                    value={proforma}
                                    onChange={(e) => setProforma(e.target.value)}
                                    required
                                >
                                    <option value="">Seleccionar Proforma</option>
                                    {opciones.proformas.map((proforma) => {
                                    const clienteExistente = opciones.clientes.find(c => c.id_cliente === proforma.cliente);
                                    if (clienteExistente) {
                                        const shippingdocumentcreado = opciones.shippingdocs?.find(sd => sd.proforma === proforma.id_proforma); // Verifica si hay una SD asociada a esta PI
                                        if (!shippingdocumentcreado) {
                                            return (
                                                <option key={proforma.id_proforma} value={proforma.id_proforma}>
                                                    {'Nro proforma -> ' + proforma.id_proforma + ' - ' + clienteExistente.nombre}
                                                </option>
                                            );
                                        }
                                    }
                                    return null; // Si ya hay una SD asociada, omitir la opción
                                    })}
                                </select>
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Dirección 1</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={direccion1}
                                    onChange={(e) => setDireccion1(e.target.value)}
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Dirección 2</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={direccion2}
                                    onChange={(e) => setDireccion2(e.target.value)}
                                />
                            </div>
                            <button type="button" className="btn btn-outline-primary" onClick={handleAgregarDocumento}>Agregar Documento</button>
                            {documentos.map((documento, index) => (
                                <div key={index}>
                                    <hr />
                                    <div className="input-group mb-3">
                                        <span className='input-group-text'>Documento {index + 1}</span>
                                        <select
                                            className="form-select"
                                            value={documento.documento}
                                            onChange={(e) => handleDocumentoChange(index, 'tipoDoc', e.target.value)}
                                        >
                                            <option value="">Seleccione un tipo de documento</option>
                                            {proforma && 
                                                // Filtrar los documentos requeridos asociados a la PI seleccionada
                                                opciones.proformas.map(pi => {
                                                    if (pi.id_proforma === parseInt(proforma)) {
                                                        const documentosRequeridos = pi.documentos|| [];
                                                        // Filtrar los documentos que ya están presentes en el arreglo documentos
                                                        const documentosFiltrados = documentosRequeridos.filter(dr => !documentos.some(doc => parseInt(doc.documento) === parseInt(dr)));
                                                        // Mapear los documentos requeridos a opciones solo para aquellos que aún no se han agregado
                                                        return documentosFiltrados.map(dr => {
                                                            const doc = opciones.documentos.find(d => d.id_documento === parseInt(dr));
                                                            return doc ? <option key={doc.id_documento} value={parseInt(doc.id_documento)}>{doc.nombre}</option> : null;
                                                        });
                                                    } else {
                                                        return null;
                                                    }
                                                })
                                            }
                                            {/* Incluye la opción seleccionada actual incluso si se ha filtrado */}
                                            {documento.documento && (
                                                <option key={documento.documento} value={documento.documento} disabled>{opciones.documentos.find(d => d.id_documento === parseInt(documento.documento))?.nombre}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className='input-group mb-3'>
                                        <span className='input-group-text'>Destino {index + 1}</span>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Ingrese destino"
                                          value={documento.destino}
                                          onChange={(e) => handleDocumentoChange(index, 'destino', e.target.value)}
                                          required
                                        />
                                    </div>
                                    <div className='input-group mb-3'>
                                        <span className='input-group-text'>Contenido {index + 1}</span>
                                        <textarea
                                            className="form-control"
                                            rows="3"
                                            value={documento.contenido}
                                            onChange={(e) => handleDocumentoChange(index, 'contenido', e.target.value)}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className='card-footer' style={{ textAlign: 'center' }}>
                            <button type="submit" className="btn btn-outline-success mb-3 mt-3" disabled={botonDeshabilitado}>Guardar</button>
                            <button type="button" className="btn btn-outline-danger mb-3 mt-3" onClick={cerrar}>Cancelar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AgregarShippingDoc;
