import React, { useState } from 'react';

const EditarPaymentTerm = ({ pt, cerrar, guardar }) => {
    const [nombre, setNombre] = useState(pt.nombre);
    const [reglas, setReglas] = useState(pt.reglas || []);
    const [indicador1, setIndicador1] = useState(reglas.includes(1));
    const [indicador2, setIndicador2] = useState(reglas.includes(2));
    const [indicador3, setIndicador3] = useState(reglas.includes(3));
    const [indicador4, setIndicador4] = useState(reglas.includes(4));

    const handleSwitchChange = (event, indicador) => {
        const checked = event.target.checked;
        let nuevasReglas = [...reglas];
        
        if (checked) {
            nuevasReglas.push(indicador);
        } else {
            nuevasReglas = nuevasReglas.filter(regla => regla !== indicador);
        }
        
        setReglas(nuevasReglas);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const updatedPT = {
            id_paymentterm: pt.id_paymentterm,
            nombre,
            reglas,
        };

        guardar(updatedPT);
        cerrar();
    };

    return (
        <div className='modal-overlay'>
            <div className='modal1'>
                <div className='card'>
                    <form onSubmit={handleSubmit}>
                        <div className='card-header'>
                            <h3 className='card-title' style={{ textAlign: 'center' }}>Editar Payment Term</h3>
                        </div>
                        <div className='card-body'>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Nombre</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Ingrese nombre del payment term"
                                    value={nombre}
                                    onChange={(e) => setNombre(e.target.value)}
                                    required
                                />
                            </div>
                            <div className='input-group mb-3 form-check form-switch'>
                                <input 
                                    className="form-check-input" 
                                    type="checkbox" 
                                    id="flexSwitchCheckDefault" 
                                    checked={indicador1} 
                                    onChange={(e) => handleSwitchChange(e, 1)} 
                                />
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault" style={{ marginLeft: '10px' }}>Beneficiary Bank</label>
                            </div>
                            <div className='input-group mb-3 form-check form-switch'>
                                <input 
                                    className="form-check-input" 
                                    type="checkbox" 
                                    id="flexSwitchCheckDefault2" 
                                    checked={indicador2} 
                                    onChange={(e) => handleSwitchChange(e, 2)} 
                                />
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault2" style={{ marginLeft: '10px' }}>Bank Information</label>
                            </div>
                            <div className='input-group mb-3 form-check form-switch'>
                                <input 
                                    className="form-check-input" 
                                    type="checkbox" 
                                    id="flexSwitchCheckDefault3" 
                                    checked={indicador3} 
                                    onChange={(e) => handleSwitchChange(e, 3)} 
                                />
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault3" style={{ marginLeft: '10px' }}>LC Especifications</label>
                            </div>
                            <div className='input-group mb-3 form-check form-switch'>
                                <input 
                                    className="form-check-input" 
                                    type="checkbox" 
                                    id="flexSwitchCheckDefault4" 
                                    checked={indicador4} 
                                    onChange={(e) => handleSwitchChange(e, 4)} 
                                />
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault4" style={{ marginLeft: '10px' }}>CAD y Aduana</label>
                            </div>
                        </div>
                        <div className='card-footer' style={{ textAlign: 'center' }}>
                            <button 
                                type="submit" 
                                className="btn btn-outline-success mb-3 mt-3"
                            >
                                Guardar cambios
                            </button>
                            <button type="button" className="btn btn-outline-danger" onClick={cerrar}>Cancelar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default EditarPaymentTerm;
