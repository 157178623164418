import React, { useState } from 'react';
import DataTable from 'react-data-table-component';

function EditarCategoria({ categoria, cerrar, guardar, calidades }) {
    const [botonDeshabilitado, setBotonDeshabilitado] = useState(false);
    const [nombre, setNombre] = React.useState(categoria.nombre || '');
    const [ingles, setIngles] = React.useState(categoria.nombre_ingles || '');
    const [portugues, setPortugues] = React.useState(categoria.nombre_portugues || '');
    const [Calidades, setCalidades] = React.useState(categoria.calidades || []);
    const [nuevaCalidad, setNuevaCalidad] = useState(null);
    const [ncm, setNcm] = React.useState(categoria.ncm || '')

    const columns = [
        {
            name: 'ID Calidad',
            selector: (row) => row
        },
        {
            name: 'Calidad',
            selector: row => {
                if (row) {
                    const calidadEncontrada = calidades.find(calidad => calidad.id_calidad === parseInt(row));
                    if (calidadEncontrada) {
                        return calidadEncontrada.calidad;
                    }
                }
            },
            width: '200px',
        },
        {
            name: 'Eliminar',
            cell: row => (
                <button
                    type="button"
                    className="btn btn-outline-danger"
                    onClick={() => {
                        if (window.confirm('¿Estás seguro?')) {
                            handleDeleteCalidad(row);
                        }
                    }}
                >
                    X
                </button>
            ),
            ignoreRowClick: true,
        },
    ];

    const handleAgregarCalidad = () => {
        const nuevasCalidades = [...Calidades, parseInt(nuevaCalidad)];
        setCalidades(nuevasCalidades);
        setNuevaCalidad(null);
    };

    const handleDeleteCalidad = (id) => {
        const updatedCalidades = Calidades.filter((calidad) => calidad !== id);
        setCalidades(updatedCalidades);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setBotonDeshabilitado(true);

        const editedCategoria = {
            ...categoria,
            nombre: nombre,
            nombre_ingles: ingles,
            nombre_portugues: portugues,
            calidades: Calidades,
            ncm,
        };

        guardar(editedCategoria);
        console.log(editedCategoria);
        cerrar();
    };

    return (
        <div className='modal-overlay'>
            <div className='modal1'>
                <div className='card'>
                    <form onSubmit={handleSubmit}>
                        <div className='card-header'>
                            <h3 style={{ textAlign: 'center' }}>Editar Categoria</h3>
                        </div>
                        <div className='card-body'>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Nombre</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Ingrese nombre categoría"
                                    value={nombre}
                                    onChange={(e) => setNombre(e.target.value)}
                                    required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>En inglés</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Ingrese categoría"
                                    value={ingles}
                                    onChange={(e) => setIngles(e.target.value)}
                                    required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>En portugués</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Ingrese categoría"
                                    value={portugues}
                                    onChange={(e) => setPortugues(e.target.value)}
                                    required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>NCM</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese NCM"
                                  value={ncm}
                                  onChange={(e) => setNcm(e.target.value)}
                                  required
                                />
                            </div>
                            <div className='mb-3'>
                                <h5>Calidades</h5>
                                <div className='input-group mb-3'>
                                    <span className='input-group-text'>
                                        Calidad
                                    </span>
                                    <select
                                        value={nuevaCalidad}
                                        onChange={e => setNuevaCalidad(e.target.value)}
                                        className='form-control'
                                    >
                                        <option value="">Seleccionar Calidad</option>
                                        {calidades.map((calidad) => {
                                            const calidadExistente = Calidades.some(cal => parseInt(cal) === parseInt(calidad.id_calidad));
                                            if (!calidadExistente) {
                                                return (
                                                    <option key={calidad.id_Calidad} value={calidad.id_calidad}>
                                                        {calidad.calidad}
                                                    </option>
                                                );
                                            }
                                            return null;
                                        })}
                                    </select>
                                    <button type="button" onClick={handleAgregarCalidad} className="btn btn-outline-primary" disabled={!nuevaCalidad}>
                                        Agregar Calidad
                                    </button>
                                </div>
                                <DataTable
                                    columns={columns}
                                    data={Calidades}
                                    highlightOnHover
                                    dense
                                    pagination={true}
                                />
                            </div>
                        </div>
                        <div className='card-footer' style={{ textAlign: 'center' }}>
                            <button type="submit" className="btn btn-outline-success mb-3 mt-3" disabled={botonDeshabilitado}>Guardar</button>
                            <button type="button" className="btn btn-outline-danger mb-3 mt-3" onClick={cerrar}>Cancelar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default EditarCategoria;
