import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';

function EditarProducto({ producto, cerrar, guardar, opciones, medidas }) {
    const [botonDeshabilitado, setBotonDeshabilitado] = useState(false);
    const [categoria, setCategoria] = useState(producto.categoria || '');
    const [especie, setEspecie] = useState(producto.especie || '');
    const [ncm, setNcm] = useState(producto.ncm || '');
    const [medida, setMedida] = useState(producto.medida || '');
    const [espesor, setEspesor] = useState(producto.espesor || 0);
    const [calidades, setCalidades] = useState(producto.calidades || []);
    const [nuevaCalidad, setNuevaCalidad] = useState(null);

    // Estado para almacenar los espesores disponibles para la medida seleccionada
    const [espesoresDisponibles, setEspesoresDisponibles] = useState([]);

    useEffect(() => {
        if (medida) {
            // Buscar la medida seleccionada en las opciones y obtener sus espesores
            const medidaSeleccionada = medidas.find(m => m.id_medida === parseInt(medida));
            if (medidaSeleccionada && medidaSeleccionada.espesor) {
                setEspesoresDisponibles(medidaSeleccionada.espesor);
                // Verificar si el espesor actual está en la lista de espesores disponibles
                if (medidaSeleccionada.espesor.includes(producto.espesor)) {
                    setEspesor(producto.espesor);
                } else {
                    setEspesor(''); // Reiniciar si no coincide
                }
            } else {
                setEspesoresDisponibles([]);
                setEspesor(''); // Reiniciar si no hay espesores disponibles
            }
        }
    }, [medida, medidas, producto.espesor]);

    const handleSubmit = (event) => {
        event.preventDefault();
        setBotonDeshabilitado(true);

        const editedProducto = {
            id_producto: producto.id_producto,
            categoria,
            especie,
            ncm,
            medida,
            espesor: parseFloat(espesor), // Incluir el espesor seleccionado
            calidades: calidades.map(c => ({ id_calidad: c}))
        };

        console.log(editedProducto)

        guardar(editedProducto);
    };

    const columns = [
        {
            name: 'ID Calidad',
            selector: row => row
        },
        {
            name: 'Calidad',
            selector: row => {
                if (row) {
                    const calidadEncontrada = opciones.calidades.find(calidad => calidad.id_calidad === parseInt(row));
                    if (calidadEncontrada) {
                        return calidadEncontrada.calidad;
                    }
                }
            },
        },
        {
            name: 'Eliminar',
            cell: row => (
                <button 
                    type="button"
                    className="btn btn-outline-danger"
                    onClick={() => {
                        if (window.confirm('¿Estás seguro?')) {
                            handleDeleteCalidad(row);
                        }
                    }}
                >
                    X
                </button>
            ),
            ignoreRowClick: true,
        },
    ];

    const handleAgregarCalidad = () => {
        const nuevasCalidades = [...calidades, parseInt(nuevaCalidad)];
        setCalidades(nuevasCalidades);
        setNuevaCalidad(null);
    };

    const handleDeleteCalidad = (id) => {
        const updatedCalidades = calidades.filter((calidad) => calidad !== id);
        setCalidades(updatedCalidades);
    };

    return (
        <div className='modal-overlay'>
            <div className='modal1'>
                <div className='card'>
                    <form onSubmit={handleSubmit}>
                        <div className='card-header'>
                            <h3 className='card-title' style={{ textAlign: 'center' }}>Editar Producto</h3>
                        </div>
                        <div className='card-body'>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Categoría</span>
                                <select 
                                    className="form-control"
                                    value={categoria}
                                    onChange={(e) => setCategoria(e.target.value)}
                                    required
                                >
                                    <option value="">Seleccionar Categoría</option>
                                    {opciones.categorias.map(categoria => (
                                        <option key={categoria.id_categoria} value={categoria.id_categoria}>
                                            {categoria.nombre}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Especie</span>
                                <select 
                                    className="form-control"
                                    value={especie}
                                    onChange={(e) => setEspecie(e.target.value)}
                                    required
                                >
                                    <option value="">Seleccionar Especie</option>
                                    {opciones.especies.map(especie => (
                                        <option key={especie.id_especie} value={especie.id_especie}>
                                            {especie.nombre}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>NCM</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese NCM"
                                  value={ncm}
                                  onChange={(e) => setNcm(e.target.value)}
                                  required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Medida</span>
                                <select 
                                    className="form-control"
                                    value={medida}
                                    onChange={(e) => setMedida(e.target.value)}
                                    required
                                >
                                    <option value="">Seleccionar Medida</option>
                                    {opciones.medidas.map(medida => (
                                        <option key={medida.id_medida} value={medida.id_medida}>
                                            {medida.medida}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {medida && (
                                <div className='input-group mb-3'>
                                    <span className='input-group-text'>Espesor</span>
                                    <select 
                                        className="form-control"
                                        value={espesor}
                                        onChange={(e) => setEspesor(e.target.value)}
                                        required
                                    >
                                        <option value={0}>Seleccionar Espesor</option>
                                        {espesoresDisponibles.map((espesor, index) => (
                                            <option key={index} value={espesor}>
                                                {espesor}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}
                            <div className='mb-3'>
                                <h5>Calidades</h5>
                                <div className='input-group mb-3'>
                                    <span className='input-group-text'>Calidad</span>
                                    <select 
                                        value={nuevaCalidad}
                                        onChange={e => setNuevaCalidad(e.target.value)}
                                        className='form-control'
                                    >
                                        <option value="">Seleccionar Calidad</option>
                                        {opciones.calidades.map(calidad => (
                                            <option key={calidad.id_calidad} value={calidad.id_calidad}>
                                                {calidad.calidad}
                                            </option>
                                        ))}
                                    </select>
                                    <button 
                                        type="button" 
                                        onClick={handleAgregarCalidad} 
                                        className="btn btn-outline-primary" 
                                        disabled={!nuevaCalidad}
                                    >
                                        Agregar Calidad
                                    </button>
                                </div>
                                <DataTable 
                                  columns={columns}
                                  data={calidades}
                                  highlightOnHover
                                  dense
                                  pagination={true}   
                                />
                            </div>
                        </div>
                        <div className='card-footer' style={{ textAlign: 'center' }}>
                            <button 
                              type="submit" 
                              className="btn btn-outline-success mb-3 mt-3"
                              disabled={botonDeshabilitado}
                            >
                                Guardar
                            </button>
                            <button type="button" className="btn btn-outline-danger" onClick={cerrar}>Cancelar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default EditarProducto;
