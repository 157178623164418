import React, { useState } from 'react';

const AgregarEspecie = ({cerrar}) => {
    const [botonDeshabilitado, setBotonDeshabilitado] = useState(false);
    const [nombre, setNombre] = useState('');
    const [ingles, setIngles] = useState('');
    const [portugues, setPortugues] = useState('');
    const [ncm, setNcm] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        setBotonDeshabilitado(true);

        const nuevaEspecie = {
            nombre,
            nombre_ingles: ingles,
            nombre_portugues: portugues,
            ncm
        };

        const token = localStorage.getItem('token');

        fetch('https://api.dvstarlab.com/agregarespecie', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(nuevaEspecie),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Error al agregar especie');
            }
            return response.json();
        })
        .then(data => {
            console.log('Especie agregada:', data);
            alert('Especie ' + nombre + ' agregada exitosamente');
            window.location.reload();
        })
        .catch(error => {
            console.error('Error:', error);
        })
        .finally(() => {
            setBotonDeshabilitado(false);
        });
    }

    return (
        <div className='modal-overlay'>
            <div className='modal1'>
                <div className='card'>
                    <form onSubmit={handleSubmit}>
                        <div className='card-header'>
                            <h3 className='card-title' style={{ textAlign: 'center' }}>Agregar Especie</h3>
                        </div>
                        <div className='card-body'>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Nombre</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Ingrese Especie"
                                    value={nombre}
                                    onChange={(e) => setNombre(e.target.value)}
                                    required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>En inglés</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Ingrese Especie"
                                    value={ingles}
                                    onChange={(e) => setIngles(e.target.value)}
                                    required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>En portugués</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Ingrese Especie"
                                    value={portugues}
                                    onChange={(e) => setPortugues(e.target.value)}
                                    required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>NCM</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese NCM"
                                  value={ncm}
                                  onChange={(e) => setNcm(e.target.value)}
                                  required
                                />
                            </div>
                        </div>
                        <div className='card-footer' style={{ textAlign: 'center' }}>
                            <button 
                                type="submit" 
                                className="btn btn-outline-success mb-3 mt-3"
                                disabled={botonDeshabilitado}
                            >
                                Agregar
                            </button>
                            <button type="button" className="btn btn-outline-danger" onClick={cerrar}>Cancelar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default AgregarEspecie