import React, { useState, useEffect, useCallback } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ptcImage from '../../imagenes/ptc.jpg';
import ptbImage from '../../imagenes/ptb.jpg';

const GenerarPDF = ({ ordencompra, proveedores, incoterms, puertos, productos, documentos, usuarios, categorias, especies, medidas, calidades, clientes, almacenes, ordenescompra, cerrar }) => {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getUTCFullYear();
        const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
        const day = date.getUTCDate().toString().padStart(2, '0');
        return `${day}-${month}-${year}`;
    };

    function formatMonthYear(dateString) {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long' }; // Muestra el mes completo y el año
        return date.toLocaleDateString('pt-BR', options);
    }

    function formatNumber(value) {
        return new Intl.NumberFormat('en-US', { 
            minimumFractionDigits: 2, 
            maximumFractionDigits: 2 
        }).format(value);
    }

    const emision = ordencompra.emision ? formatDate(ordencompra.emision) : '';
    const entrega = ordencompra.entrega ? formatDate(ordencompra.entrega) : '';
    const proveedor = ordencompra.proveedor || 0;
    const condicion_pago = ordencompra.condicion_pago || '';
    const requisitos_calidad = ordencompra.requisitos_calidad || '';
    const requisitos_etiquetado = ordencompra.requisitos_etiquetado || '';
    const valor_ordencompra = ordencompra.valor_ordencompra || 0;
    const documentosRequeridos = ordencompra.documentos || [];
    const observaciones = ordencompra.observaciones || '';
    const descripcion_producto = ordencompra.descripcion_producto || '';
    const estado = ordencompra.estado || 0;
    const [informacion_adicional] = useState(ordencompra.informacion_adicional || '');
    const [infoProveedor, setInfoProveedor] = useState(null);
    const [nroOrden, setNroOrden] = useState(ordencompra.correlativo);
    const fechaDate = new Date(ordencompra.emision);
    const year = fechaDate.getFullYear().toString().substr(-2);
    const [mCubicos, setMCubicos] = useState(null);
    const [contador, setContador] = useState(0);
    const [empresaEmisor, setEmpresaEmisor] = useState(ordencompra.empresa_emisor||0);

    const getCurrencySymbol = (moneda) => {
        switch(moneda) {
            case 0: return 'USD';
            case 1: return 'EUR';
            case 2: return 'CLP';
            case 3: return 'BRL';
            case 4: return 'PYG';
            default: return 'USD';
        }
    };

    const Productos = ordencompra.productos.map((producto, index) => ({
        id_producto: producto || 0,
        real_size: ordencompra.real_size[index] || '',
        m3: ordencompra.m3[index] || 0,
        usd_m3: ordencompra.usd_m3[index] || 0,
        usd_total: ordencompra.usd_total[index] || 0,
        calidad: ordencompra.calidades[index] || 0,
    }));

    useEffect(() => {
        if (proveedor && !infoProveedor) {
            const info = proveedores.find(p => p.id_proveedor === proveedor);
            if (info) {
                setInfoProveedor(info);
            }
        }
    }, [proveedor, infoProveedor, proveedores, ordencompra, ordenescompra]);

    useEffect(() => {
        if (Productos.length > 0 && mCubicos === null) {
            const totalM3 = Productos.reduce((total, producto) => total + parseFloat(producto.m3), 0);
            setMCubicos(totalM3);
        }
    }, [Productos, mCubicos]);

    const generatePDF = useCallback(() => {
        let fontSize = 11;
        let imageSize = 60; // Tamaño inicial del logo
        let cellPadding = 1; // Padding inicial de las celdas
        let spacing = 5; // Espaciado inicial entre tablas
        let isOnePage = false;

        while (!isOnePage && fontSize > 5) {
            const doc = new jsPDF();
            let y = 10;

            const title = `OC ${infoProveedor?.siglas} ${nroOrden ? nroOrden < 10 ? '0' + nroOrden : nroOrden : ''}-${year}`;
            doc.setFontSize(fontSize);

            console.log(ordencompra.empresa_emisor)
            // Agregar logo
            if(ordencompra.empresa_emisor === 0){
                doc.addImage(ptcImage, 'JPEG', 15, y, imageSize, imageSize * 0.33); // Ajuste dinámico del tamaño del logo
                y += imageSize * 0.33 + spacing;
            }else if(ordencompra.empresa_emisor === 1){
                doc.addImage(ptbImage, 'JPEG', 15, y, imageSize, imageSize * 0.33); // Ajuste dinámico del tamaño del logo
                y += imageSize * 0.33 + spacing;
            }else{
                doc.addImage(ptcImage, 'JPEG', 15, y, imageSize, imageSize * 0.33); // Ajuste dinámico del tamaño del logo
                y += imageSize * 0.33 + spacing;
            }

            // Título
            doc.setFont('helvetica', 'bold');
            doc.text(title.toUpperCase(), doc.internal.pageSize.getWidth() / 2, y, { align: 'center' });
            y += spacing;

            // Fecha
            doc.setFontSize(fontSize - 2);
            doc.text(`Date: ${emision}`, doc.internal.pageSize.getWidth() - 15, y, { align: 'right' });
            y += spacing;

            // Información del proveedor
            // ${puertos.find(p => p.id_puerto === ordencompra.puerto)?.nombre || ''}, ${puertos.find(p => p.id_puerto === ordencompra.puerto)?.pais || ''}
            doc.setFontSize(fontSize);
            doc.text('Fornecedor', 15, y);
            doc.autoTable({
                startY: y + spacing,
                body: [
                    [`${infoProveedor?.nombre || ''}`, `Condições de Venda: ${incoterms.find(i => i.id_incoterm === ordencompra.incoterm)?.nombre || ''}`],
                    [`Address: ${infoProveedor?.direccion || ''}`, `Data de Embarque: ${formatMonthYear(ordencompra.entrega)}`],
                    [`TAX ID: ${infoProveedor?.tax || ''}`, `Condição de Pagamento: ${condicion_pago || ''}`],
                    [`Attn: ${infoProveedor?.contacto || ''}`, ''],
                ],
                styles: { cellPadding: cellPadding, fontSize: fontSize - 1, valign: 'top' },
                columnStyles: {
                    0: { cellWidth: 95 },
                    1: { cellWidth: 95 },
                },
                theme: 'grid',
            });
            y = doc.lastAutoTable.finalY + spacing;

            // Información de origen y destino
            doc.autoTable({
                startY: y,
                body: [
                    [`Origen: ${ordencompra.origen || ''}`, 'Embalagem: Pacotes'],
                    [``, `Embalagem ISPM-15. Só é permitido usar skids de madeira serrada`],
                    [`Etiqueta: Formato de etiqueta anexada`, ''],
                ],
                styles: { cellPadding: cellPadding, fontSize: fontSize - 1, valign: 'top' },
                columnStyles: {
                    0: { cellWidth: 95 },
                    1: { cellWidth: 95 },
                },
                theme: 'grid',
            });
            y = doc.lastAutoTable.finalY + spacing;

            // Descripción del producto
            doc.autoTable({
                startY: y,
                body: [
                    [{ content: 'Descrição do Produto', colSpan: 2, styles: { halign: 'center', fontStyle: 'bold' } }],
                    [{ content: `${descripcion_producto}`, colSpan: 2, styles: { halign: 'center' } }],
                ],
                styles: { cellPadding: cellPadding, fontSize: fontSize - 1, valign: 'top' },
                columnStyles: {
                    0: { cellWidth: 95 },
                    1: { cellWidth: 95 },
                },
                theme: 'grid',
            });
            y = doc.lastAutoTable.finalY + spacing;

            // Detalles del producto
            const detallesData = Productos.map(producto => {
                const productoActual = productos.find(p => p.id_producto === producto.id_producto);
                if (!productoActual) {
                    return ['', producto.real_size, producto.calidad, producto.m3, producto.usd_m3, producto.usd_total];
                }
                const medidaProducto = medidas.find(med => med.id_medida === productoActual.medida);
                const categoriaEncontrada = categorias.find(cat => cat.id_categoria === productoActual.categoria);
                const especieEncontrada = especies.find(esp => esp.id_especie === productoActual.especie);
                const productoNombre = `${categoriaEncontrada?.nombre_portugues} ${especieEncontrada?.nombre_portugues}`;
                const medidaFacturacion = `${productoActual.espesor} X ${medidaProducto.ancho} X ${medidaProducto.largo}`;

                return [
                    productoNombre,
                    medidaFacturacion,
                    producto.real_size || '',
                    calidades.find(cal => cal.id_calidad === producto.calidad)?.calidad || '',
                    formatNumber(producto.m3 || 0),
                    formatNumber(producto.usd_m3),
                    formatNumber(producto.usd_total)
                ];
            });

            const totalM3 = Productos.reduce((total, producto) => total + (Number(producto.m3) || 0), 0).toFixed(2);


            detallesData.push([
                { content: 'TOTAL', styles: { fontStyle: 'bold' } },
                '',
                '',
                '',
                { content: formatNumber(totalM3), styles: { fontStyle: 'bold' } },
                '',
                { content: formatNumber(ordencompra.valor_ordencompra), styles: { fontStyle: 'bold' } }
            ]);

            doc.autoTable({
                startY: y,
                head: [['Produto', 'Medida faturamento', 'Medida Real', 'Classificação', 'M3', `${getCurrencySymbol(ordencompra.moneda)}/m3`, `Total ${getCurrencySymbol(ordencompra.moneda)}`]],
                body: detallesData,
                styles: { cellPadding: cellPadding, fontSize: fontSize - 1, valign: 'top', halign: 'center' },
                columnStyles: {
                    0: { cellWidth: 40 },
                    1: { cellWidth: 35 },
                    2: { cellWidth: 35 },
                    3: { cellWidth: 26 },
                    4: { cellWidth: 18 },
                    5: { cellWidth: 18 },
                    6: { cellWidth: 18 },
                },
                theme: 'grid',
            });
            y = doc.lastAutoTable.finalY + spacing;

            console.log("Valores M3 de Productos:", Productos.map(p => p.m3));
            console.log("Total M3 calculado:", totalM3);

            // Información del banco y estándares de calidad
            doc.autoTable({
                startY: y,
                body: [
                    [{ content: 'Especificações de qualidade', styles: { fontStyle: 'bold' } }],
                    [requisitos_calidad],
                    [{ content: 'Especificações adicionais', styles: { fontStyle: 'bold' } }],
                    [informacion_adicional],
                    [{ content: 'Etiquetado', styles: { fontStyle: 'bold' } }],
                    [requisitos_etiquetado],
                    [{ content: 'OBSERVAÇÕES', styles: { fontStyle: 'bold' } }],
                    [observaciones],
                ],
                styles: { cellPadding: cellPadding, fontSize: fontSize - 1, valign: 'top' },
                columnStyles: {
                    0: { cellWidth: 190 },
                },
                theme: 'grid',
            });
            y = doc.lastAutoTable.finalY + spacing;

            // Documentos requeridos
            const documentosr = documentosRequeridos.map(doc => {
                const documentoActual = documentos.find(td => td.id_documento === doc);
                return documentoActual ? documentoActual.nombre : '';
            }).join(' / ');

            doc.autoTable({
                startY: y,
                body: [
                    [{ content: 'Documentos Requeridos', styles: { fontStyle: 'bold' } }],
                    [documentosr]
                ],
                styles: { cellPadding: cellPadding, fontSize: fontSize - 1, valign: 'top' },
                columnStyles: {
                    0: { cellWidth: 190 },
                },
                theme: 'grid',
            });
            y = doc.lastAutoTable.finalY + spacing;

            // Espacio para las firmas
            const firmaNombre = estado === 2 ? "Juan Pablo Opazo\nCommercial Director" : "";
            doc.autoTable({
                startY: y,
                body: [[firmaNombre, '']],
                styles: { cellPadding: cellPadding, fontSize: fontSize - 1, valign: 'top', halign: 'center' },
                columnStyles: { 0: { cellWidth: 95 }, 1: { cellWidth: 95 } },
                theme: 'grid',
            });
            y = doc.lastAutoTable.finalY + spacing;

            // Tabla final para Palm Trade Chile SPA
            doc.autoTable({
                startY: y,
                body: [
                    [{ content: ordencompra.empresa_emisor === 0 ? 'Palm Trade Chile SPA' : ordencompra.empresa_emisor === 1 ? 'Palm Trade Brasil LTDA' : 'Palm Trade Chile SPA' , styles: { fontStyle: 'bold' } }, { content: `${infoProveedor?.nombre}`, styles: { fontStyle: 'bold' } }],
                ],
                styles: { cellPadding: cellPadding, fontSize: fontSize - 1, valign: 'top', halign: 'center' },
                columnStyles: { 0: { cellWidth: 95 }, 1: { cellWidth: 95 } },
                theme: 'grid',
            });

            if (doc.internal.getNumberOfPages() === 1) {
                isOnePage = true;
                // Generación de etiquetas por producto
                doc.addPage();
                doc.setFontSize(16);
                doc.setFont('helvetica', 'bold');
                doc.text('Etiqueta del Producto', 105, 10, { align: 'center' });
                doc.setFontSize(12);
                doc.setFont('helvetica', 'normal');

                let etiquetaCount = 0;
                Productos.forEach((productoEtiqueta, index) => {
                    if (index > 0 && index % 2 === 0) {
                        doc.addPage();
                        etiquetaCount = 0; // Reiniciar el contador de etiquetas por página
                    }

                    const yOffset = 30 + (etiquetaCount * 100); // Ajusta el valor para espaciar las etiquetas verticalmente

                    const productoActual = productos.find(p => p.id_producto === productoEtiqueta.id_producto);
                    let medidaProducto = null;
                    if (productoActual) {
                        medidaProducto = medidas.find(med => med.id_medida === productoActual.medida);
                    }

                    const categoriaEncontrada = categorias.find(cat => cat.id_categoria === productoActual.categoria);
                    const especieEncontrada = especies.find(esp => esp.id_especie === productoActual.especie);
                    const productoNombre = `${categoriaEncontrada?.nombre} de ${especieEncontrada?.nombre}`;
                    const medidasTexto = medidaProducto ? `${productoActual?.espesor} x ${medidaProducto.ancho || 'RW'} x ${medidaProducto.largo}` : '';
                    const calidadTexto = productoEtiqueta.calidad ? calidades.find(cal => cal.id_calidad === productoEtiqueta.calidad)?.calidad : '';

                    const etiquetaData = [
                        ['PO #', `${infoProveedor.siglas} ${nroOrden ? nroOrden < 10 ? '0' + nroOrden : nroOrden : ''}-${year}`],
                        ['Product', `${productoNombre}`],
                        ['Grade', `${calidadTexto}`],
                        ['Quantity of pcs', `${productoEtiqueta.cantidad || ''}`],
                        ['Size', `${medidasTexto}`],
                        ['Origin', `${ordencompra.origen || ''}`],
                        ['Mill #', 'PTC-']
                    ];

                    // Añadir cabecera con nombre en inglés de la categoría
                    doc.autoTable({
                        startY: yOffset + 10,
                        head: [[categoriaEncontrada?.nombre_ingles || '']],
                        theme: 'grid',
                        headStyles: {
                            halign: 'center', // Centrar el texto en la cabecera
                            fillColor: [255, 255, 255],
                            textColor: [0, 0, 0],
                            fontSize: 12,
                            lineWidth: 0.1,
                            lineColor: [0, 0, 0]
                        },
                        columnStyles: {
                            0: { cellWidth: 190 }
                        }
                });

                // Añadir cuerpo de la etiqueta
                doc.autoTable({
                    startY: doc.lastAutoTable.finalY, // Empezar debajo de la cabecera
                    body: etiquetaData,
                    theme: 'grid',
                    bodyStyles: {
                        fillColor: [255, 255, 255],
                        textColor: [0, 0, 0],
                        lineWidth: 0.1,
                        lineColor: [0, 0, 0]
                    },
                    columnStyles: {
                        0: { cellWidth: 95 }, // Ajustar el ancho de la primera columna
                        1: { cellWidth: 95 }  // Ajustar el ancho de la segunda columna
                    }
                });

                // Añadir cuadro y texto "# Pacote" en la esquina superior derecha
                const lastY = doc.lastAutoTable.finalY; // Obtener la posición Y final de la tabla
                doc.setDrawColor(0);
                doc.setFillColor(255, 255, 255);
                doc.rect(164.2, yOffset + 10, 40, 8.4, 'FD'); // Dibujar el rectángulo con fondo blanco
                doc.text('# Pacote', 165, yOffset + 15);

                    etiquetaCount++;
                });
                doc.save(`${title}.pdf`);
            } else {
                fontSize -= 1;
                imageSize -= 5;
                cellPadding -= 0.2;
                spacing -= 0.5;
            }
        }
    }, [infoProveedor, nroOrden, ordencompra, emision, ptcImage, Productos, medidas, incoterms, puertos, documentos, descripcion_producto, condicion_pago, requisitos_calidad, informacion_adicional, requisitos_etiquetado, observaciones, documentosRequeridos, calidades]);

    useEffect(() => {
        if (infoProveedor && contador === 0) {
            generatePDF();
            setContador(1);
            cerrar();
        }
    }, [infoProveedor, contador, cerrar, generatePDF]);

    return null;
};

export default GenerarPDF;
