import React from 'react';

const Navigation = ({ onToggleMenu, selectedMenu }) => {
    const renderMenuItems = (items) => {
        return items.map((item, index) => {
            if (item.subItems) {
                return (
                    <li key={index} className="nav-item dropdown">
                        <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            id={`navbarDropdown${index}`}
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            {item.label}
                        </a>
                        <ul className="dropdown-menu" aria-labelledby={`navbarDropdown${index}`}>
                            {item.subItems.map((subItem, subIndex) => (
                                <li key={subIndex}>
                                    <a className="dropdown-item" href={subItem.href}>
                                        {subItem.label}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </li>
                );
            }
            return (
                <li key={index} className="nav-item">
                    <a className="nav-link" href={item.href}>{item.label}</a>
                </li>
            );
        });
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container-fluid">
                <button className="navbar-brand border-0" onClick={onToggleMenu} style={{ background: 'none', border: 'none' }}>
                    {/* Add your brand/logo here */}
                </button>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">
                        {renderMenuItems(selectedMenu)}
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navigation;
