import React, { useState } from 'react';

function EditarEspecie({ especie, cerrar, guardar }) {
    const [botonDeshabilitado, setBotonDeshabilitado] = useState(false);
    const [nombre, setNombre] = React.useState(especie.nombre || '');
    const [ingles, setIngles] = React.useState(especie.nombre_ingles || '');
    const [portugues, setPortugues] = React.useState(especie.nombre_portugues || '');
    const [ncm, setNcm] = React.useState(especie.ncm || '')

    const handleSubmit = (event) => {
        event.preventDefault();
        setBotonDeshabilitado(true);

        const editedEspecie = {
            ...especie,
            nombre: nombre,
            nombre_ingles: ingles,
            nombre_portugues: portugues,
            ncm
        };

        guardar(editedEspecie);
        cerrar();
    };

    return (
        <div className='modal-overlay'>
            <div className='modal1'>
                <div className='card'>
                    <form onSubmit={handleSubmit}>
                        <div className='card-header'>
                            <h3 style={{ textAlign: 'center' }}>Editar Especie</h3>
                        </div>
                        <div className='card-body'>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Nombre</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Ingrese nombre especie"
                                    value={nombre}
                                    onChange={(e) => setNombre(e.target.value)}
                                    required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>En inglés</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Ingrese especie"
                                    value={ingles}
                                    onChange={(e) => setIngles(e.target.value)}
                                    required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>En portugués</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Ingrese especie"
                                    value={portugues}
                                    onChange={(e) => setPortugues(e.target.value)}
                                    required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>NCM</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese NCM"
                                  value={ncm}
                                  onChange={(e) => setNcm(e.target.value)}
                                  required
                                />
                            </div>
                        </div>
                        <div className='card-footer' style={{ textAlign: 'center' }}>
                            <button type="submit" className="btn btn-outline-success mb-3 mt-3" disabled={botonDeshabilitado}>Guardar</button>
                            <button type="button" className="btn btn-outline-danger mb-3 mt-3" onClick={cerrar}>Cancelar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default EditarEspecie;