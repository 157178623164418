import React, { useState, useEffect, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { IoTrashOutline } from "react-icons/io5";
import { LuPencilLine } from "react-icons/lu";
import AgregarLineaCredito from './AgregarLineaCredito';
import EditarLineaCredito from './EditarLineaCredito';

const LineasCredito = () => {
    const [dataLineasCredito, setDataLineasCredito] = useState([]);
    const [opciones, setOpciones] = useState({
        clientes: [],
    })
    const [showAgregar, setShowAgregar] = useState(false);
    const [showEditar, setShowEditar] = useState(false);
    const [selected, setSelected] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const firstRender = useRef(true);

    const fetchData = async () => {
        const token = localStorage.getItem('token'); // Obtener token de autenticación
        try {
            const response = await fetch('https://api.dvstarlab.com/lineascredito', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.status === 401) {
                throw new Error('Unauthorized');
            }
            const data = await response.json();
            setDataLineasCredito(data);

            const opcionesResponse = await fetch('https://api.dvstarlab.com/opciones', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (opcionesResponse.status === 401) {
                throw new Error('Unauthorized');
            }
            const opcionesData = await opcionesResponse.json();
            setOpciones(opcionesData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        if (firstRender.current) {
            fetchData();
            firstRender.current = false;
        }
    }, []);

    useEffect(() => {
        const filtered = dataLineasCredito.filter((row) =>
            Object.values(row).some((value) =>
                value.toString().toLowerCase().includes(filterText.toLowerCase())
            )
        );
        setFilteredData(filtered);
    }, [dataLineasCredito, filterText]);

    const columns = [
        {
            name: 'Editar',
            cell: (row) => (
                <button className='btn btn-outline-primary' onClick={() => handleEditar(row)}>
                    <LuPencilLine />
                </button>
            ),
        },
        {
            name: 'Monto',
            selector: row => row.monto,
        },
        {
            name: 'Cliente',
            selector: row => {
                if (row.cliente) {
                    const client = opciones.clientes.find(c => c.id_cliente === row.cliente);
                    return client ? client.nombre : null;
                }
                return null;
            }
        },
        {
            name: 'Eliminar',
            cell: (row) => (
                <button
                    className='btn btn-outline-danger'
                    onClick={() => {
                        if (window.confirm('¿Estas seguro?')) {
                            handleEliminar(row.id_lineacredito);
                        }
                    }}
                >
                    <IoTrashOutline />
                </button>
            ),
            ignoreRowClick: true,
        },
    ];

    const handleAgregar = () => {
        setShowAgregar(true);
    };

    const handleEditar = (lineaCredito) => {
        setSelected(lineaCredito);
        setShowEditar(true);
    };

    const handleSaveEditar = (editedLineaCredito) => {
        const token = localStorage.getItem('token'); // Obtener token de autenticación
        fetch(`https://api.dvstarlab.com/editarlineacredito/${editedLineaCredito.id_lineacredito}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(editedLineaCredito),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Error al actualizar línea de crédito');
            }
            return response.json();
        })
        .then(data => {
            console.log('Línea de crédito actualizada:', data);
            alert('Línea de crédito actualizada exitosamente');
            window.location.reload();
        })
        .catch(error => {
            console.error('Error:', error);
        });
    };

    const handleCloseEditar = () => {
        setSelected(null);
        setShowEditar(false);
    };

    const handleEliminar = (idLineaCredito) => {
        const token = localStorage.getItem('token'); // Obtener token de autenticación
        fetch(`https://api.dvstarlab.com/eliminarlineacredito/${idLineaCredito}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
        .then(response => {
            if (response.ok) {
                alert('Línea de crédito eliminada correctamente.');
                window.location.reload();
            } else {
                alert('Error al intentar eliminar la línea de crédito.');
            }
        })
        .catch(error => {
            console.error('Error de red:', error);
        });
    };

    return (
        <div className='container-fluid'>
            <div className='card'>
                <div className='card-header'>
                    <h3 className='card-title' style={{ textAlign: 'center' }}>Líneas de Crédito</h3>
                </div>
                <div className='card-body mb-2'>
                    <div className="input-group mb-3">
                        <input
                            type="text"
                            placeholder="Buscar..."
                            value={filterText}
                            onChange={(e) => setFilterText(e.target.value)}
                            className='form-control'
                        />
                    </div>
                    <div className="input-group mb-3">
                        <button type="button" className="btn btn-outline-primary" onClick={handleAgregar}>Agregar Línea de Crédito</button>
                    </div>
                    {showAgregar && <AgregarLineaCredito opciones={opciones} cerrar={() => setShowAgregar(false)} />}
                    <div className="card">
                        <div className="card-body">
                            <DataTable
                                columns={columns}
                                data={filteredData}
                                noHeader
                                responsive
                                pagination
                                highlightOnHover
                            />
                        </div>
                    </div>
                    {selected && showEditar && (
                        <EditarLineaCredito
                            lineaCredito={selected}
                            opciones={opciones}
                            cerrar={handleCloseEditar}
                            guardar={handleSaveEditar}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default LineasCredito;
