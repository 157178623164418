import React from 'react';

const EditarIncoTerm = ({incoterm, cerrar, guardar}) => {
    const [botonDeshabilitado, setBotonDeshabilitado] = React.useState(false);
    const [nombre, setNombre] = React.useState(incoterm.nombre || '');
    const [descripcion, setDescripcion] = React.useState(incoterm.descripcion || '');
    const [indicador, setIndicador] = React.useState(incoterm.indicador || null);

    const handleSubmit = (event) => {
        event.preventDefault();
        setBotonDeshabilitado(true);

        const editedIncoTerm = {
            ...incoterm,
            nombre: nombre,
            descripcion: descripcion,
            indicador: indicador,
        };

        guardar(editedIncoTerm);
        cerrar();
    };

    const handleRadioChange = (event) => {
        const selectedValue = parseInt(event.target.value); // Convertir el valor a entero
        setIndicador(selectedValue);
    };

    return(
        <div className='modal-overlay'>
            <div className='modal1'>
                <div className='card'>
                    <form onSubmit={handleSubmit}>
                        <div className='card-header'>
                            <h3 style={{ textAlign: 'center' }}>Editar IncoTerm</h3>
                        </div>
                        <div className='card-body'>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>IncoTerm</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese nombre IncoTerm"
                                  value={nombre}
                                  onChange={(e) => setNombre(e.target.value)}
                                  required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Descripción</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese descripción"
                                  value={descripcion}
                                  onChange={(e) => setDescripcion(e.target.value)}
                                  required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value={1} checked={indicador === 1} onChange={handleRadioChange}/>
                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                        Puerto
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value={2} checked={indicador === 2} onChange={handleRadioChange}/>
                                    <label className="form-check-label" htmlFor="flexRadioDefault2">
                                        ICD
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" value={3} checked={indicador === 3} onChange={handleRadioChange}/>
                                    <label className="form-check-label" htmlFor="flexRadioDefault3">
                                        Door Delivery
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='card-footer' style={{ textAlign: 'center' }}>
                            <button type="submit" className="btn btn-outline-success mb-3 mt-3" disabled={botonDeshabilitado}>Guardar</button>
                            <button type="button" className="btn btn-outline-danger mb-3 mt-3" onClick={cerrar}>Cancelar</button> 
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default EditarIncoTerm