import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';

// Utilidades para formatear fecha
const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = date.getUTCDate().toString().padStart(2, '0');
    return `${day}-${month}-${year}`;
};

const ProductoDescripcion = ({ productos, categorias, especies, medidas, producto }) => {
    if (producto.id_producto) {
        const productoEncontrado = productos.find(p => parseInt(p.id_producto) === parseInt(producto.id_producto));
        if (productoEncontrado) {
            const categoriaEncontrada = categorias.find(categoria => parseInt(categoria.id_categoria) === parseInt(productoEncontrado.categoria));
            const especieEncontrada = especies.find(especie => parseInt(especie.id_especie) === parseInt(productoEncontrado.especie));
            const medidaEncontrada = medidas.find(medida => parseInt(medida.id_medida) === parseInt(productoEncontrado.medida));
            if (categoriaEncontrada && especieEncontrada && medidaEncontrada) {
                return `${categoriaEncontrada.nombre} de ${especieEncontrada.nombre} ${productoEncontrado.espesor} X ${medidaEncontrada.ancho} X ${medidaEncontrada.largo}`;
            }
        }
    }
    return '';
};

const ClienteInfo = ({ cliente }) => (
    <>
        <p>{cliente?.nombre}</p>
        <p>Address: {cliente?.direccion}</p>
        <p>TAX ID: {cliente?.tax}</p>
        <p>Attn: {cliente?.contacto}</p>
    </>
);

const BankInfo = ({ bank_information, beneficiary_bank, mostrarBankInfo, mostrarBeneficiaryBank }) => (
    <>
        {mostrarBankInfo && (
            <>
                <p><strong>Bank Information</strong></p>
                <p>{bank_information}</p>
            </>
        )}
        {mostrarBeneficiaryBank && (
            <>
                <p><strong>Beneficiary Bank</strong></p>
                <p>{beneficiary_bank}</p>
            </>
        )}
    </>
);

const VerProforma = ({ proforma, clientes, incoterms, puertos, productos, documentos, usuarios, categorias, especies, medidas, calidades, proformas, pt, guardar, cerrar }) => {
    const [estado, setEstado] = useState(proforma.estado || 0);
    const [correlativo, setCorrelativo] = useState(proforma.correlativo || 0);
    const [infoCliente, setInfoCliente] = useState(null);
    const [nroProforma, setNroProforma] = useState(0);
    const [mCubicos, setMCubicos] = useState(null);
    const id = localStorage.getItem('ID_Usuario');

    const fecha = proforma.fecha ? formatDate(proforma.fecha) : '';
    const cliente = proforma.cliente || 0;
    const fecha_embarque = proforma.fecha_embarque ? formatDate(proforma.fecha_embarque) : '';

    const paymentTermObj = proforma.payment_terms
        ? pt.find(payment => payment.id_paymentterm === parseInt(proforma.payment_terms))
        : null;

    const paymentTermNombre = paymentTermObj?.nombre || 'N/A';

    const Productos = proforma.productos.map((producto, index) => ({
        id_producto: producto || 0,
        real_size: proforma.real_size[index] || '',
        m3: proforma.m3[index] || 0,
        usd_m3: proforma.usd_m3[index] || 0,
        usd_total: proforma.usd_total[index] || 0
    }));

    const handleSubmit = () => {
        const edited = {
            ...proforma,
            estado,
        };

        guardar(edited);
        cerrar();
    };

    const actualizarCliente = async (clienteActualizado) => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`https://api.dvstarlab.com/editarcliente/${clienteActualizado.id_cliente}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(clienteActualizado),
            });
    
            if (!response.ok) {
                throw new Error('Error actualizando el correlativo del cliente');
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (cliente && !infoCliente) {
            const info = clientes.find(cli => cli.id_cliente === cliente);
            if (info) {
                setInfoCliente(info);
                setCorrelativo(info.correlativo || 0);  // Asigna el correlativo actual del cliente
            }
        }
    }, [cliente, infoCliente, clientes]);

    useEffect(() => {
        if (Productos.length > 0 && !mCubicos) {
            const total_m3 = Productos.reduce((total, producto) => total + parseFloat(producto.m3), 0);
            setMCubicos(total_m3);
        }
    }, [Productos, mCubicos]);

    const handleAprobar = () => {
        if(proforma.estado === 1){
            // Asignar el correlativo del cliente a la proforma
            const correlativoCliente = correlativo;
            setCorrelativo(correlativoCliente); // Actualizamos el correlativo en el frontend para la proforma

            // Actualizamos la proforma con el correlativo del cliente
            const proformaActualizada = {
                ...proforma,
                correlativo: correlativoCliente,
                estado: 2, // Cambia el estado a "Aprobada"
            };

            // Incrementamos el correlativo del cliente para la próxima proforma
            const nuevoCorrelativoCliente = parseInt(correlativoCliente) + 1;
            const clienteActualizado = {
                ...infoCliente,
                correlativo: nuevoCorrelativoCliente
            };

            // Guardamos los cambios en la base de datos
            actualizarCliente(clienteActualizado);
            guardar(proformaActualizada);
            cerrar();
        }else if(proforma.estado === 2){
            setEstado(3)
        }
    };

    const handleRechazar = () => {
        setEstado(4);
    };

    useEffect(() => {
        if (estado !== proforma.estado && estado === 3) {
            const timer = setTimeout(() => {
                handleSubmit({ preventDefault: () => {} });
            }, 100);

            return () => clearTimeout(timer);
        }
    }, [estado, proforma.estado]);

    const columns = [
        {
            name: 'Producto',
            selector: row => <ProductoDescripcion productos={productos} categorias={categorias} especies={especies} medidas={medidas} producto={row} />
        },
        { name: 'Real Size', selector: row => row.real_size, width: '15%' },
        { name: 'm3', selector: row => row.m3, width: '15%' },
        { name: 'USD/m3', selector: row => row.usd_m3, width: '15%' },
        { name: 'Total USD', selector: row => row.usd_total, width: '15%' },
    ];

    return (
        <div className='modal-overlay'>
            <div className='modal1'>
                <div className='card'>
                    <form onSubmit={handleSubmit}>
                        <div className='card-header'>
                            <div className='card-title' style={{ textAlign: 'center' }}>
                            <strong>
                                Proforma Invoice {infoCliente?.siglas} {nroProforma < 10 ? '0' + nroProforma : nroProforma}-
                                {isNaN(new Date(proforma.fecha_embarque).getUTCFullYear()) 
                                    ? '00' 
                                    : new Date(proforma.fecha_embarque).getUTCFullYear().toString().substr(-2)}
                            </strong>
                            </div>
                        </div>
                        <div className='card-body'>
                           <div className='row'>
                                <div className='col'>
                                    <div className='card h-100'>
                                        <div className='card-header'>
                                             <div className='card-title' style={{ textAlign: 'left' }}><strong>Customer</strong></div>
                                        </div>
                                        <div className='card-body'>
                                            <ClienteInfo cliente={infoCliente} />
                                        </div>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='card'>
                                        <div className='card-header'>
                                            <div className='card-title' style={{ textAlign: 'right' }}><strong>{fecha}</strong></div>
                                        </div>
                                        <div className='card-body'>
                                            <p>Shippment Date: {fecha_embarque}</p>
                                            <br />
                                            <br />
                                            <p>
                                                Payment Terms: {paymentTermNombre}
                                                {paymentTermObj?.reglas.includes(4) && (
                                                    <>
                                                        {' / '}
                                                        <strong>Advance:</strong> {proforma.advance || '0'}%
                                                        {' / '}
                                                        <strong>CAD:</strong> {proforma.cad || '0'}%
                                                    </>
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <div className='card h-100'>
                                        <div className='card-body'>
                                            <p>Origin: Brazil</p>
                                            <br />
                                            <br />
                                            <br />
                                            <p>Destination: {infoCliente?.pais}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='card h-100'>
                                        <div className='card-body'>
                                            <p>Port of Origin: {puertos.find(puerto => puerto.id_puerto === proforma.puerto)?.nombre}</p>
                                            <p>Port of Delivery: {puertos.find(puerto => puerto.id_puerto === proforma.puerto)?.nombre}, {puertos.find(puerto => puerto.id_puerto === proforma.puerto)?.pais}</p>
                                            <p>Delivery: port of {puertos.find(puerto => puerto.id_puerto === proforma.puerto)?.nombre}, {puertos.find(puerto => puerto.id_puerto === proforma.puerto)?.pais}</p>
                                            <p>Sales condition: {incoterms.find(inco => inco.id_incoterm === proforma.incoterm)?.nombre} {puertos.find(puerto => puerto.id_puerto === proforma.puerto)?.nombre}, {puertos.find(puerto => puerto.id_puerto === proforma.puerto)?.pais}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <div className='card h-100'></div>
                                </div>
                                <div className='col'>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <p>Packing: Bundles</p>
                                            <p>Packing complies with ISPM-15 standard</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <div className='card h-100'>
                                        <h6 style={{ textAlign: 'center' }}><strong>PRODUCT DESCRIPTION</strong></h6>
                                        <p style={{ textAlign: 'center' }}>{proforma.descripcion_producto}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <div className='card h-100'>
                                        <DataTable
                                            columns={columns}
                                            data={Productos}
                                            highlightOnHover
                                            dense
                                        />
                                        <hr />
                                        <p style={{ textAlign: 'center' }}>Total m3 {mCubicos || ''} - USD {proforma.valor_proforma}</p>
                                    </div>
                                </div>
                            </div>
                            {/* Mostrar campos condicionales según el payment_terms */}
                            {(paymentTermObj?.reglas.includes(1) || paymentTermObj?.reglas.includes(2)) && (
                                <div className='row'>
                                    <div className='col'>
                                        <div className='card h-100'>
                                            <div className='card-body'>
                                                <BankInfo 
                                                    bank_information={proforma.bank_information} 
                                                    beneficiary_bank={proforma.beneficiary_bank} 
                                                    mostrarBankInfo={paymentTermObj?.reglas.includes(2)} 
                                                    mostrarBeneficiaryBank={paymentTermObj?.reglas.includes(1)} 
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {paymentTermObj?.reglas.includes(3) && (
                                <div className='row'>
                                    <div className='col'>
                                        <div className='card h-100'>
                                            <div className='card-body'>
                                                <p><strong>Especificaciones Línea de Crédito</strong></p>
                                                <p>{proforma.especificaciones_lc}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className='row'>
                                <div className='col'>
                                    <div className='card h-100'>
                                        <div className='card-body'>
                                            {proforma.quality_standards && (
                                                <div>
                                                    <p><strong>Quality Standards</strong></p>
                                                    <p>{proforma.quality_standards}</p>
                                                </div>
                                            )}
                                            {proforma.observaciones_proforma && (
                                                <div>
                                                    <p><strong>Obs:</strong></p>
                                                    <p>{proforma.observaciones_proforma}</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <div className='card'>
                                        <div className='card-body'>
                                            {proforma.documentos.length > 0 && (
                                                <div>
                                                    <h6>Documentos Requeridos</h6>
                                                    {proforma.documentos.map((doc, index) => (
                                                        <span key={index}>
                                                            {documentos.find(docu => docu.id_documento === doc)?.nombre}
                                                            {index !== proforma.documentos.length - 1 && " / "}
                                                        </span>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col'></div>
                                </div>
                            </div>
                        </div>
                        <div className='card-footer' style={{ textAlign: 'center' }}>
                            {parseInt(id) === parseInt(proforma.aprobador) && (
                                <>
                                    {proforma.estado === 1 ? (
                                        <div className='mb-3'>
                                            <button type="button" className="btn btn-outline-primary" onClick={handleAprobar}>Aprobar</button>
                                            <button type="button" className="btn btn-outline-danger" onClick={handleRechazar}>Rechazar</button>
                                        </div>
                                    ) : proforma.estado === 2 ? (
                                        <div className='mb-3'>
                                            <button type="button" className="btn btn-outline-primary" onClick={handleAprobar}>Aprobado x Cliente</button>
                                            <button type="button" className="btn btn-outline-danger" onClick={handleRechazar}>Rechazado x Cliente</button>
                                        </div>
                                    ) : null}
                                </>
                            )}
                            <button type="button" className="btn btn-outline-danger" onClick={cerrar}>Cerrar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default VerProforma;
