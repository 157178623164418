import React, { useState } from 'react';

const EditarDocumento = ({ documento, cerrar, guardar }) => {
    const [botonDeshabilitado, setBotonDeshabilitado] = useState(false);
    const [nombre, setNombre] = useState(documento.nombre);

    const handleSubmit = (event) => {
        event.preventDefault();
        setBotonDeshabilitado(true);

        const editedDocumento = {
            ...documento,
            nombre,
        };

        guardar(editedDocumento);
        cerrar();
    };

    return (
        <div className='modal-overlay'>
            <div className='modal1'>
                <div className='card'>
                    <form onSubmit={handleSubmit}>
                        <div className='card-header'>
                            <h3 style={{ textAlign: 'center' }}>Editar Documento</h3>
                        </div>
                        <div className='card-body'>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Nombre</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Ingrese nombre del documento"
                                    value={nombre}
                                    onChange={(e) => setNombre(e.target.value)}
                                    required
                                />
                            </div>
                        </div>
                        <div className='card-footer' style={{ textAlign: 'center' }}>
                            <button type="submit" className="btn btn-outline-success mb-3 mt-3" disabled={botonDeshabilitado}>Guardar</button>
                            <button type="button" className="btn btn-outline-danger mb-3 mt-3" onClick={cerrar}>Cancelar</button> 
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditarDocumento;
