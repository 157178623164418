import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';


const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = date.getUTCDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
};

const EditarOrdenCompra = ({ordencompra, proformas, proveedores, incoterms, puertos, productos, documentos, usuarios, categorias, especies, medidas, calidades, clientes, almacenes, guardar, cerrar}) => {
    const [botonDeshabilitado, setBotonDeshabilitado] = useState(false);
    const [emision, setEmision] = useState(ordencompra.emision ? formatDate(ordencompra.emision) : '');
    const [proforma, setProforma] = useState(ordencompra.proforma||0);
    const [proveedor, setProveedor] = useState(ordencompra.proveedor||0)
    const [entrega, setEntrega] = useState(ordencompra.entrega ? formatDate(ordencompra.entrega) : '');
    const [almacen, setAlmacen] = useState(ordencompra.almacen||0);
    const [condicion_pago, setCondicionPago] = useState(ordencompra.condicion_pago||'');
    const [requisitos_calidad, setRequisitosCalidad] = useState(ordencompra.requisitos_calidad||'')
    const [requisitos_etiquetado, setRequisitosEtiquetado] = useState(ordencompra.requisitos_etiquetado||'');
    const [incoterm, setIncoTerm] = useState(ordencompra.incoterm||0);
    const [puerto, setPuerto] = useState(ordencompra.puerto||0);
    const [direccion_destino, setDireccionDestino] = useState(ordencompra.direccion_destino||'');
    const [descripcion_producto, setDescripcionProducto] = useState(ordencompra.descripcion_producto||'');
    const [Productos, setProductos] = useState(
        ordencompra.productos.map((producto, index) => ({
            id_producto: producto || 0,
            real_size: ordencompra.real_size[index] || '',
            m3: ordencompra.m3[index] || 0,
            usd_m3: ordencompra.usd_m3[index] || 0,
            usd_total: ordencompra.usd_total[index] || 0,
            calidad: ordencompra.calidades[index] || 0,
        }))
    );
    const [valor_ordencompra, setValorOrdenCompra] = useState(ordencompra.valor_ordencompra||0);
    const [documentosRequeridos, SetDocumentosRequeridos] = useState(ordencompra.documentos||[]);
    const [estado, setEstado] = useState(ordencompra.estado||0);
    const [remitente, setRemitente] = useState(ordencompra.remitente||0);
    const [aprobador, setAprobador] = useState(ordencompra.aprobador||0);
    const [observaciones, setObservaciones] = useState(ordencompra.observaciones||'');
    const [nuevoProducto, setNuevoProducto] = useState(0);
    const [nuevoDocumento, setNuevoDocumento] = useState(0);
    const [calidad, setCalidad] = useState('');
    const [calidadesFiltradas, setCalidadesFiltradas] = useState([]);
    const [informacion_adicional, setInformacionAdicional] = useState(ordencompra.informacion_adicional||'')
    const [productosRequeridos, setProductosRequeridos] = useState([]);
    const [indicadorIT, setIndicadorIT] = useState(null)
    const [origen, setOrigen] = useState(ordencompra.origen || 'Brazil');
    const [allowance, setAllowance] = useState(ordencompra.allowance || 0);
    const [moneda, setMoneda] = useState(ordencompra.moneda || 0);
    const [editMode, setEditMode] = useState(false);
    const [productoEnEdicion, setProductoEnEdicion] = useState(null);
    const [correlativo, setCorrelativo] = useState(ordencompra.correlativo || 0);
    const [empresaEmisor, setEmpresaEmisor] = useState(ordencompra.empresa_emisor || '');

    console.log("OC => ")
    console.log(ordencompra)
    useEffect(() => {
      if (incoterm) {
          seleccionIT(incoterm);
      }
    }, [incoterm]);

    const getCurrencySymbol = (moneda) => {
      switch(moneda) {
          case 0: return 'USD';
          case 1: return 'EUR';
          case 2: return 'CLP';
          case 3: return 'BRL';
          case 4: return 'PYG';
          default: return 'USD';
      }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setBotonDeshabilitado(true);

        const arrayProductos = [];
        const arrayRealSize = [];
        const arrayM3 = [];
        const arrayUSDM3 = [];
        const arrayUSDTotal = [];
        const arrayCalidad = [];

        Productos.forEach(producto => {
            arrayProductos.push(producto.id_producto)
            arrayRealSize.push(producto.real_size)
            arrayM3.push(producto.m3)
            arrayUSDM3.push(producto.usd_m3)
            arrayUSDTotal.push(producto.usd_total)
            arrayCalidad.push(producto.calidad);
        })

        const arrayDocumentos = []

        documentosRequeridos.forEach(doc => {
            arrayDocumentos.push(doc)
        })

        const edited = {
            id_ordencompra: ordencompra.id_ordencompra,
            proveedor,
            proforma,
            almacen,
            emision,
            entrega,
            incoterm,
            puerto,
            productos: arrayProductos,
            real_size: arrayRealSize,
            m3: arrayM3,
            usd_m3: arrayUSDM3,
            usd_total: arrayUSDTotal,
            documentos: arrayDocumentos,
            valor_ordencompra,
            condicion_pago,
            requisitos_calidad,
            informacion_adicional,
            requisitos_etiquetado,
            estado: 1,
            remitente,
            aprobador,
            observaciones,
            descripcion_producto,
            calidades: arrayCalidad,
            origen,
            allowance,
            moneda,
            correlativo,
            empresa_emisor: parseInt(empresaEmisor),
        }

        console.log(edited)

        guardar(edited);
        cerrar();
    }

    const columnsProductos = [
        {
            name: 'Producto',
            selector: row => {
                if (row.id_producto) {
                    const productoEncontrado = productos.find(prod => parseInt(prod.id_producto) === parseInt(row.id_producto));
                    console.log(productoEncontrado)
                    if (productoEncontrado){
                        const categoriaEncontrada = categorias.find(categoria => parseInt(categoria.id_categoria) === parseInt(productoEncontrado.categoria))
                        const especieEncontrada = especies.find(especie => parseInt(especie.id_especie) === parseInt(productoEncontrado.especie))
                        const medidaEncontrada = medidas.find(medida => parseInt(medida.id_medida) === parseInt(productoEncontrado.medida))
                        return categoriaEncontrada?.nombre + " de " + especieEncontrada?.nombre + " " + medidaEncontrada?.espesor + " X " + medidaEncontrada?.ancho + " X " + medidaEncontrada?.largo
                    }
                }
            },
        },
        {
          name: 'Calidad',
          selector: row => {
            if (row.calidad) {
              const calidadEncontrada = calidades.find(cal => parseInt(cal.id_calidad) === parseInt(row.calidad));
              if (calidadEncontrada) {
                return calidadEncontrada.calidad;
              }
            }else{
              return row.calidad
            }
          },
          width: '15%',
        },
        {
          name: 'Real Size',
          selector: row => row.real_size,
          width: '15%',
        },
        {
          name: 'm3',
          selector: row => row.m3,
          width: '15%',
        },
        {
          name: `${getCurrencySymbol(moneda)}/m3`,
          selector: row => row.usd_m3,
          width: '15%',
        },
        {
          name: `Total ${getCurrencySymbol(moneda)}`,
          selector: row => row.usd_total,
          width: '15%',
        },
        {
          name: 'Editar',
          cell: row => (
              <button 
                  type="button" 
                  className="btn btn-outline-primary"
                  onClick={() => handleEditProducto(row)}
              >
                  Editar
              </button>
          ),
          ignoreRowClick: true,
          width: '100px',
        },
        {
          name: 'Eliminar',
          cell: row => (
            <div className="delete-button-container">
              <button
                type="button"
                className="btn btn-outline-danger delete-button"
                onClick={() => {
                  if (window.confirm('¿Estás seguro?')) {
                    handleDeleteProducto(row.id_producto, row.calidad);
                  }
                }}
              >
                X
              </button>
            </div>
          ),
          ignoreRowClick: true,
          width: '100px',
        },
      ];
    
      const columnsDocumentos = [
        {
            name: 'Documentos',
            selector: row => {
                if (row) {
                    const docEncontrado = documentos.find(d => parseInt(d.id_documento) === parseInt(row));
                    if (docEncontrado){
                        return docEncontrado.nombre
                    }
                }
            },
        },
        {
            name: 'Eliminar',
            cell: row => (
                <div className="delete-button-container">
                    <button 
                        type="button"
                        className="btn btn-outline-danger delete-button"
                        onClick={() => {
                            if(window.confirm('¿Estás seguro?')) {
                                handleDeleteDocumento(row)
                            }
                        }}
                    >
                        X
                    </button>
                </div>
            ),
            ignoreRowClick: true,
            width: '100px',
        },
    ];

    const handleEditProducto = (producto) => {
      setNuevoProducto(producto.id_producto);  // Selecciona el producto
      setCalidad(producto.calidad);  // Selecciona la calidad
      document.getElementById('m3').value = producto.m3;  // Rellenar m3
      document.getElementById('usd_m3').value = producto.usd_m3;  // Rellenar usd_m3
      setEditMode(true);  // Activamos el modo de edición
      setProductoEnEdicion(producto);  // Guardamos el producto en edición
    };

    const handleDeleteProducto = (id, calidad) => {
        const updatedProductos = Productos.filter((producto) => 
          producto.id_producto !== id && producto.calidad !== calidad
        );
        setProductos(updatedProductos);
        if(updatedProductos.length === 0){
            setDescripcionProducto('')
        }
    };

    const handleAgregarProducto = () => {
      if (nuevoProducto && calidad) {
        const m3 = parseFloat(document.getElementById('m3').value);
        const usd_m3 = parseFloat(document.getElementById('usd_m3').value);
        if (editMode && productoEnEdicion) {
            // Editar producto existente
            const updatedProductos = Productos.map(prod => {
                if (prod.id_producto === productoEnEdicion.id_producto && prod.calidad === productoEnEdicion.calidad) {
                    return {
                        ...prod,
                        m3: m3,
                        usd_m3: usd_m3,
                        usd_total: m3 * usd_m3,
                    };
                }
                return prod;
            });
            setProductos(updatedProductos);
            setEditMode(false);  // Salimos del modo edición
        } else {
          const productoSeleccionado = productos.find(p => parseInt(p.id_producto) === parseInt(nuevoProducto));
          if (productoSeleccionado) {
            const m3 = parseFloat(document.getElementById('m3').value);
            const usd_m3 = parseFloat(document.getElementById('usd_m3').value);
          
            if (!m3 || !calidad || !usd_m3) {
              alert('Por favor ingrese todos los detalles requeridos.');
              return;
            }
          
            const categoriaSeleccionada = categorias.find(categoria => parseInt(categoria.id_categoria) === parseInt(productoSeleccionado.categoria));
            const especieSeleccionada = especies.find(especie => parseInt(especie.id_especie) === parseInt(productoSeleccionado.especie));
            const medidaEncontrada = medidas.find(m => m.id_medida === productoSeleccionado.medida);

            if (categoriaSeleccionada && especieSeleccionada && medidaEncontrada) {
              const indiceEspesor = medidaEncontrada.espesor.findIndex(e => e === productoSeleccionado.espesor);
              const espesorrCorrespondiente = medidaEncontrada.espesorr[indiceEspesor];
            
              const nuevo = {
                id_producto: parseInt(nuevoProducto),
                real_size: `${espesorrCorrespondiente} x ${medidaEncontrada.anchor} x ${medidaEncontrada.largor}`,
                m3,
                usd_m3,
                usd_total: m3 * usd_m3,
                calidad: parseInt(calidad),
              };
            
              const nuevosProductos = [...Productos, nuevo];
              setProductos(nuevosProductos);
              setDescripcionProducto(`${categoriaSeleccionada.nombre_portugues} de ${especieSeleccionada.nombre_portugues}`);
              setNuevoProducto(null);
              setCalidad('');
              document.getElementById('m3').value = '';
              document.getElementById('usd_m3').value = '';
            } else {
              alert("Categoría, especie o medida no encontrada.");
            }
          }
        }
      } else {
        alert("Producto o medida no seleccionada.");
      }
    };
  
    useEffect(() => {
        const totalUSD = Productos.reduce((acc, producto) => acc + parseFloat(producto.usd_total || 0), 0);
        setValorOrdenCompra(totalUSD);
    }, [Productos]);

    const handleDeleteDocumento = (id) => {
        const updatedDocumentos = documentosRequeridos.filter((doc) => doc !== id);
        SetDocumentosRequeridos(updatedDocumentos);
    };

    const handleAgregarDocumento = () => {
        if (nuevoDocumento) {
            const documentoSeleccionado = documentos.find(d => parseInt(d.id_documento) === parseInt(nuevoDocumento));
            if (documentoSeleccionado) {
                const nuevo = documentoSeleccionado.id_documento
                SetDocumentosRequeridos([...documentosRequeridos, nuevo]);
                setNuevoDocumento(null);
            } else {
                alert("No se puede agregar");
            }
        }
    }

    const handleSelectChange = (e) => {
        const value = e.target.value;
        setProforma(value);
    };

    const actualizarCalidades = (id) => {
        const producto = productos.find(prod => prod.id_producto === id);
        if (producto && producto.calidades) {
          const calidadesAsociadas = producto.calidades.map(id =>
            calidades.find(cal => cal.id_calidad === id)
          ).filter(calidad => calidad);
          setCalidadesFiltradas(calidadesAsociadas);
        } else {
          setCalidadesFiltradas([]);
        }
    };

    const seleccionIT = (incoterm) =>{
      if(incoterm !== 0){
          const itSeleccionado = incoterms.find(i => i.id_incoterm === parseInt(incoterm))
          if(itSeleccionado){
              if(itSeleccionado.indicador === 1){
                  setIncoTerm(itSeleccionado.id_incoterm)
                  setIndicadorIT(1);
              }else if(itSeleccionado.indicador === 2){
                  setIncoTerm(itSeleccionado.id_incoterm)
                  setIndicadorIT(2);
              }else if(itSeleccionado.indicador === 3){
                  setIncoTerm(itSeleccionado.id_incoterm)
                  setIndicadorIT(3);
              }else{
                  console.log("Error con el indicador")
              }
          }else{
              console.log("No se encontro el incoterm")
          }
      }
    }

    return(
        <div className='modal-overlay'>
            <div className='modal1'>
                <div className='card'>
                    <form onSubmit={handleSubmit}>
                        <div className='card-header'>
                            <div className='card-title' style={{ textAlign: 'center' }}>Editar Orden de compra</div>
                        </div>
                        <div className='card-body'>
                            <div className="input-group mb-3">
                                <span className='input-group-text'>Moneda</span>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="moneda"
                                        id="usd"
                                        value={0}
                                        checked={moneda === 0}
                                        onChange={(e) => setMoneda(parseInt(e.target.value))}
                                    />
                                    <label className="form-check-label" htmlFor="usd">USD</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="moneda"
                                        id="eur"
                                        value={1}
                                        checked={moneda === 1}
                                        onChange={(e) => setMoneda(parseInt(e.target.value))}
                                    />
                                    <label className="form-check-label" htmlFor="eur">EUR</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="moneda"
                                        id="clp"
                                        value={2}
                                        checked={moneda === 2}
                                        onChange={(e) => setMoneda(parseInt(e.target.value))}
                                    />
                                    <label className="form-check-label" htmlFor="clp">CLP</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="moneda"
                                        id="brl"
                                        value={3}
                                        checked={moneda === 3}
                                        onChange={(e) => setMoneda(parseInt(e.target.value))}
                                    />
                                    <label className="form-check-label" htmlFor="brl">BRL</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="moneda"
                                        id="pyg"
                                        value={4}
                                        checked={moneda === 4}
                                        onChange={(e) => setMoneda(parseInt(e.target.value))}
                                    />
                                    <label className="form-check-label" htmlFor="pyg">PYG</label>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                              <span className='input-group-text'>Empresa Emisor</span>
                              <div className="form-check form-check-inline">
                                  <input
                                      className="form-check-input"
                                      type="radio"
                                      name="empresaEmisor"
                                      id="ptc"
                                      value={0}
                                      checked={empresaEmisor === 0}
                                      onChange={(e) => setEmpresaEmisor(parseInt(e.target.value))}
                                  />
                                  <label className="form-check-label" htmlFor="ptc">PTC</label>
                              </div>
                              <div className="form-check form-check-inline">
                                  <input
                                      className="form-check-input"
                                      type="radio"
                                      name="empresaEmisor"
                                      id="ptb"
                                      value={1}
                                      checked={empresaEmisor === 1}
                                      onChange={(e) => setEmpresaEmisor(parseInt(e.target.value))}
                                  />
                                  <label className="form-check-label" htmlFor="ptb">PTB</label>
                              </div>
                            </div>
                            <div className="input-group mb-3">
                                <span className="input-group-text">Proforma</span>
                                <select
                                    className="form-select"
                                    value={proforma}
                                    onChange={(e) => handleSelectChange(e)}
                                    disabled={proforma !== 0}  // Deshabilitar si ya hay una proforma seleccionada
                                >
                                    <option value="">Seleccione una Proforma</option>
                                    <option value={0}>Proforma por crear</option>
                                    {proformas.map((Pi) => {
                                        const clienteExistente = clientes.find(cli => cli.id_cliente === Pi.cliente);
                                        if (clienteExistente) {
                                            return (
                                                <option key={Pi.id_proforma} value={Pi.id_proforma}>
                                                    {Pi.id_proforma + ' - ' + clienteExistente?.nombre}
                                                </option>
                                            );
                                        }
                                        return null;
                                    })}
                                </select>
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Fecha de Emisión</span>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={emision}
                                    onChange={(e) => setEmision(e.target.value)}
                                />
                            </div>
                            <div className="input-group mb-3">
                              <span className='input-group-text'>Proveedor</span>
                              <select
                                className="form-select"
                                value={proveedor}
                                onChange={(e) => setProveedor(e.target.value)}
                                disabled
                              >
                                <option value="">Seleccione un proveedor</option>
                                {proveedores.map((pro) => (
                                  <option key={pro.id_proveedor} value={pro.id_proveedor}>
                                    {pro.nombre}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Fecha Embarque</span>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={entrega}
                                    onChange={(e) => setEntrega(e.target.value)}
                                />
                            </div>
                            <div className='input-group mb-3'>
                              <span className='input-group-text'>Origen</span>
                              <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese Origen"
                                  value={origen}
                                  onChange={(e) => setOrigen(e.target.value)}
                                  required
                              />
                            </div>
                            <div className="input-group mb-3">
                                <span className="input-group-text">IncoTerm</span>
                                <select
                                className="form-select"
                                value={incoterm}
                                onChange={(e) => seleccionIT(e.target.value)}
                                >
                                <option value="">Seleccione un IncoTerm</option>
                                {incoterms.map((it) => (
                                    <option key={it.id_incoterm} value={it.id_incoterm}>
                                    {it.nombre}
                                    </option>
                                ))}
                                </select>
                            </div>
                            {indicadorIT === 2 &&
                              <div className="input-group mb-3">
                                <span className="input-group-text">Almacén</span>
                                <select
                                  className="form-select"
                                  value={almacen}
                                  onChange={(e) => setAlmacen(e.target.value)}
                                >
                                  <option value="">Seleccione un almacén</option>
                                  {almacenes.map((alm) => (
                                    <option key={alm.id_almacen} value={alm.id_almacen}>
                                      {alm.nombre}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            }
                            {indicadorIT === 1 && 
                              <div className="input-group mb-3">
                                <span className="input-group-text">Puerto</span>
                                <select
                                  className="form-select"
                                  value={puerto}
                                  onChange={(e) => setPuerto(e.target.value)}
                                >
                                  <option value="">Seleccione un Puerto de Destino</option>
                                  {puertos.map((puerto) => (
                                    <option key={puerto.id_puerto} value={puerto.id_puerto}>
                                      {puerto.nombre}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            }
                            <div className="input-group mb-3">
                                <span className="input-group-text">Descripción Producto</span>
                                <input
                                type="text"
                                className="form-control"
                                value={descripcion_producto}
                                readOnly
                                />
                            </div>
                            <div className='mb-3'>
                                <h5 style={{ textAlign: 'center' }}>Productos</h5>
                                <div className='input-group mb-3'>
                                    <span className='input-group-text'>
                                        Producto
                                    </span>
                                    <select
                                        value={nuevoProducto}
                                        onChange={e => {
                                            setNuevoProducto(e.target.value);
                                            const producto = productos.find(prod => parseInt(prod.id_producto) === parseInt(e.target.value));
                                            if (producto) {
                                                actualizarCalidades(producto.id_producto);
                                            }
                                        }}
                                        className='form-control'
                                    >
                                        <option value="">Seleccionar Producto</option>
                                        {productos?.map((producto) => {
                                            const productoExistente = Productos.some(p => parseInt(p.id_producto) === parseInt(producto.id_producto));
                                            const proveedorSeleccionado = proveedores.find(prov => parseInt(prov.id_proveedor) === parseInt(proveedor))
                                            const productoEnProveedor = proveedorSeleccionado?.productos.find(prod => prod === producto.id_producto)
                                            console.log(productoExistente)
                                            console.log(proveedorSeleccionado)
                                            console.log(productoEnProveedor)
                                            if (productoEnProveedor) {
                                                const categoriaProducto = categorias.find(categoria => categoria.id_categoria === producto.categoria);
                                                const especieProducto = especies.find(especie => especie.id_especie === producto.especie);
                                                const medidaProducto = medidas.find(medida => medida.id_medida === producto.medida);
                                                console.log(categoriaProducto)
                                                console.log(especieProducto)
                                                console.log(medidaProducto)
                                                if (categoriaProducto && especieProducto && medidaProducto) {
                                                    if (!descripcion_producto || `${categoriaProducto.nombre_portugues} de ${especieProducto.nombre_portugues}` === descripcion_producto) {
                                                        return (
                                                            <option key={producto.id_producto} value={producto.id_producto}>
                                                                {`${categoriaProducto.nombre} de ${especieProducto.nombre} ${producto.espesor} X ${medidaProducto.ancho} X ${medidaProducto.largo}`}
                                                            </option>
                                                        );
                                                    }else{
                                                      return (
                                                        <option key={producto.id_producto} value={producto.id_producto}>
                                                            {`${categoriaProducto.nombre} de ${especieProducto.nombre} ${producto.espesor} X ${medidaProducto.ancho} X ${medidaProducto.largo}`}
                                                        </option>
                                                      );
                                                    }
                                                }
                                            }
                                            return null;
                                        })}
                                    </select>
                                </div>
                                {!editMode && nuevoProducto &&(
                                  <>
                                  <div className="input-group mb-3">
                                    <span className="input-group-text">Calidad</span>
                                    <select
                                      id="calidad"
                                      className="form-select"
                                      value={calidad}
                                      onChange={(e) => setCalidad(e.target.value)}
                                    >
                                      <option value="">Seleccione una calidad</option>
                                      {calidadesFiltradas.map((cal) => (
                                        <option key={cal.id_calidad} value={cal.id_calidad}>
                                          {cal.calidad}
                                        </option>
                                      ))}
                                    </select>
                                    <span className="input-group-text">m3</span>
                                    <input type="number" id="m3" className="form-control" />
                                    <span className="input-group-text">{getCurrencySymbol(moneda)}/m3</span>
                                    <input type="number" id="usd_m3" className="form-control" />
                                  </div>
                                  </>
                                )}
                                {editMode &&(
                                  <>
                                    <div className="input-group mb-3">
                                      <span className="input-group-text">m3</span>
                                      <input type="number" id="m3" className="form-control" />
                                      <span className="input-group-text">{getCurrencySymbol(moneda)}/m3</span>
                                      <input type="number" id="usd_m3" className="form-control" />
                                    </div>
                                  </>
                                )}
                                <button 
                                    type="button" 
                                    onClick={handleAgregarProducto} 
                                    className="btn btn-outline-primary" 
                                    disabled={!nuevoProducto || !calidad}
                                >
                                    {editMode ? 'Editar Producto' : 'Agregar Producto'}
                                </button>
                                <DataTable
                                  columns={columnsProductos}
                                  data={Productos}
                                  highlightOnHover
                                  dense
                                  pagination={true}
                                />
                            </div>
                            <div className="input-group mb-3">
                              <span className="input-group-text">Valor Orden de Compra (${getCurrencySymbol(moneda)})</span>
                              <input
                                type="text"
                                className="form-control"
                                value={valor_ordencompra}
                                readOnly
                              />
                            </div>
                            <div className="input-group mb-3">
                              <span className="input-group-text" style={{ width: '170px' }}>Allowance</span>
                              <textarea
                                type="number"
                                className="form-control"
                                placeholder="Ingrese condiciones de pago"
                                value={allowance}
                                onChange={(e) => setAllowance(e.target.value)}
                                required
                              />
                            </div>
                            <div className="input-group mb-3">
                              <span className="input-group-text" style={{ width: '170px' }}>Condición de Pago</span>
                              <textarea
                                type="text"
                                className="form-control"
                                placeholder="Ingrese condiciones de pago"
                                value={condicion_pago}
                                onChange={(e) => setCondicionPago(e.target.value)}
                                required
                              />
                            </div>
                            <div className="input-group mb-3">
                              <span className="input-group-text" style={{ width: '170px' }}>Requisitos Calidad</span>
                              <textarea
                                type="text"
                                className="form-control"
                                placeholder="Ingrese Requisitos de calidad"
                                value={requisitos_calidad}
                                onChange={(e) => setRequisitosCalidad(e.target.value)}
                                required
                              />
                            </div>
                            <div className="input-group mb-3">
                              <span className="input-group-text" style={{ width: '170px' }}>Información Adicional</span>
                              <textarea
                                type="text"
                                className="form-control"
                                placeholder="Ingrese Requisitos de etiquetado"
                                value={informacion_adicional}
                                onChange={(e) => setInformacionAdicional(e.target.value)}
                                required
                              />
                            </div>
                            <div className="input-group mb-3">
                              <span className="input-group-text" style={{ width: '170px' }}>Requisitos Etiquetado</span>
                              <textarea
                                type="text"
                                className="form-control"
                                placeholder="Ingrese Requisitos de etiquetado"
                                value={requisitos_etiquetado}
                                onChange={(e) => setRequisitosEtiquetado(e.target.value)}
                                required
                              />
                            </div>
                            <div className="mb-3">
                              <h5 style={{ textAlign: 'center' }}>Documentos Requeridos</h5>
                              <div className="input-group mb-3">
                                <span className="input-group-text">Documento</span>
                                <select
                                  value={nuevoDocumento}
                                  onChange={e => setNuevoDocumento(e.target.value)}
                                  className="form-control"
                                >
                                  <option value="">Seleccionar Documento</option>
                                  {documentos.map((td) => {
                                    const tdExistente = documentosRequeridos.some(dr => parseInt(dr) === parseInt(td.id_documento));
                                    if (!tdExistente) {
                                      return (
                                        <option key={td.id_documento} value={td.id_documento}>
                                          {td.nombre}
                                        </option>
                                      );
                                    }
                                    return null;
                                  })}
                                </select>
                                <button type="button" onClick={handleAgregarDocumento} className="btn btn-outline-primary" disabled={!nuevoDocumento}>
                                  Agregar Documento
                                </button>
                              </div>
                              <DataTable
                                columns={columnsDocumentos}
                                data={documentosRequeridos}
                                highlightOnHover
                                dense
                                pagination={true}
                              />
                            </div>
                            <div className="input-group mb-3">
                              <span className="input-group-text">Remitente</span>
                              <select
                                className="form-select"
                                value={remitente}
                                onChange={(e) => setRemitente(e.target.value)}
                              >
                                <option value="">Seleccione un Remitente</option>
                                {usuarios.map((user) => (
                                  <option key={user.id_usuario} value={user.id_usuario}>
                                    {user.nombre}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="input-group mb-3">
                              <span className="input-group-text">Aprobador</span>
                              <select
                                className="form-select"
                                value={aprobador}
                                onChange={(e) => setAprobador(e.target.value)}
                              >
                                <option value="">Seleccione un Aprobador</option>
                                {usuarios
                                  .filter(user => user.rol === 1)
                                  .map(user => (
                                    <option key={user.id_usuario} value={user.id_usuario}>
                                      {user.nombre}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div className="input-group mb-3">
                              <span className="input-group-text">Observaciones</span>
                              <textarea
                                type="text"
                                className="form-control"
                                value={observaciones}
                                onChange={(e) => setObservaciones(e.target.value)}
                              />
                            </div>
                        </div>
                        <div className='card-footer' style={{ textAlign: 'center' }}>
                            <button 
                              type="submit" 
                              className="btn btn-outline-success mb-3 mt-3"
                              disabled={botonDeshabilitado}
                            >
                                Guardar
                            </button>
                            <button type="button" className="btn btn-outline-danger" onClick={cerrar}>Cancelar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default EditarOrdenCompra