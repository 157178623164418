import React, { useState } from 'react';

const AgregarDocumento = ({ cerrar }) => {
    const [botonDeshabilitado, setBotonDeshabilitado] = useState(false);
    const [nombre, setNombre] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        setBotonDeshabilitado(true);

        const nuevoDocumento = {
            nombre,
        };

        const token = localStorage.getItem('token'); // Obtener token de autenticación
        fetch('https://api.dvstarlab.com/agregardocumento', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(nuevoDocumento),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Error al agregar documento');
            }
            return response.json();
        })
        .then(data => {
            console.log('Documento agregado:', data);
            alert('Documento ' + nombre + ' agregado exitosamente')
            window.location.reload();
        })
        .catch(error => {
            console.error('Error:', error);
        })
        .finally(() => {
            setBotonDeshabilitado(false);
        });
    };

    return (
        <div className='modal-overlay'>
            <div className='modal1'>
                <div className='card'>
                    <form onSubmit={handleSubmit}>
                        <div className='card-header'>
                            <h3 className='card-title' style={{ textAlign: 'center' }}>Agregar Documento</h3>
                        </div>
                        <div className='card-body'>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Nombre</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Ingrese nombre del documento"
                                    value={nombre}
                                    onChange={(e) => setNombre(e.target.value)}
                                    required
                                />
                            </div>
                        </div>
                        <div className='card-footer' style={{ textAlign: 'center' }}>
                            <button
                                type="submit"
                                className="btn btn-outline-success mb-3 mt-3"
                                disabled={botonDeshabilitado}
                            >
                                Agregar
                            </button>
                            <button type="button" className="btn btn-outline-danger" onClick={cerrar}>Cancelar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AgregarDocumento;
