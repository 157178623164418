// frontend/src/vistas/calidades/EditarCalidad.js
import React, { useState } from 'react';

const EditarCalidad = ({ calidad, cerrar, guardar }) => {
    const [Calidad, setCalidad] = useState(calidad.calidad);

    const handleSubmit = (event) => {
        event.preventDefault();
        const editedCalidad = {
            ...calidad,
            calidad: Calidad,
        };
        guardar(editedCalidad);
        cerrar();
    };

    return (
        <div className='modal-overlay'>
            <div className='modal1'>
                <div className='card'>
                    <form onSubmit={handleSubmit}>
                        <div className='card-header'>
                            <h3 className='card-title' style={{ textAlign: 'center' }}>Editar Calidad</h3>
                        </div>
                        <div className='card-body'>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Calidad</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese nombre del almacen"
                                  value={Calidad}
                                  onChange={(e) => setCalidad(e.target.value)}
                                  required
                                />
                            </div>
                        </div>
                        <div className='card-footer' style={{ textAlign: 'center' }}>
                            <button 
                                type="submit" 
                                className="btn btn-outline-success mb-3 mt-3"
                            >
                                Guardar
                            </button>
                            <button type="button" className="btn btn-outline-danger" onClick={cerrar}>Cancelar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditarCalidad;

