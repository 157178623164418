// frontend/src/vistas/almacenes/AgregarAlmacen.js
import React, { useState } from 'react';

const AgregarAlmacen = ({ cerrar }) => {
    const [nombre, setNombre] = useState('');
    const [direccion, setDireccion] = useState('');
    const [email, setEmail] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        const nuevoAlmacen = {
            nombre,
            direccion,
            email
        };

        const token = localStorage.getItem('token'); // Obtener token de autenticación

        fetch('https://api.dvstarlab.com/addstorage', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(nuevoAlmacen)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Error al agregar almacen');
            }
            return response.json();
        })
        .then(data => {
            alert('Almacen agregado exitosamente');
            window.location.reload();
        })
        .catch(error => {
            console.error('Error:', error);
        });
    };

    return (
        <div className='modal-overlay'>
            <div className='modal1'>
                <div className='card'>
                    <form onSubmit={handleSubmit}>
                        <div className='card-header'>
                            <h3 className='card-title' style={{ textAlign: 'center' }}>Agregar Almacen</h3>
                        </div>
                        <div className='card-body'>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Nombre</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese nombre del almacen"
                                  value={nombre}
                                  onChange={(e) => setNombre(e.target.value)}
                                  required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Dirección</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese dirección del almacen"
                                  value={direccion}
                                  onChange={(e) => setDireccion(e.target.value)}
                                  required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Email</span>
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="Ingrese email del almacen"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  required
                                />
                            </div>
                        </div>
                        <div className='card-footer' style={{ textAlign: 'center' }}>
                            <button 
                                type="submit" 
                                className="btn btn-outline-success mb-3 mt-3"
                            >
                                Agregar
                            </button>
                            <button type="button" className="btn btn-outline-danger" onClick={cerrar}>Cancelar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AgregarAlmacen;
