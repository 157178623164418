import React, { useState, useEffect, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { IoTrashOutline } from "react-icons/io5";
import { LuPencilLine } from "react-icons/lu";
import AgregarCartaCredito from './AgregarCartaCredito';
import EditarCartaCredito from './EditarCartaCredito';

const CartasCredito = () => {
    const [dataCartasCredito, setDataCartasCredito] = useState([]);
    const firstRender = useRef(true);
    const [showAgregar, setShowAgregar] = useState(false);
    const [showEditar, setShowEditar] = useState(false);
    const [selected, setSelected] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    const fetchData = async () => {
        const token = localStorage.getItem('token'); // Obtener token de autenticación
        try {
            const response = await fetch('https://api.dvstarlab.com/cartascredito', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.status === 401) {
                throw new Error('Unauthorized');
            }
            const data = await response.json();
            setDataCartasCredito(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        if (firstRender.current) {
            fetchData();
            firstRender.current = false;
        }
    }, []);

    useEffect(() => {
        const filtered = dataCartasCredito.filter((row) =>
            Object.values(row).some((value) =>
                value.toString().toLowerCase().includes(filterText.toLowerCase())
            )
        );
        setFilteredData(filtered);
    }, [dataCartasCredito, filterText]);

    const columns = [
        {
            name: 'Editar',
            cell: (row) => (
                <button className='btn btn-outline-primary' onClick={() => handleEditar(row)}>
                    <LuPencilLine />
                </button>
            ),
        },
        {
            name: 'Proforma',
            selector: row => row.proforma,
        },
        {
            name: 'Código',
            selector: row => row.codigo,
        },
        {
            name: 'Emisión',
            selector: row => row.emision,
        },
        {
            name: 'Expiración',
            selector: row => row.expiracion,
        },
        {
            name: 'Último Embarque',
            selector: row => row.ultimo_embarque,
        },
        {
            name: 'Tolerancia',
            selector: row => row.tolerancia,
        },
        {
            name: 'Valor',
            selector: row => row.valor,
        },
        {
            name: 'Eliminar',
            cell: (row) => (
                <button
                    className='btn btn-outline-danger'
                    onClick={() => {
                        if (window.confirm('¿Estas seguro?')) {
                            handleEliminar(row.id_cartacredito);
                        }
                    }}
                >
                    <IoTrashOutline />
                </button>
            ),
            ignoreRowClick: true,
        },
    ];

    const handleAgregar = () => {
        setShowAgregar(true);
    };

    const handleEditar = (cartaCredito) => {
        setSelected(cartaCredito);
        setShowEditar(true);
    };

    const handleSaveEditar = (editedCartaCredito) => {
        const token = localStorage.getItem('token'); // Obtener token de autenticación
        fetch(`https://api.dvstarlab.com/editarcartacredito/${editedCartaCredito.id_cartacredito}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(editedCartaCredito),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Error al actualizar carta de crédito');
            }
            return response.json();
        })
        .then(data => {
            console.log('Carta de crédito actualizada:', data);
            alert('Carta de crédito actualizada exitosamente');
            window.location.reload();
        })
        .catch(error => {
            console.error('Error:', error);
        });
    };

    const handleCloseEditar = () => {
        setSelected(null);
        setShowEditar(false);
    };

    const handleEliminar = (idCartaCreditoAEliminar) => {
        const token = localStorage.getItem('token'); // Obtener token de autenticación
        fetch(`https://api.dvstarlab.com/eliminarcartacredito/${idCartaCreditoAEliminar}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
        .then(response => {
            if (response.ok) {
                alert('Carta de crédito eliminada correctamente.');
                window.location.reload();
            } else {
                alert('Error al intentar eliminar la carta de crédito.');
            }
        })
        .catch(error => {
            console.error('Error de red:', error);
        });
    };

    return (
        <div className='container-fluid'>
            <div className='card'>
                <div className='card-header'>
                    <h3 className='card-title' style={{ textAlign: 'center' }}>Cartas de Crédito</h3>
                </div>
                <div className='card-body mb-2'>
                    <div className="input-group mb-3">
                        <input
                            type="text"
                            placeholder="Buscar..."
                            value={filterText}
                            onChange={(e) => setFilterText(e.target.value)}
                            className='form-control'
                        />
                    </div>
                    <div className="input-group mb-3">
                        <button type="button" className="btn btn-outline-primary" onClick={handleAgregar}>Agregar Carta de Crédito</button>
                    </div>
                    {showAgregar && <AgregarCartaCredito cerrar={() => setShowAgregar(false)} />}
                    <div className="card">
                        <div className="card-body">
                            <DataTable
                                columns={columns}
                                data={filteredData}
                                noHeader
                                responsive
                                pagination
                                highlightOnHover
                            />
                        </div>
                    </div>
                    {selected && showEditar && (
                        <EditarCartaCredito
                            cartaCredito={selected}
                            cerrar={handleCloseEditar}
                            guardar={handleSaveEditar}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default CartasCredito;
