import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = date.getUTCDate().toString().padStart(2, '0');
    return `${day}-${month}-${year}`;
};

const VerOrdenCompra = ({ ordencompra, proveedores, incoterms, puertos, productos, documentos, usuarios, categorias, especies, medidas, calidades, guardar, cerrar }) => {
    // Estado que se puede modificar
    const [estado, setEstado] = useState(ordencompra.estado || 0);
    const [correlativo, setCorrelativo] = useState(ordencompra.correlativo || 0)
    const [infoProveedor, setInfoProveedor] = useState(null);

    useEffect(() => {
        if (estado !== ordencompra.estado && estado === 3) {
            const timer = setTimeout(() => {
                handleSubmit({ preventDefault: () => {} });
            }, 100);

            return () => clearTimeout(timer);
        }
    }, [estado, ordencompra.estado]);

    // Guardar cambios de estado
    const handleSubmit = () => {
        const edited = { ...ordencompra, estado };
        guardar(edited);
        cerrar();
    };

    const columns = [
        {
            name: 'Producto',
            selector: row => {
                const productoEncontrado = productos.find(prod => parseInt(prod.id_producto) === parseInt(row.id_producto));
                if (productoEncontrado) {
                    const categoriaEncontrada = categorias.find(categoria => parseInt(categoria.id_categoria) === parseInt(productoEncontrado.categoria));
                    const especieEncontrada = especies.find(especie => parseInt(especie.id_especie) === parseInt(productoEncontrado.especie));
                    const medidaEncontrada = medidas.find(medida => parseInt(medida.id_medida) === parseInt(productoEncontrado.medida));
                    if (categoriaEncontrada && especieEncontrada && medidaEncontrada) {
                        return `${categoriaEncontrada.nombre} de ${especieEncontrada.nombre} ${productoEncontrado.espesor} X ${medidaEncontrada.ancho} X ${medidaEncontrada.largo}`;
                    }
                }
                return 'N/A';
            },
        },
        { name: 'Real Size', selector: row => row.real_size, width: '15%' },
        {
            name: 'Calidad',
            selector: row => {
                const calidadEncontrada = calidades.find(cal => parseInt(cal.id_calidad) === parseInt(row.calidad));
                return calidadEncontrada?.calidad || 'N/A';
            },
            width: '10%',
        },
        { name: 'm3', selector: row => row.m3, width: '15%' },
        { name: 'USD/m3', selector: row => row.usd_m3, width: '15%' },
        { name: 'Valor', selector: row => row.usd_total, width: '15%' },
    ];

    const actualizarProveedor = async (proveedorActualizado) => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`https://api.dvstarlab.com/editarproveedor/${proveedorActualizado.id_proveedor}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(proveedorActualizado),
            });
    
            if (!response.ok) {
                throw new Error('Error actualizando el correlativo del proveedor');
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if(ordencompra.proveedor && !infoProveedor){
            const info = proveedores.find(pro => pro.id_proveedor === ordencompra.proveedor)
            if(info){
                setInfoProveedor(info)
                setCorrelativo(info.correlativo || 0);
                console.log("correlativo ->" + info.correlativo)
            }
        }
    }, [proveedores, infoProveedor])

    const handleAprobar = () => {
        if(ordencompra.estado === 1){
            const ordenCompraActualizada = {
                ...ordencompra,
                estado: 2,
            };
            guardar(ordenCompraActualizada);
            cerrar();
        }else if(ordencompra.estado === 2){
            setEstado(3);
        }
    }

    const handleRechazar = () => {
        setEstado(4);
    };

    return (
        <div className='modal-overlay'>
            <div className='modal1'>
                <div className='card'>
                    <form onSubmit={handleSubmit}>
                        <div className='card-header'>
                            <div className='card-title' style={{ textAlign: 'center' }}>
                                <strong>Ver Orden de Compra</strong>
                            </div>
                        </div>
                        <div className='card-body'>
                            {/* Primera fila: Información del proveedor y detalles de la orden */}
                            <div className='row'>
                                <div className='col'>
                                    <div className='card h-100'>
                                        <div className='card-header'>
                                            <div className='card-title' style={{ textAlign: 'left' }}><strong>Proveedor</strong></div>
                                        </div>
                                        <div className='card-body'>
                                            <p>{proveedores.find(p => p.id_proveedor === ordencompra.proveedor)?.nombre || 'N/A'}</p>
                                            <p>Address: {proveedores.find(p => p.id_proveedor === ordencompra.proveedor)?.direccion || 'N/A'}</p>
                                            <p>Attn: {proveedores.find(p => p.id_proveedor === ordencompra.proveedor)?.contacto || 'N/A'}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='card'>
                                        <div className='card-header'>
                                            <div className='card-title' style={{ textAlign: 'right' }}><strong>Data: {formatDate(ordencompra.emision)}</strong></div>
                                        </div>
                                        <div className='card-body'>
                                            <p>Sales condition: {incoterms.find(inco => inco.id_incoterm === ordencompra.incoterm)?.nombre || 'N/A'} {puertos.find(puerto => puerto.id_puerto === ordencompra.puerto)?.nombre || 'N/A'}, {puertos.find(puerto => puerto.id_puerto === ordencompra.puerto)?.pais || 'N/A'}</p>
                                            <p>Shipment Date: {formatDate(ordencompra.entrega)}</p>
                                            <p>Payment Terms: {ordencompra.condicion_pago}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Segunda fila: Información del puerto y origen */}
                            <div className='row'>
                                <div className='col'>
                                    <div className='card h-100'>
                                        <div className='card-body'>
                                            <p>Origin: Brazil</p>
                                            <p>Destination: {proveedores.find(p => p.id_proveedor === ordencompra.proveedor)?.pais || 'N/A'}</p>
                                            <p><strong>Label: Attached label format</strong></p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='card h-100'>
                                        <div className='card-body'>
                                            <p>Port of Origin: {puertos.find(puerto => puerto.id_puerto === ordencompra.puerto)?.nombre || 'N/A'}, {puertos.find(puerto => puerto.id_puerto === ordencompra.puerto)?.pais || 'N/A'}</p>
                                            <p>Port of Delivery: {puertos.find(puerto => puerto.id_puerto === ordencompra.puerto)?.nombre || 'N/A'}, {puertos.find(puerto => puerto.id_puerto === ordencompra.puerto)?.pais || 'N/A'}</p>
                                            <p>Delivery: port of {puertos.find(puerto => puerto.id_puerto === ordencompra.puerto)?.nombre || 'N/A'}, {puertos.find(puerto => puerto.id_puerto === ordencompra.puerto)?.pais || 'N/A'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Tercera fila: Descripción del producto */}
                            <div className='row'>
                                <div className='col'>
                                    <div className='card h-100'>
                                        <h6 style={{ textAlign: 'center' }}><strong>Descripción del Producto</strong></h6>
                                        <p style={{ textAlign: 'center' }}>{ordencompra.descripcion_producto || 'N/A'}</p>
                                    </div>
                                </div>
                            </div>
                            {/* Cuarta fila: Productos */}
                            <div className='row'>
                                <div className='col'>
                                    <div className='card h-100'>
                                        <DataTable 
                                            columns={columns}
                                            data={ordencompra.productos.map((producto, index) => ({
                                                id_producto: producto || 0,
                                                real_size: ordencompra.real_size[index] || '',
                                                m3: ordencompra.m3[index] || 0,
                                                usd_m3: ordencompra.usd_m3[index] || 0,
                                                usd_total: ordencompra.usd_total[index] || 0,
                                                calidad: ordencompra.calidades[index] || 0,
                                            }))}
                                            highlightOnHover
                                            dense  
                                        />
                                        <hr />
                                        <p style={{ textAlign: 'center' }}>Total m3: {ordencompra.productos.reduce((total, p, index) => total + ordencompra.m3[index], 0)} - USD {ordencompra.valor_ordencompra}</p>
                                    </div>
                                </div>
                            </div>
                            {/* Quinta fila: Requisitos de calidad, etiquetado e información adicional */}
                            <div className='row'>
                                <div className='col'>
                                    <div className='card h-100'>
                                        <div className='card-body'>
                                            <p><strong>Requisitos de Calidad</strong></p>
                                            <p>{ordencompra.requisitos_calidad || 'N/A'}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='card h-100'>
                                        <div className='card-body'>
                                            <p><strong>Requisitos de Etiquetado</strong></p>
                                            <p>{ordencompra.requisitos_etiquetado || 'N/A'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Sexta fila: Información adicional y observaciones */}
                            <div className='row'>
                                <div className='col'>
                                    <div className='card h-100'>
                                        <div className='card-body'>
                                            <p><strong>Información Adicional</strong></p>
                                            <p>{ordencompra.informacion_adicional || 'N/A'}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='card h-100'>
                                        <div className='card-body'>
                                            <p><strong>Observaciones</strong></p>
                                            <p>{ordencompra.observaciones || 'N/A'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Séptima fila: Documentos Requeridos */}
                            <div className='row'>
                                <div className='col'>
                                    <div className='card'>
                                        <div className='card-body'>
                                            {ordencompra.documentos.length > 0 && (
                                                <div>
                                                    <h6>Documentos Requeridos</h6>
                                                    {ordencompra.documentos.map((doc, index) => (
                                                        <span key={index}>
                                                            {documentos.find(docu => docu.id_documento === doc)?.nombre || 'N/A'}
                                                            {index !== ordencompra.documentos.length - 1 && " / "}
                                                        </span>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Footer: Botones de acción */}
                        <div className='card-footer' style={{ textAlign: 'center' }}>
                            {parseInt(localStorage.getItem('ID_Usuario')) === parseInt(ordencompra.aprobador) && (
                                <>
                                    {ordencompra.estado === 1 ? (
                                        <div className='mb-3'>
                                            <button type="button" className="btn btn-outline-primary" onClick={handleAprobar}>Aprobar</button>
                                            <button type="button" className="btn btn-outline-danger" onClick={handleRechazar}>Rechazar</button>
                                        </div>
                                    ) : ordencompra.estado === 2 ? (
                                        <div className='mb-3'>
                                            <button type="button" className="btn btn-outline-primary" onClick={handleAprobar}>Aprobado x Proveedor</button>
                                            <button type="button" className="btn btn-outline-danger" onClick={handleRechazar}>Rechazado x Proveedor</button>
                                        </div>
                                    ) : null}
                                    
                                </>
                            )}
                            <button type="submit" className="btn btn-outline-success mb-3 mt-3">Guardar</button>
                            <button type="button" className="btn btn-outline-danger" onClick={cerrar}>Cerrar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default VerOrdenCompra;