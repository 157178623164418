import React, { useState } from "react";
import ptcImage from '../imagenes/ptc.jpg';

function Login({ onLogin }) {
    const [correo, setCorreo] = useState('');
    const [password, setPassword] = useState('');

    function handleLogin() {
        const userData = {
            correo: correo,
            password: password
        };

        fetch('https://api.dvstarlab.com/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData),
        })
        .then(response => {
            if (!response.ok) {
                return response.json().then(err => {
                    throw new Error(err.error || 'Error al iniciar sesión');
                });
            }
            return response.json();
        })
        .then(data => {
            console.log(data);
            localStorage.setItem('token', data.token); // Guardar token de autenticación
            localStorage.setItem('Nombre', data.Nombre);
            localStorage.setItem('Rol', data.Rol);
            localStorage.setItem('ID_Usuario', data.ID_Usuario);
            localStorage.setItem('loggedIn', true);
            alert('Bienvenido!');
            window.location.href = '/home';
        })
        .catch(error => {
            if (error.message === 'Credenciales inválidas') {
                alert('Credenciales inválidas. Por favor, verifica tu correo y contraseña.');
            } else {
                alert('Ocurrió un problema al iniciar sesión. Inténtalo de nuevo más tarde.');
            }
            console.error('Error:', error);
        });
    }

    return (
        <div>
            <div className="modal2" style={{ width: '500px' }}>
                <img 
                    src={ptcImage} 
                    alt="PTC" 
                    style={{ 
                        display: 'block', 
                        marginLeft: 'auto', 
                        marginRight: 'auto', 
                        width: '100%', // ocupa todo el ancho del contenedor
                        maxWidth: '200px', // opcional: define un tamaño máximo para la imagen
                        height: 'auto' // mantiene la proporción de la imagen
                    }} 
                />
                <h5 style={{ textAlign: 'center' }}><strong>Bienvenido al sistema de gestión de Palm Trade.</strong></h5>
                <div className="card">
                    <div className="card-header">
                        <h2 className="mt-4" style={{ textAlign: 'center' }}>Iniciar sesión</h2>
                    </div>
                    <div className="card-body">
                        <div className="input-group mb-3">
                            <label htmlFor="usuario" className="input-group-text">
                                Correo
                            </label>
                            <input
                                type="email"
                                className="form-control"
                                value={correo}
                                onChange={e => setCorreo(e.target.value)}
                            />
                        </div>

                        <div className="input-group mb-3">
                            <label htmlFor="password" className="input-group-text">
                                Contraseña
                            </label>
                            <input
                                type="password"
                                className="form-control"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='card-footer' style={{ textAlign: 'center' }}>
                        <button onClick={handleLogin} className="btn btn-outline-primary mt-3 mb-3">
                            Iniciar sesión
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
