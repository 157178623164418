import React, { useState } from 'react';

const AgregarPaymentTerm = ({}) => {
    const [nombre, setNombre] = useState('');
    const [reglas, setReglas] = useState([]);
    const [indicador1, setIndicador1] = useState(false);
    const [indicador2, setIndicador2] = useState(false);
    const [indicador3, setIndicador3] = useState(false);
    const [indicador4, setIndicador4] = useState(false);

    const handleSwitchChange = (event, indicador) => {
        const checked = event.target.checked;
        let nuevasReglas = [...reglas];
        
        if (checked) {
            nuevasReglas.push(indicador);
        } else {
            nuevasReglas = nuevasReglas.filter(regla => regla !== indicador);
        }
        
        setReglas(nuevasReglas);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const nuevoPT = {
            nombre,
            reglas,
        };

        const token = localStorage.getItem('token');

        fetch('https://api.dvstarlab.com/agregarpaymentterm', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(nuevoPT)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Error al agregar payment term');
            }
            return response.json();
        })
        .then(data => {
            alert('Payment term agregado exitosamente');
            window.location.reload();
        })
        .catch(error => {
            console.error('Error:', error);
        });
    };

    return (
        <div className='modal-overlay'>
            <div className='modal1'>
                <div className='card'>
                    <form onSubmit={handleSubmit}>
                        <div className='card-header'>
                            <h3 className='card-title' style={{ textAlign: 'center' }}>Agregar Payment Term</h3>
                        </div>
                        <div className='card-body'>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Nombre</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Ingrese nombre del payment term"
                                    value={nombre}
                                    onChange={(e) => setNombre(e.target.value)}
                                    required
                                />
                            </div>
                            <div className='input-group mb-3 form-check form-switch'>
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" onChange={(e) => handleSwitchChange(e, 1)} />
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault" style={{ marginLeft: '10px' }}>Beneficiary Bank</label>
                            </div>
                            <div className='input-group mb-3 form-check form-switch'>
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault2" onChange={(e) => handleSwitchChange(e, 2)} />
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault2" style={{ marginLeft: '10px' }}>Bank Information</label>
                            </div>
                            <div className='input-group mb-3 form-check form-switch'>
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault3" onChange={(e) => handleSwitchChange(e, 3)} />
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault3" style={{ marginLeft: '10px' }}>LC Especifications</label>
                            </div>
                            <div className='input-group mb-3 form-check form-switch'>
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault4" onChange={(e) => handleSwitchChange(e, 4)} />
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault4" style={{ marginLeft: '10px' }}>Advance y CAD</label>
                            </div>
                        </div>
                        <div className='card-footer' style={{ textAlign: 'center' }}>
                            <button 
                                type="submit" 
                                className="btn btn-outline-success mb-3 mt-3"
                            >
                                Agregar
                            </button>
                            <button type="button" className="btn btn-outline-danger" onClick={() => window.location.reload()}>Cancelar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AgregarPaymentTerm;
