import React, { useState } from 'react';

const AgregarLineaCredito = ({ opciones, cerrar }) => {
    const [botonDeshabilitado, setBotonDeshabilitado] = useState(false);
    const [monto, setMonto] = useState('');
    const [cliente, setCliente] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        setBotonDeshabilitado(true);

        const nuevaLineaCredito = {
            monto,
            cliente,
        };

        const token = localStorage.getItem('token');

        fetch('https://api.dvstarlab.com/agregarlineacredito', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(nuevaLineaCredito),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Error al agregar línea de crédito');
            }
            return response.json();
        })
        .then(data => {
            console.log('Línea de crédito agregada:', data);
            alert('Línea de crédito agregada exitosamente');
            window.location.reload();
        })
        .catch(error => {
            console.error('Error:', error);
        })
        .finally(() => {
            setBotonDeshabilitado(false);
        });
    };

    return (
        <div className='modal-overlay'>
            <div className='modal1'>
                <div className='card'>
                    <form onSubmit={handleSubmit}>
                        <div className='card-header'>
                            <h3 className='card-title' style={{ textAlign: 'center' }}>Agregar Línea de Crédito</h3>
                        </div>
                        <div className='card-body'>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Monto</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese Monto"
                                  value={monto}
                                  onChange={(e) => setMonto(e.target.value)}
                                  required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Cliente</span>
                                <select 
                                    className="form-control"
                                    value={cliente}
                                    onChange={(e) => setCliente(e.target.value)}
                                    required
                                >
                                    <option value="">Seleccionar Cliente</option>
                                    {opciones.clientes.map(cliente => (
                                        <option key={cliente.id_clientes} value={cliente.id_cliente}>
                                            {cliente.nombre}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='card-footer' style={{ textAlign: 'center' }}>
                            <button type="submit" className="btn btn-outline-success mb-3 mt-3" disabled={botonDeshabilitado}>Guardar</button>
                            <button type="button" className="btn btn-outline-danger mb-3 mt-3" onClick={cerrar}>Cancelar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AgregarLineaCredito;
