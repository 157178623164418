import React, { useState, useEffect, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { IoTrashOutline } from "react-icons/io5";
import { LuPencilLine } from "react-icons/lu";
import { FaFilePdf } from "react-icons/fa6";
import { FaEye } from "react-icons/fa";
import AgregarOrdenCompra from './AgregarOrdenCompra';
import EditarOrdenCompra from './EditarOrdenCompra';
import VerOrdenCompra from './VerOrdenCompra';
import GenerarPDF from './GenerarPDF';
import { Spinner } from 'react-bootstrap';

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = date.getUTCDate().toString().padStart(2, '0');
    return `${day}-${month}-${year}`;
};

const OrdenesCompra = () => {
    const [loading, setLoading] = useState(true);
    const [dataOrdenesCompra, setDataOrdenesCompra] = useState([]);
    const [dataProformas, setDataProformas] = useState([]);
    const [dataProveedores, setDataProveedores] = useState([]);
    const [dataAlmacenes, setDataAlmacenes] = useState([]);
    const [dataIncoTerms, setDataIncoTerms] = useState([]);
    const [dataProductos, setDataProductos] = useState([]);
    const [dataCategorias, setDataCategorias] = useState([]);
    const [dataMedidas, setDataMedidas] = useState([]);
    const [dataCalidades, setDataCalidades] = useState([]);
    const [dataEspecies, setDataEspecies] = useState([]);
    const [dataDocumentos, setDataDocumentos] = useState([]);
    const [dataPuertos, setDataPuertos] = useState([]);
    const [dataUsuarios, setDataUsuarios] = useState([]);
    const [dataClientes, setDataClientes] = useState([]);
    const firstRender = useRef(true);
    const [showAgregar, setShowAgregar] = useState(false);
    const [showEditar, setShowEditar] = useState(false);
    const [showVer, setShowVer] = useState(false);
    const [selected, setSelected] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [showPDF, setShowPDF] = useState(false)

    const fetchData = async () => {
        const token = localStorage.getItem('token');
        try{
            const responseOrdenesCompra = await fetch('https://api.dvstarlab.com/ordenescompra', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (responseOrdenesCompra.status === 401) {
                throw new Error('Unauthorized');
            }
            const dataOrdenesCompra = await responseOrdenesCompra.json();
            setDataOrdenesCompra(dataOrdenesCompra);

            const responseProformas = await fetch('https://api.dvstarlab.com/proformas', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (responseProformas.status === 401) {
                throw new Error('Unauthorized');
            }
            const dataProformas = await responseProformas.json();
            setDataProformas(dataProformas);

            const responseProveedores = await fetch('https://api.dvstarlab.com/proveedores', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (responseProveedores.status === 401) {
                throw new Error('Unauthorized');
            }
            const dataProveedores = await responseProveedores.json();
            setDataProveedores(dataProveedores);

            const responseAlmacenes = await fetch('https://api.dvstarlab.com/almacenes', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (responseAlmacenes.status === 401) {
                throw new Error('Unauthorized');
            }
            const dataAlmacenes = await responseAlmacenes.json();
            setDataAlmacenes(dataAlmacenes);

            const responseincoterms = await fetch('https://api.dvstarlab.com/incoterms', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (responseincoterms.status === 401) {
                throw new Error('Unauthorized');
            }
            const dataincoterms = await responseincoterms.json();
            setDataIncoTerms(dataincoterms);

            const responseProductos = await fetch('https://api.dvstarlab.com/productos', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (responseProductos.status === 401) {
                throw new Error('Unauthorized');
            }
            const dataProductos = await responseProductos.json();
            setDataProductos(dataProductos);

            const responseCategorias = await fetch('https://api.dvstarlab.com/categorias', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (responseCategorias.status === 401) {
                throw new Error('Unauthorized');
            }
            const dataCategorias = await responseCategorias.json();
            setDataCategorias(dataCategorias);

            const responseMedidas = await fetch('https://api.dvstarlab.com/medidas', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (responseMedidas.status === 401) {
                throw new Error('Unauthorized');
            }
            const dataMedidas = await responseMedidas.json();
            setDataMedidas(dataMedidas);

            const responseCalidades = await fetch('https://api.dvstarlab.com/calidades', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (responseCalidades.status === 401) {
                throw new Error('Unauthorized');
            }
            const dataCalidades = await responseCalidades.json();
            setDataCalidades(dataCalidades);

            const responseEspecies = await fetch('https://api.dvstarlab.com/especies', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (responseEspecies.status === 401) {
                throw new Error('Unauthorized');
            }
            const dataEspecies = await responseEspecies.json();
            setDataEspecies(dataEspecies);

            const responseDocumentos = await fetch('https://api.dvstarlab.com/documentos', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (responseDocumentos.status === 401) {
                throw new Error('Unauthorized');
            }
            const dataDocumentos = await responseDocumentos.json();
            setDataDocumentos(dataDocumentos);

            const responsePuertos = await fetch('https://api.dvstarlab.com/puertos', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (responsePuertos.status === 401) {
                throw new Error('Unauthorized');
            }
            const dataPuertos = await responsePuertos.json();
            setDataPuertos(dataPuertos);

            const responseUsuarios = await fetch('https://api.dvstarlab.com/usuarios', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (responseUsuarios.status === 401) {
                throw new Error('Unauthorized');
            }
            const dataUsuarios = await responseUsuarios.json();
            setDataUsuarios(dataUsuarios);

            const responseClientes = await fetch('https://api.dvstarlab.com/clientes', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (responseClientes.status === 401) {
                throw new Error('Unauthorized');
            }
            const dataClientes = await responseClientes.json();
            setDataClientes(dataClientes);

            setLoading(false);
            alert("Datos recibidos")

        }catch(error){
            console.error('Error fetching data:', error);
            setLoading(false);
            alert("Error recibiendo los datos.")
        }
    }

    useEffect(() => {
        if(firstRender.current){
            fetchData();
            firstRender.current = false;
        }
    }, []);

    useEffect(() => {
        const sortedData = dataOrdenesCompra.slice().sort((a, b) => a.idordencompra - b.id_ordencompra);
        const filtered = sortedData.filter((row) =>
            Object.values(row).some((value) =>
                value?.toString().toLowerCase().includes(filterText.toLowerCase())
            )
        );
        setFilteredData(filtered);
    }, [dataOrdenesCompra, filterText]);

    const columns = [
        {
            name: 'Editar',
            cell: (row) => <button className='btn btn-outline-primary' onClick={() => handleEditar(row)}><LuPencilLine /></button>,
        },
        {
            name: 'Nro OC',
            selector: row => row.correlativo,
            width: '80px',
        },
        {
            name: 'Proveedor',
            selector: row => {
                let proveedor = row.proveedor ? dataProveedores.find(pro => pro.id_proveedor === row.proveedor) : null;
                return proveedor ? proveedor.nombre : "undefined"
            },
            width: '300px',
        },
        {
            name: 'Emisión',
            selector: row => formatDate(row.emision),
        },
        {
            name: 'Entrega',
            selector: row => formatDate(row.entrega),
        },
        {
            name: 'Valor',
            selector: row => row.valor_ordencompra ? parseFloat(row.valor_ordencompra).toFixed(2) : 'Error',
        },
        {
            name: 'Moneda',
            selector: row => row.moneda === 0 ? "USD" : row.moneda === 1 ? "EUR" : row.moneda === 2 ? "CLP" : row.moneda === 3 ? "BRL" : row.moneda === 4 ? "PYG" : "Indefinido",
        },
        {
            name: 'Estado',
            selector: row => row.estado === 1 ? "Por aprobar" : row.estado === 2 ? "Aprobada" : row.estado === 3 ? "Rechazada" : "Indefinido",
        },
        {
            name: 'Acciones',
            cell: row => (
                <div>
                    <button className='btn btn-outline-primary mr-2' onClick={() => handleVerOC(row)}>
                        <FaEye />
                    </button>
                    <button className='btn btn-outline-primary mr-2' onClick={() => handleGenerarPDF(row)}>
                        <FaFilePdf />
                    </button>
                    <button
                        className='btn btn-outline-danger'
                        onClick={() => {
                            if(window.confirm('¿Estás seguro?')){
                                handleEliminar(row['id_ordencompra']);
                            }
                        }}
                    >
                        <IoTrashOutline />
                    </button>
                </div>
            ),
            ignoreRowClick: true,
        }
    ]

    const handleAgregar = () => {
        setShowAgregar(true);
    };

    const handleEditar = (oc) => {
        setSelected(oc);
        setShowEditar(true);
    };

    const handleSaveEditar = (edited) => {
        const token = localStorage.getItem('token');
        fetch(`https://api.dvstarlab.com/editarordencompra/${edited.id_ordencompra}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(edited),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Error al actualizar datos');
            }
            return response.json();
        })
        .then(data => {
            console.log('Datos actualizados:', data);
            alert('Datos actualizados exitosamente');
            window.location.reload();
        })
        .catch(error => {
            console.error('Error:', error);
        });
    };

    const handleCloseEditar = () => {
        setSelected(null);
        setShowEditar(false)
    };

    const handleEliminar = (idAEliminar) => {
        const token = localStorage.getItem('token'); // Obtener token de autenticación
        fetch(`https://api.dvstarlab.com/eliminarordencompra/${idAEliminar}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            if(response.ok){
                alert('Datos Eliminados Correctamente.');
                window.location.reload();
            }else{
                alert('Error al intentar eliminar datos.')
            }
        })
        .catch(error => {
            console.error('Error de red:', error);
        });
    }

    const handleVerOC = (ordencompra) => {
        setSelected(ordencompra);
        setShowVer(true);
    }
    const handleCloseVerOC = () => {
        setSelected(null);
        setShowVer(false)
    }

    const handleGenerarPDF = (ordencompra) => {
        setSelected(ordencompra);
        setShowPDF(true);
    }

    return(
        <div className='container-fluid'>
            <div className='card'>
                <div className='card-header'>
                    <h3 className='card-title' style={{textAlign: 'center'}}>Ordenes de compra</h3>
                </div>
                <div className='card-body mb-2'>
                {loading ? (
                    <>
                    <div className="text-center">
                        <Spinner animation="border" role="status" />
                    </div>
                    <div className='text-center'>
                        <span className="sr-only">Cargando...</span>
                    </div>
                    </>
                ) : (
                    <>
                    <div className="input-group mb-3">
                        <input
                        type="text"
                        placeholder="Buscar..."
                        value={filterText}
                        onChange={(e) => setFilterText(e.target.value)}
                        className='form-control'
                        />
                    </div>
                    <div className="input-group mb-3">
                        <button type="button" className="btn btn-outline-primary"onClick={() => handleAgregar()}>Agregar Orden de Compra</button>
                    </div>
                    {showAgregar && 
                        <AgregarOrdenCompra proformas={dataProformas} proveedores={dataProveedores} incoterms={dataIncoTerms} puertos={dataPuertos} productos={dataProductos} documentos={dataDocumentos} usuarios={dataUsuarios} categorias={dataCategorias} especies={dataEspecies} medidas={dataMedidas} calidades={dataCalidades} clientes={dataClientes} almacenes={dataAlmacenes} ordenesCompra={dataOrdenesCompra} cerrar={() => setShowAgregar(false)} />
                    }
                    <div className="card">
                        <div className="card-body">
                            <DataTable
                              columns={columns}
                              data={filteredData}
                              noHeader
                              responsive
                              pagination
                              paginationPerPage={30}
                              highlightOnHover
                            />
                        </div>
                    </div>
                    {selected && showEditar && (selected.estado === 1 || selected.estado === 3) &&(
                      <EditarOrdenCompra ordencompra={selected} proformas={dataProformas} proveedores={dataProveedores} incoterms={dataIncoTerms} puertos={dataPuertos} productos={dataProductos} documentos={dataDocumentos} usuarios={dataUsuarios} categorias={dataCategorias} especies={dataEspecies} medidas={dataMedidas} calidades={dataCalidades} clientes={dataClientes} almacenes={dataAlmacenes} cerrar={handleCloseEditar} guardar={handleSaveEditar}
                      />
                    )}
                    {selected && showVer &&(
                      <VerOrdenCompra ordencompra={selected} proformas={dataProformas} proveedores={dataProveedores} incoterms={dataIncoTerms} puertos={dataPuertos} productos={dataProductos} documentos={dataDocumentos} usuarios={dataUsuarios} categorias={dataCategorias} especies={dataEspecies} medidas={dataMedidas} calidades={dataCalidades} clientes={dataClientes} almacenes={dataAlmacenes} ordenescompra={dataOrdenesCompra} cerrar={handleCloseVerOC} guardar={handleSaveEditar} />
                    )}
                    {!showVer && !showEditar && showPDF && selected &&(
                        <GenerarPDF ordencompra={selected} proformas={dataProformas} proveedores={dataProveedores} incoterms={dataIncoTerms} puertos={dataPuertos} productos={dataProductos} documentos={dataDocumentos} usuarios={dataUsuarios} categorias={dataCategorias} especies={dataEspecies} medidas={dataMedidas} calidades={dataCalidades} clientes={dataClientes} almacenes={dataAlmacenes} ordenescompra={dataOrdenesCompra} cerrar={handleCloseVerOC}/>
                    )}
                    </>
                )}
                </div>
            </div>
        </div>
    )
}

export default OrdenesCompra