import React, { useState } from 'react';

const EditarShippingDoc = ({ cerrar, shippingDoc, guardar }) => {
    const [botonDeshabilitado, setBotonDeshabilitado] = useState(false);
    const [documentos, setDocumentos] = useState(shippingDoc.documentos.join(', '));
    const [destinos, setDestinos] = useState(shippingDoc.destinos.join(', '));
    const [contenidos, setContenidos] = useState(shippingDoc.contenidos.join(', '));
    const [proforma, setProforma] = useState(shippingDoc.proforma || '');
    const [direccion1, setDireccion1] = useState(shippingDoc.direccion1 || '');
    const [direccion2, setDireccion2] = useState(shippingDoc.direccion2 || '');

    const handleSubmit = (event) => {
        event.preventDefault();
        setBotonDeshabilitado(true);

        const updatedShippingDoc = {
            ...shippingDoc,
            documentos: documentos.split(',').map(Number),
            destinos: destinos.split(','),
            contenidos: contenidos.split(','),
            proforma: parseInt(proforma),
            direccion1,
            direccion2
        };

        guardar(updatedShippingDoc)
            .then(() => {
                cerrar();
            })
            .finally(() => {
                setBotonDeshabilitado(false);
            });
    };

    return (
        <div className='modal-overlay'>
            <div className='modal1'>
                <div className='card'>
                    <form onSubmit={handleSubmit}>
                        <div className='card-header'>
                            <h3 className='card-title' style={{ textAlign: 'center' }}>Editar Documento de Envío</h3>
                        </div>
                        <div className='card-body'>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Documentos</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese IDs de Documentos, separados por coma"
                                  value={documentos}
                                  onChange={(e) => setDocumentos(e.target.value)}
                                  required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Destinos</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese Destinos, separados por coma"
                                  value={destinos}
                                  onChange={(e) => setDestinos(e.target.value)}
                                  required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Contenidos</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese Contenidos, separados por coma"
                                  value={contenidos}
                                  onChange={(e) => setContenidos(e.target.value)}
                                  required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Proforma</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese ID de Proforma"
                                  value={proforma}
                                  onChange={(e) => setProforma(e.target.value)}
                                  required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Dirección 1</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese Dirección 1"
                                  value={direccion1}
                                  onChange={(e) => setDireccion1(e.target.value)}
                                  required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Dirección 2</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese Dirección 2"
                                  value={direccion2}
                                  onChange={(e) => setDireccion2(e.target.value)}
                                  required
                                />
                            </div>
                        </div>
                        <div className='card-footer' style={{ textAlign: 'center' }}>
                            <button type="submit" className="btn btn-outline-success mb-3 mt-3" disabled={botonDeshabilitado}>Guardar</button>
                            <button type="button" className="btn btn-outline-danger mb-3 mt-3" onClick={cerrar}>Cancelar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditarShippingDoc;
