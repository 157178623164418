import React, { useState, useEffect, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { IoTrashOutline } from "react-icons/io5";
import { LuPencilLine } from "react-icons/lu";
import AgregarCategoria from './AgregarCategoria';
import EditarCategoria from './EditarCategoria';

const SearchBar = ({ filterText, onFilter }) => (
    <div className='input-group mb-3'>
        <input
            type="text"
            placeholder="Buscar..."
            value={filterText}
            onChange={onFilter}
            className='form-control'
        />
    </div>
);

const ActionButtons = ({ onAgregar }) => (
    <div className="input-group mb-3">
        <button type="button" className="btn btn-outline-primary" onClick={onAgregar}>Agregar Calidad</button>
    </div>
);

function Calidades({ data, calidades }) {
    const [combined, setCombined] = useState([]);
    useEffect(() => {
        if (data && calidades) {
            const combinedData = [];
            for (const calidad of data.calidades) {
                let foundCalidad = calidades.find(c => parseInt(c.id_calidad) === parseInt(calidad));
                if (foundCalidad) {
                    combinedData.push(foundCalidad);
                } else {
                    combinedData.push({
                        id_calidad: calidad,
                        calidad: ''
                    });
                }
            }
            setCombined(combinedData);
        }
    }, [data, calidades]);

    const columns = [
        {
            name: 'ID_Calidad',
            cell: (row) => row.id_calidad
        },
        {
            name: 'Calidad',
            cell: (row) => row.calidad
        },
    ];

    return (
        <div>
            <DataTable
                columns={columns}
                data={combined}
                noHeader
            />
        </div>
    );
}

const Categorias = () => {
    const [dataCategorias, setDataCategorias] = useState([]);
    const [dataCalidades, setDataCalidades] = useState([]);
    const firstRender = useRef(true);
    const [showAgregarCategoria, setShowAgregarCategoria] = useState(false);
    const [showEditarCategoria, setShowEditarCategoria] = useState(false);
    const [selectedCategoria, setSelectedCategoria] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    const fetchData = async () => {
        const token = localStorage.getItem('token'); // Obtener token de autenticación
        try {
            const categoriaResponse = await fetch('https://api.dvstarlab.com/categorias', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (categoriaResponse.status === 401) {
                throw new Error('Unauthorized');
            }
            const categoriaData = await categoriaResponse.json();
            setDataCategorias(categoriaData);

            const calidadResponse = await fetch('https://api.dvstarlab.com/calidades', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const calidadData = await calidadResponse.json();
            setDataCalidades(calidadData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        if (firstRender.current) {
            fetchData();
            firstRender.current = false;
        }
    }, []);

    useEffect(() => {
        if (Array.isArray(dataCategorias)) {
            const filtered = dataCategorias.filter((row) =>
                Object.values(row).some((value) =>
                    value.toString().toLowerCase().includes(filterText.toLowerCase())
                )
            );
            setFilteredData(filtered);
        }
    }, [dataCategorias, filterText]);

    const columns = [
        {
            name: 'Editar',
            cell: (row) => <button className='btn btn-outline-primary' onClick={() => handleEditar(row)}><LuPencilLine /></button>,
        },
        {
            name: 'Nombre',
            selector: row => row.nombre ? row.nombre : 'Indefinido',
        },
        {
            name: 'Inglés',
            selector: row => row.nombre_ingles ? row.nombre_ingles : 'Indefinido'
        },
        {
            name: 'Portugués',
            selector: row => row.nombre_portugues ? row.nombre_portugues : 'Indefinido'
        },
        {
            name: 'Cantidad Calidades',
            selector: row => Array.isArray(row.calidades) ? row.calidades.length : row.calidades,
        },
        {
            name: 'Eliminar',
            cell: (row) => <button
                className='btn btn-outline-danger'
                onClick={() => {
                    if (window.confirm('¿Estás seguro?')) {
                        handleEliminar(row.id_categoria);
                    }
                }}
            >
                <IoTrashOutline />
            </button>,
            ignoreRowClick: true,
        },
    ];

    const handleAgregar = () => {
        setShowAgregarCategoria(true);
    };

    const handleEditar = (categoria) => {
        setSelectedCategoria(categoria);
        setShowEditarCategoria(true);
    };

    const handleSaveEditar = (editedCategoria) => {
        const token = localStorage.getItem('token'); // Obtener token de autenticación
        fetch(`https://api.dvstarlab.com/editarcategoria/${editedCategoria.id_categoria}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(editedCategoria),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Error al actualizar categoría');
                }
                return response.json();
            })
            .then(data => {
                console.log('Categoría actualizada:', data);
                alert('Categoría actualizada exitosamente');
                window.location.reload();
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    const handleCloseEditar = () => {
        setSelectedCategoria(null);
        setShowEditarCategoria(false);
    };

    const handleEliminar = (idCategoriaAEliminar) => {
        const token = localStorage.getItem('token'); // Obtener token de autenticación
        fetch(`https://api.dvstarlab.com/eliminarcategoria/${idCategoriaAEliminar}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (response.ok) {
                    alert('Categoría Eliminada Correctamente.');
                    window.location.reload();
                } else {
                    alert('Error al intentar eliminar la categoría.')
                }
            })
            .catch(error => {
                console.error('Error de red:', error);
            });
    };

    return (
        <div className='container-fluid'>
            <div className='card'>
                <div className='card-header'>
                    <h3 className='card-title' style={{ textAlign: 'center' }}>Categorías</h3>
                </div>
                <div className='card-body mb-2'>
                    <div className="input-group mb-3">
                        <input
                            type="text"
                            placeholder="Buscar..."
                            value={filterText}
                            onChange={(e) => setFilterText(e.target.value)}
                            className='form-control'
                        />
                    </div>
                    <div className="input-group mb-3">
                        <button type="button" className="btn btn-outline-primary" onClick={() => handleAgregar()}>Agregar Categoría</button>
                    </div>
                    {showAgregarCategoria &&
                        <AgregarCategoria cerrar={() => setShowAgregarCategoria(false)} calidades={dataCalidades} />
                    }
                    <div className="card">
                        <div className="card-body">
                            <DataTable
                                columns={columns}
                                data={filteredData}
                                noHeader
                                responsive
                                pagination
                                highlightOnHover
                                expandableRows
                                expandableRowsComponent={({ data }) => (
                                    <Calidades data={data} calidades={dataCalidades} />
                                )}
                            />
                        </div>
                    </div>
                    {selectedCategoria && showEditarCategoria && (
                        <EditarCategoria
                            categoria={selectedCategoria}
                            cerrar={handleCloseEditar}
                            guardar={handleSaveEditar}
                            calidades={dataCalidades}
                        />
                    )}
                </div>
            </div>
        </div>
    );

}

export default Categorias;
