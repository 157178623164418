// frontend/src/vistas/proveedores/Proveedores.js
import React, { useState, useEffect, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { IoTrashOutline } from "react-icons/io5";
import { LuPencilLine } from "react-icons/lu";
import AgregarProveedor from './AgregarProveedor';
import EditarProveedor from './EditarProveedor';
import * as XLSX from 'xlsx';

const Proveedores = () => {
    const [dataProveedores, setDataProveedores] = useState([]);
    const [opciones, setOpciones] = useState({
        productos: [],
        incoterms: [],
        puertos: [],
        documentos: [],
        almacenes: [],
    });
    const firstRender = useRef(true);
    const [showAgregar, setShowAgregar] = useState(false);
    const [showEditar, setShowEditar] = useState(false);
    const [selected, setSelected] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    const fetchData = async () => {
        const token = localStorage.getItem('token'); // Obtener token de autenticación
        try {
            const proveedorResponse = await fetch('https://api.dvstarlab.com/proveedores', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (proveedorResponse.status === 401) {
                throw new Error('Unauthorized');
            }
            const proveedorData = await proveedorResponse.json();
            setDataProveedores(proveedorData);

            const opcionesResponse = await fetch('https://api.dvstarlab.com/opciones', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (opcionesResponse.status === 401) {
                throw new Error('Unauthorized');
            }
            const opcionesData = await opcionesResponse.json();
            setOpciones(opcionesData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        if (firstRender.current) {
            fetchData();
            firstRender.current = false;
        }
    }, []);

    useEffect(() => {
        const filtered = dataProveedores.filter((row) =>
            Object.values(row).some((value) =>
                value?.toString().toLowerCase().includes(filterText.toLowerCase())
            )
        );
        setFilteredData(filtered);
    }, [dataProveedores, filterText]);

    const prepareDataForExcel = (data) => {
        return data.map((item) => {
            // Aquí convertimos cualquier array en una cadena de texto
            const newItem = { ...item }; // Crear una copia del objeto
            for (const key in newItem) {
                if (Array.isArray(newItem[key])) {
                    // Si el valor es un array, lo convertimos en una cadena separada por comas
                    newItem[key] = newItem[key].join(', ');
                }
            }
            return newItem;
        });
    };

    const exportToExcel = () => {
        const preparedData = prepareDataForExcel(dataProveedores); // Preparar los datos
        const worksheet = XLSX.utils.json_to_sheet(preparedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Proveedores");
        XLSX.writeFile(workbook, "Proveedores.xlsx");
    };

    const columns = [
        {
            name: 'Editar',
            cell: (row) => (
                <button className='btn btn-outline-primary' onClick={() => handleEditar(row)}>
                    <LuPencilLine />
                </button>
            ),
        },
        {
            name: 'Nombre',
            selector: row => row.nombre,
        },
        {
            name: 'Tax',
            selector: row => row.tax,
        },
        {
            name: 'Siglas',
            selector: row => row.siglas,
        },
        {
            name: 'Direccion',
            selector: row => row.direccion,
        },
        {
            name: 'Eliminar',
            cell: (row) => (
                <button
                    className='btn btn-outline-danger'
                    onClick={() => {
                        if (window.confirm('¿Estas seguro?')) {
                            handleEliminar(row.id_proveedor);
                        }
                    }}
                >
                    <IoTrashOutline />
                </button>
            ),
            ignoreRowClick: true,
        },
    ];

    const handleAgregar = () => {
        setShowAgregar(true);
    };

    const handleEditar = (proveedor) => {
        setSelected(proveedor);
        setShowEditar(true);
    };

    const handleSaveEditar = (editedProveedor) => {
        const token = localStorage.getItem('token'); // Obtener token de autenticación
        fetch(`https://api.dvstarlab.com/editarproveedor/${editedProveedor.id_proveedor}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(editedProveedor),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Error al actualizar proveedor');
            }
            return response.json();
        })
        .then(data => {
            console.log('Proveedor actualizado:', data);
            alert('Proveedor actualizado exitosamente');
            window.location.reload();
        })
        .catch(error => {
            console.error('Error:', error);
        });
    };

    const handleCloseEditar = () => {
        setSelected(null);
        setShowEditar(false);
    };

    const handleEliminar = (idProveedorAEliminar) => {
        const token = localStorage.getItem('token'); // Obtener token de autenticación
        fetch(`https://api.dvstarlab.com/eliminarproveedor/${idProveedorAEliminar}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
        .then(response => {
            if (response.ok) {
                alert('Proveedor Eliminado Correctamente.');
                window.location.reload();
            } else {
                alert('Error al intentar eliminar el proveedor.');
            }
        })
        .catch(error => {
            console.error('Error de red:', error);
        });
    };

    return (
        <div className='container-fluid'>
            <div className='card'>
                <div className='card-header'>
                    <h3 className='card-title' style={{ textAlign: 'center' }}>Proveedores</h3>
                </div>
                <div className='card-body mb-2'>
                    <div className="input-group mb-3">
                        <input
                            type="text"
                            placeholder="Buscar..."
                            value={filterText}
                            onChange={(e) => setFilterText(e.target.value)}
                            className='form-control'
                        />
                    </div>
                    <div className="input-group mb-3">
                        <button type="button" className="btn btn-outline-primary" onClick={handleAgregar}>Agregar Proveedor</button>
                        <button type="button" className="btn btn-outline-success" onClick={exportToExcel}>Exportar a Excel</button>
                    </div>
                    {showAgregar && <AgregarProveedor cerrar={() => setShowAgregar(false)} opciones={opciones} />}
                    <div className="card">
                        <div className="card-body">
                            <DataTable
                                columns={columns}
                                data={filteredData}
                                noHeader
                                responsive
                                pagination
                                highlightOnHover
                            />
                        </div>
                    </div>
                    {selected && showEditar && (
                        <EditarProveedor
                            proveedor={selected}
                            cerrar={handleCloseEditar}
                            guardar={handleSaveEditar}
                            opciones={opciones}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default Proveedores