// frontend/src/vistas/almacenes/EditarAlmacen.js
import React, { useState } from 'react';

const EditarAlmacen = ({ almacen, cerrar, guardar }) => {
    const [nombre, setNombre] = useState(almacen.nombre);
    const [direccion, setDireccion] = useState(almacen.direccion);
    const [email, setEmail] = useState(almacen.email);

    const handleSubmit = (event) => {
        event.preventDefault();
        const editedAlmacen = {
            ...almacen,
            nombre,
            direccion,
            email
        };
        guardar(editedAlmacen);
        cerrar();
    };

    return (
        <div className='modal-overlay'>
            <div className='modal1'>
                <div className='card'>
                    <form onSubmit={handleSubmit}>
                        <div className='card-header'>
                            <h3 className='card-title' style={{ textAlign: 'center' }}>Editar Almacen</h3>
                        </div>
                        <div className='card-body'>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Nombre</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese nombre del almacen"
                                  value={nombre}
                                  onChange={(e) => setNombre(e.target.value)}
                                  required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Dirección</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese dirección del almacen"
                                  value={direccion}
                                  onChange={(e) => setDireccion(e.target.value)}
                                  required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Email</span>
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="Ingrese email del almacen"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  required
                                />
                            </div>
                        </div>
                        <div className='card-footer' style={{ textAlign: 'center' }}>
                            <button 
                                type="submit" 
                                className="btn btn-outline-success mb-3 mt-3"
                            >
                                Guardar
                            </button>
                            <button type="button" className="btn btn-outline-danger" onClick={cerrar}>Cancelar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditarAlmacen;
