import React, { useState, useEffect, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { IoTrashOutline } from "react-icons/io5";
import { LuPencilLine } from "react-icons/lu";
import AgregarIncoTerm from './AgregarIncoTerm';
import EditarIncoTerm from './EditarIncoTerm';

const SearchBar = ({ filterText, onFilter }) => (
    <div className='input-group mb-3'>
        <input
            type="text"
            placeholder="Buscar..."
            value={filterText}
            onChange={onFilter}
            className='form-control'
        />
    </div>
);

const ActionButtons = ({ onAgregar }) => (
    <div className="input-group mb-3">
        <button type="button" className="btn btn-outline-primary" onClick={onAgregar}>Agregar IncoTerm</button>
    </div>
);

const IncoTerms = () => {
    const [dataIncoTerms, setDataIncoTerms] = useState([]);
    const firstRender = useRef(true);
    const [showAgregar, setShowAgregar] = useState(false);
    const [showEditar, setShowEditar] = useState(false);
    const [selected, setSelected] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [filteredData, setFilteredData] = useState([])

    const fetchData = async () => {
        const token = localStorage.getItem('token'); // Obtener token de autenticación
        try {
            const incoTermResponse = await fetch('https://api.dvstarlab.com/incoterms', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (incoTermResponse.status === 401) {
                throw new Error('Unauthorized');
            }
            const incoTermData = await incoTermResponse.json();
            setDataIncoTerms(incoTermData);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        if (firstRender.current) {
            fetchData();
            firstRender.current = false;
        }
    }, []);

    useEffect(() => {
        if (Array.isArray(dataIncoTerms)) {
            const filtered = dataIncoTerms.filter((row) =>
                Object.values(row).some((value) =>
                    value.toString().toLowerCase().includes(filterText.toLowerCase())
                )
            );
            setFilteredData(filtered);
        }
    }, [dataIncoTerms, filterText]);

    const columns = [
        {
            name: 'Editar',
            cell: (row) => <button className='btn btn-outline-primary' onClick={() => handleEditar(row)}><LuPencilLine /></button>,
        },
        {
            name: 'IncoTerm',
            selector: row => row.nombre,
        },
        {
            name: 'Descripción',
            selector: row => row.descripcion,
        },
        {
            name: 'Indicador',
            selector: row => row.indicador? row.indicador === 1 ? 'Puerto' : row.indicador === 2 ? 'ICD' : row.indicador === 3 ? 'Door Delivery' : 'Indefinido' : 'Indefinido'
        },
        {
            name: 'Eliminar',
            cell: (row) => <button
                className='btn btn-outline-danger'
                onClick={() => {
                    if(window.confirm('¿Estas seguro?')){
                        handleEliminar(row.id_incoterm);
                    }
                }}
                >
                    <IoTrashOutline />
                </button>,
                ignoreRowClick: true,
        },
    ];

    const handleAgregar = () => {
        setShowAgregar(true);
    }

    const handleEditar = (incoterm) => {
        setSelected(incoterm);
        setShowEditar(true);
    };

    const handleSaveEditar = (editedIncoTerm) => {
        const token = localStorage.getItem('token'); // Obtener token de autenticación
        fetch(`https://api.dvstarlab.com/editarincoterm/${editedIncoTerm.id_incoterm}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(editedIncoTerm),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Error al actualizar IncoTerm');
                }
                return response.json();
            })
            .then(data => {
                console.log('IncoTerm actualizado:', data);
                alert('IncoTerm actualizado exitosamente');
                window.location.reload();
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    const handleCloseEditar = () => {
        setSelected(null);
        setShowEditar(false);
    };

    const handleEliminar = (idIncoTermAEliminar) => {
        const token = localStorage.getItem('token'); // Obtener token de autenticación
        fetch(`https://api.dvstarlab.com/eliminarincoterm/${idIncoTermAEliminar}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (response.ok) {
                    alert('IncoTerm Eliminado Correctamente.');
                    window.location.reload();
                } else {
                    alert('Error al intentar eliminar el IncoTerm.')
                }
            })
            .catch(error => {
                console.error('Error de red:', error);
            });
    };

    return (
        <div className='container-fluid'>
            <div className='card'>
                <div className='card-header'>
                    <h3 className='card-title' style={{ textAlign: 'center' }}>IncoTerms</h3>
                </div>
                <div className='card-body mb-2'>
                    <div className="input-group mb-3">
                        <input
                        type="text"
                        placeholder="Buscar..."
                        value={filterText}
                        onChange={(e) => setFilterText(e.target.value)}
                        className='form-control'
                        />
                    </div>
                    <div className="input-group mb-3">
                        <button type="button" className="btn btn-outline-primary"onClick={() => handleAgregar()}>Agregar IncoTerm</button>
                    </div>
                    {showAgregar && 
                      <AgregarIncoTerm cerrar={() => setShowAgregar(false)} />
                    }
                    <div className="card">
                        <div className="card-body">
                            <DataTable
                              columns={columns}
                              data={filteredData}
                              noHeader
                              responsive
                              pagination
                              highlightOnHover
                            />
                        </div>
                    </div>
                    {selected && showEditar &&(
                      <EditarIncoTerm
                        incoterm={selected}
                        cerrar={handleCloseEditar}
                        guardar={handleSaveEditar}
                      />
                    )}
                </div>
            </div>
        </div>
    )
}

export default IncoTerms