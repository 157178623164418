import React, { useState } from 'react';

const AgregarUsuario = ({ cerrar }) => {
    const [botonDeshabilitado, setBotonDeshabilitado] = useState(false);
    const [nombre, setNombre] = useState('');
    const [rol, setRol] = useState(0);
    const [correo, setCorreo] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [area, setArea] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!correo.endsWith('@palmtradechile.com')) {
            alert("Correo no Valido");
            return;
        }
        if (password !== password2) {
            alert('Las contraseñas no coinciden');
            return;
        }
        setBotonDeshabilitado(true);

        const nuevoUsuario = {
            nombre,
            rol,
            correo,
            password,
            area
        };

        const token = localStorage.getItem('token'); // Obtener token de autenticación

        fetch('https://api.dvstarlab.com/agregarusuario', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(nuevoUsuario)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Error al agregar usuario');
            }
            return response.json();
        })
        .then(data => {
            console.log('Usuario agregado:', data);
            alert('Usuario ' + nombre + ' agregado exitosamente');
            window.location.reload();
        })
        .catch(error => {
            console.error('Error:', error);
        })
        .finally(() => {
            setBotonDeshabilitado(false);
        });
    };

    const handleRadioChange = (event) => {
        const selectedValue = parseInt(event.target.value);
        setRol(selectedValue);
    };

    return(
        <div className='modal-overlay'>
            <div className='modal1'>
                <div className='card'>
                    <form onSubmit={handleSubmit}>
                        <div className='card-header'>
                            <h3 className='card-title' style={{ textAlign: 'center' }}>Agregar Usuario</h3>
                        </div>
                        <div className='card-body'>
                            <div className='input-group mb-3'>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value={1} checked={rol === 1} onChange={handleRadioChange}/>
                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                        Administrador
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value={2} checked={rol === 2} onChange={handleRadioChange}/>
                                    <label className="form-check-label" htmlFor="flexRadioDefault2">
                                        Operario OffShore
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" value={3} checked={rol === 3} onChange={handleRadioChange}/>
                                    <label className="form-check-label" htmlFor="flexRadioDefault3">
                                        Operario Nacional
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4" value={4} checked={rol === 4} onChange={handleRadioChange}/>
                                    <label className="form-check-label" htmlFor="flexRadioDefault4">
                                        Operario Comercial
                                    </label>
                                </div>
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Nombre</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese nombre usuario"
                                  value={nombre}
                                  onChange={(e) => setNombre(e.target.value)}
                                  required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Área</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese área usuario"
                                  value={area}
                                  onChange={(e) => setArea(e.target.value)}
                                  required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Correo</span>
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="Ingrese correo usuario"
                                  value={correo}
                                  onChange={(e) => setCorreo(e.target.value)}
                                  required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Contraseña</span>
                                <input
                                  type="password"
                                  className="form-control"
                                  placeholder="Ingrese contraseña"
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                  required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Confirmar contraseña</span>
                                <input
                                  type="password"
                                  className="form-control"
                                  placeholder="Confirme contraseña"
                                  value={password2}
                                  onChange={(e) => setPassword2(e.target.value)}
                                  required
                                />
                            </div>
                        </div>
                        <div className='card-footer' style={{ textAlign: 'center' }}>
                            <button 
                                type="submit" 
                                className="btn btn-outline-success mb-3 mt-3"
                                disabled={botonDeshabilitado}
                            >
                                Agregar
                            </button>
                            <button type="button" className="btn btn-outline-danger" onClick={cerrar}>Cancelar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AgregarUsuario;
