import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { Spinner } from 'react-bootstrap';
import { LuPencilLine } from "react-icons/lu";
import { IoTrashOutline } from "react-icons/io5";

const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

function EditarNotaFiscal({ notaFiscal, cerrar, guardar, proveedores }) {
    const [botonDeshabilitado, setBotonDeshabilitado] = useState(false);
    const [nroNF, setNroNF] = useState(notaFiscal.nro_nf || '');
    const [tipoNF, setTipoNF] = useState(notaFiscal.tipo_nf || '');
    const [llave, setLlave] = useState(notaFiscal.llave || '');
    const [proveedor, setProveedor] = useState(notaFiscal.id_proveedor || '');
    const [emision, setEmision] = useState(formatDate(notaFiscal.emision));
    const [salida, setSalida] = useState(formatDate(notaFiscal.salida));
    const [cnpj, setCnpj] = useState(notaFiscal.cnpj || '');
    const [valorProductos, setValorProductos] = useState(notaFiscal.valor_productos || 0);
    const [valorTotal, setValorTotal] = useState(notaFiscal.valor_total || 0);
    const [pkg, setPkg] = useState(notaFiscal.pkg || '');
    const [descuento, setDescuento] = useState(notaFiscal.descuento || '');
    const [detalles, setDetalles] = useState([]);
    const [loadingDetalles, setLoadingDetalles] = useState(true);
    const [nuevoDetalle, setNuevoDetalle] = useState({
        producto: '', calidad: '', medida: '', ncm: '', m3: '', usd_m3: '', descuento: '', total: ''
    });
    const [editingDetalle, setEditingDetalle] = useState(null);
    const [editingIndex, setEditingIndex] = useState(null);

    const handleSubmit = (event) => {
        event.preventDefault();
        setBotonDeshabilitado(true);

        const editedNotaFiscal = {
            id_nota_fiscal: notaFiscal.id_nota_fiscal,
            nro_nf: nroNF,
            tipo_nf: tipoNF,
            llave,
            id_proveedor: proveedor,
            emision,
            salida,
            cnpj,
            valor_productos: parseFloat(valorProductos),
            valor_total: parseFloat(valorTotal),
            pkg,
            descuento,
            detalles: detalles.map(d => ({ ...d }))
        };

        guardar(editedNotaFiscal);
    };

    useEffect(() => {
        if (notaFiscal && notaFiscal.id_nota_fiscal && loadingDetalles) {
            fetchDetalles(notaFiscal.id_nota_fiscal);
        }
    }, [notaFiscal]);

    const fetchDetalles = async (idNotaFiscal) => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`https://api.dvstarlab.com/notasfiscales/${idNotaFiscal}/detalles`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            const data = await response.json();
            setDetalles(data);
            setLoadingDetalles(false);
        } catch (error) {
            console.error('Error al obtener detalles:', error);
            setLoadingDetalles(false);
        }
    };

    const columns = [
        { name: 'Producto', selector: row => row.producto },
        { name: 'Calidad', selector: row => row.calidad },
        { name: 'Medida', selector: row => row.medida },
        { name: 'NCM', selector: row => row.ncm },
        { name: 'M3', selector: row => row.m3 },
        { name: 'USD/M3', selector: row => row.usd_m3 },
        { name: 'Total', selector: row => row.total },
        { name: 'Descuento', selector: row => row.descuento },
        {
            name: 'Acciones',
            cell: row => (
                <>
                    <button
                        type="button"
                        className="btn btn-outline-primary me-2"
                        onClick={() => handleEditDetalle(row, detalles.indexOf(row))}
                    >
                        <LuPencilLine />
                    </button>
                    <button
                        type="button"
                        className="btn btn-outline-danger"
                        onClick={() => handleDeleteDetalle(detalles.indexOf(row))}
                    >
                        <IoTrashOutline />
                    </button>
                </>
            ),
            ignoreRowClick: true,
        },
    ];

    const handleAgregarDetalle = () => {
        setDetalles([...detalles, nuevoDetalle]);
        setNuevoDetalle({
            producto: '', calidad: '', medida: '', ncm: '', m3: '', usd_m3: '', descuento: '', total: ''
        });
    };

    const handleEditDetalle = (detalle, index) => {
        setEditingDetalle(detalle);
        setEditingIndex(index);
    };

    const handleSaveDetalleEdit = () => {
        const updatedDetalles = [...detalles];
        updatedDetalles[editingIndex] = { ...editingDetalle };
        setDetalles(updatedDetalles);
        setEditingDetalle(null);
        setEditingIndex(null);
    };

    const handleDeleteDetalle = (index) => {
        const updatedDetalles = detalles.filter((_, i) => i !== index);
        setDetalles(updatedDetalles);
    };

    const handleDetalleChange = (field, value) => {
        if (editingDetalle) {
            setEditingDetalle({ ...editingDetalle, [field]: value });
        } else {
            setNuevoDetalle({ ...nuevoDetalle, [field]: value });
        }
    };

    return (
        <div className='modal-overlay'>
            <div className='modal1'>
                <div className='card'>
                    <form onSubmit={handleSubmit}>
                        <div className='card-header'>
                            <h3 className='card-title' style={{ textAlign: 'center' }}>Editar Nota Fiscal</h3>
                        </div>
                        <div className='card-body'>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Número NF</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={nroNF}
                                    onChange={(e) => setNroNF(e.target.value)}
                                    required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Tipo NF</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={tipoNF}
                                    onChange={(e) => setTipoNF(e.target.value)}
                                    required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Llave</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={llave}
                                    onChange={(e) => setLlave(e.target.value)}
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Proveedor</span>
                                <select 
                                    className="form-control"
                                    value={proveedor}
                                    onChange={(e) => setProveedor(e.target.value)}
                                    required
                                >
                                    <option value="">Seleccionar Proveedor</option>
                                    {proveedores?.map(prov => (
                                        <option key={prov.id_proveedor} value={prov.id_proveedor}>
                                            {prov.nombre}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Fecha Emisión</span>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={emision}
                                    onChange={(e) => setEmision(e.target.value)}
                                    required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Fecha Salida</span>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={salida}
                                    onChange={(e) => setSalida(e.target.value)}
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Valor Productos</span>
                                <input
                                    type="number"
                                    className="form-control"
                                    value={valorProductos}
                                    onChange={(e) => setValorProductos(e.target.value)}
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Valor Total</span>
                                <input
                                    type="number"
                                    className="form-control"
                                    value={valorTotal}
                                    onChange={(e) => setValorTotal(e.target.value)}
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Paquetes</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={pkg}
                                    onChange={(e) => setPkg(e.target.value)}
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Descuento</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={descuento}
                                    onChange={(e) => setDescuento(e.target.value)}
                                />
                            </div>
                            
                            {/* Detalles */}
                            <div className='mb-3'>
                                <h5>Detalles</h5>
                                <div className='input-group mb-3'>
                                    <input type="text" placeholder="Producto" value={editingDetalle ? editingDetalle.producto : nuevoDetalle.producto} onChange={(e) => handleDetalleChange('producto', e.target.value)} className='form-control' />
                                    <input type="text" placeholder="Calidad" value={editingDetalle ? editingDetalle.calidad : nuevoDetalle.calidad} onChange={(e) => handleDetalleChange('calidad', e.target.value)} className='form-control' />
                                    <input type="text" placeholder="Medida" value={editingDetalle ? editingDetalle.medida : nuevoDetalle.medida} onChange={(e) => handleDetalleChange('medida', e.target.value)} className='form-control' />
                                    <input type="text" placeholder="NCM" value={editingDetalle ? editingDetalle.ncm : nuevoDetalle.ncm} onChange={(e) => handleDetalleChange('ncm', e.target.value)} className='form-control' />
                                    <input type="number" placeholder="M3" value={editingDetalle ? editingDetalle.m3 : nuevoDetalle.m3} onChange={(e) => handleDetalleChange('m3', e.target.value)} className='form-control' />
                                    <input type="number" placeholder="USD/M3" value={editingDetalle ? editingDetalle.usd_m3 : nuevoDetalle.usd_m3} onChange={(e) => handleDetalleChange('usd_m3', e.target.value)} className='form-control' />
                                    <input type="number" placeholder="Total" value={editingDetalle ? editingDetalle.total : nuevoDetalle.total} onChange={(e) => handleDetalleChange('total', e.target.value)} className='form-control' />
                                    <input type="number" placeholder="Descuento" value={editingDetalle ? editingDetalle.descuento : nuevoDetalle.descuento} onChange={(e) => handleDetalleChange('descuento', e.target.value)} className='form-control' />
                                    {editingDetalle ? (
                                        <button type="button" onClick={handleSaveDetalleEdit} className="btn btn-outline-success">Guardar</button>
                                    ) : (
                                        <button type="button" onClick={handleAgregarDetalle} className="btn btn-outline-primary" disabled={!nuevoDetalle.producto}>Agregar Detalle</button>
                                    )}
                                </div>
                                {loadingDetalles ? (
                                <div className="text-center">
                                    <Spinner animation="border" role="status" />
                                </div>
                                ) : (
                                    <DataTable 
                                        columns={columns} 
                                        data={detalles} 
                                        highlightOnHover 
                                        dense 
                                        pagination 
                                    />
                                )}
                            </div>
                        </div>
                        <div className='card-footer' style={{ textAlign: 'center' }}>
                            <button 
                                type="submit" 
                                className="btn btn-outline-success mb-3 mt-3"
                                disabled={botonDeshabilitado}
                            >
                                Guardar
                            </button>
                            <button type="button" className="btn btn-outline-danger" onClick={cerrar}>Cancelar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default EditarNotaFiscal;
