import React, { useState, useEffect } from 'react';
import { FaHome, FaFileUpload, FaShoppingCart, FaList, FaTree, FaShoppingBag, FaFileInvoice, FaUser, FaWarehouse, FaFileAlt, FaUserTie, FaHandHoldingUsd, FaBoxOpen, FaIndustry, FaRulerCombined, FaShip, FaUserFriends, FaFileSignature, FaChartBar, FaBoxes, FaStore, FaCreditCard, FaTools, FaClipboardList, FaTruck, FaBuilding, FaReceipt } from 'react-icons/fa';
import { IoMdLogOut } from "react-icons/io";

const Logout = ({ hover }) => {
    return (
        <div className="logout-container">
            <div className="nav-item d-flex align-items-center user-info">
                {hover ? (
                    <span className="nav-link" style={{ cursor: 'default', textAlign: 'center' }}>
                        {localStorage.getItem('Nombre')}
                    </span>
                ) : (
                    <FaUser className="nav-link" />
                )}
            </div>
            <div className="nav-item">
                <button 
                    className="btn btn-outline-danger mt-2 logout-button" 
                    onClick={() => {
                        localStorage.removeItem('token');
                        localStorage.removeItem('loggedIn');
                        localStorage.removeItem('Nombre');
                        localStorage.removeItem('Rol');
                        localStorage.removeItem('ID');
                        window.location.href = '/';
                    }}>
                    {hover ? (
                        <span className='nav-link'style={{ cursor: 'default' }}>Cerrar Sesión</span>
                    ) : (
                        <IoMdLogOut />
                    )}
                </button>
            </div>
        </div>
    );
};

const generateID = (label) => {
    return `collapse${label.replace(/\s+/g, '').replace(/[^\w\s]/gi, '')}`;
}

const MenuItem = ({label, href, onClick, icon}) => {
    const handleClick = () => {
        if(onClick){
            onClick();
        } else {
            window.location.href = href;
        }
    }

    return(
        <button type="button" className="list-group-item list-group-item-action d-flex align-items-center" id='Menu-Item' onClick={handleClick}>
            <span className="me-2">{icon}</span>
            <span className="menu-label">{label}</span>
        </button>
    )
}

const SubMenu = ({ label, items, icon, expanded, setExpanded }) => {
    const collapseID = generateID(label);
    const isOpen = expanded[collapseID] || false;

    const toggleSubMenu = () => {
        setExpanded(prevState => ({ ...prevState, [collapseID]: !isOpen }));
    };

    return (
        <>
            <button type="button" className="list-group-item list-group-item-action d-flex align-items-center" onClick={toggleSubMenu}>
                <span className="me-2">{icon}</span>
                <span className="menu-label">{label}</span>
            </button>
            <div className={`collapse ${isOpen ? 'show' : ''}`} id={collapseID}>
                {items.map((item, index) => (
                    item.subItems ? (
                        <SubMenu key={index} label={item.label} items={item.subItems} icon={item.icon} expanded={expanded} setExpanded={setExpanded} />
                    ) : (
                        <MenuItem key={index} label={item.label} href={item.href} icon={item.icon} />
                    )
                ))}
            </div>
        </>
    );
};

const Menu = ({ onHoverChange }) => {
    const [hover, setHover] = useState(false);
    const [expanded, setExpanded] = useState({});

    useEffect(() => {
        if (!hover) {
            setExpanded({});
        }
    }, [hover]);

    const handleMouseEnter = () => {
        setHover(true);
        onHoverChange(true);
    };

    const handleMouseLeave = () => {
        setHover(false);
        onHoverChange(false);
    };

    return (
        <div className="menu-container" 
             onMouseEnter={handleMouseEnter}
             onMouseLeave={handleMouseLeave}
             style={{ width: hover ? '250px' : '70px', transition: 'width 0.3s', display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <div className="list-group flex-grow-1" style={{ width: '100%', maxHeight: '80vh', overflowY: 'auto' }}>
                <MenuItem label="Volver al inicio" href="/home" icon={<FaHome />} />
                <SubMenu label="Generar" items={[
                    { label: 'Ordenes Compra', href: '/ordenescompra', icon: <FaShoppingCart /> },
                    { label: 'Proformas Invoice', href: '/proformas', icon: <FaFileAlt /> },
                ]} icon={<FaList />} expanded={expanded} setExpanded={setExpanded} />
                <SubMenu label="Lector" items={[
                    { label: 'Lector Archivos', href: '/file', icon: <FaShoppingCart /> },
                ]} icon={<FaList />} expanded={expanded} setExpanded={setExpanded} />
                <SubMenu label="Registros" items={[
                    { label: 'Almacenes', href: '/almacenes', icon: <FaWarehouse /> },
                    { label: 'Carta de crédito', href: '/cartascredito', icon: <FaCreditCard /> },
                    { label: 'Clientes', href: '/clientes', icon: <FaUserTie /> },
                    { label: 'IncoTerms', href: '/incoterms', icon: <FaFileSignature /> },
                    { label: 'Línea de Crédito', href: '/lineascredito', icon: <FaHandHoldingUsd /> },
                    { label: 'Notas Fiscales', href: '/notasfiscales' },
                    { label: 'Productos', href: '#', subItems: [
                        { label: 'Calidades', href: '/calidades', icon: <FaBoxes /> },
                        { label: 'Categorias', href: '/categorias', icon: <FaClipboardList /> },
                        { label: 'Especies', href: '/especies', icon: <FaTree /> },
                        { label: 'Medidas', href: '/medidas', icon: <FaRulerCombined /> },
                        { label: 'Productos', href: '/productos', icon: <FaBoxOpen /> }
                    ], icon: <FaIndustry /> },
                    { label: 'Payment Terms', href: '/paymentterms', icon: <FaFileSignature /> },
                    { label: 'Proveedores', href: '/proveedores', icon: <FaBuilding /> },
                    { label: 'Puertos', href: '/puertos', icon: <FaShip /> },
                    { label: 'ShippingDocs', href: '/shippingdocs', icon: <FaFileAlt /> },
                    { label: 'Sub-Clientes', href: '/subclientes', icon: <FaUserFriends /> },
                    { label: 'Tipos de Documentos', href: '/documentos', icon: <FaFileAlt /> },
                    { label: 'Usuarios', href: '/usuarios', icon: <FaUser /> }
                ]} icon={<FaList />} expanded={expanded} setExpanded={setExpanded} />
            </div>
            <Logout hover={hover}/>
        </div>
    );
};

export default Menu;