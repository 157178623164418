import React, { useState, useEffect, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { IoTrashOutline } from "react-icons/io5";
import { LuPencilLine } from "react-icons/lu";
import AgregarCalidad from './AgregarCalidad';
import EditarCalidad from './EditarCalidad';

const SearchBar = ({ filterText, onFilter }) => (
    <div className='input-group mb-3'>
        <input
            type="text"
            placeholder="Buscar..."
            value={filterText}
            onChange={onFilter}
            className='form-control'
        />
    </div>
);

const ActionButtons = ({ onAgregar }) => (
    <div className="input-group mb-3">
        <button type="button" className="btn btn-outline-primary" onClick={onAgregar}>Agregar Calidad</button>
    </div>
);

const Calidades = () => {
    const [dataCalidades, setDataCalidades] = useState([]);
    const firstRender = useRef(true);
    const [showAgregar, setShowAgregar] = useState(false);
    const [showEditar, setShowEditar] = useState(false);
    const [selected, setSelected] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    const fetchData = async () => {
        const token = localStorage.getItem('token'); // Obtener token de autenticación
        try {
            const calidadResponse = await fetch('https://api.dvstarlab.com/calidades', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (calidadResponse.status === 401) {
                throw new Error('Unauthorized');
            }
            const calidadData = await calidadResponse.json();
            setDataCalidades(calidadData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        if (firstRender.current) {
            fetchData();
            firstRender.current = false;
        }
    }, []);

    useEffect(() => {
        if (Array.isArray(dataCalidades)) {
            const filtered = dataCalidades.filter((row) =>
                Object.values(row).some((value) =>
                    value.toString().toLowerCase().includes(filterText.toLowerCase())
                )
            );
            setFilteredData(filtered);
        }
    }, [dataCalidades, filterText]);

    const columns = [
        {
            name: 'Editar',
            cell: (row) => (
                <button className='btn btn-outline-primary' onClick={() => handleEditar(row)}>
                    <LuPencilLine />
                </button>
            ),
        },
        {
            name: 'Calidad',
            selector: row => row.calidad,
        },
        {
            name: 'Eliminar',
            cell: (row) => (
                <button
                    className='btn btn-outline-danger'
                    onClick={() => {
                        if (window.confirm('¿Estas seguro?')) {
                            handleEliminar(row.id_calidad);
                        }
                    }}
                >
                    <IoTrashOutline />
                </button>
            ),
            ignoreRowClick: true,
        },
    ];

    const handleAgregar = () => {
        setShowAgregar(true);
    };

    const handleEditar = (calidad) => {
        setSelected(calidad);
        setShowEditar(true);
    };

    const handleSaveEditar = (edited) => {
        const token = localStorage.getItem('token'); // Obtener token de autenticación
        fetch(`https://api.dvstarlab.com/editarcalidad/${edited.id_calidad}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(edited),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Error al actualizar datos');
            }
            return response.json();
        })
        .then(data => {
            alert('Datos actualizados exitosamente');
            fetchData();
        })
        .catch(error => {
            console.error('Error:', error);
        });
    };

    const handleCloseEditar = () => {
        setSelected(null);
        setShowEditar(false);
    };

    const handleEliminar = (idAEliminar) => {
        const token = localStorage.getItem('token'); // Obtener token de autenticación
        fetch(`https://api.dvstarlab.com/eliminarcalidad/${idAEliminar}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
        .then(response => {
            if (response.ok) {
                alert('Datos Eliminados Correctamente.');
                fetchData();
            } else {
                alert('Error al intentar eliminar datos.');
            }
        })
        .catch(error => {
            console.error('Error de red:', error);
        });
    };

    return (
        <div className='container-fluid'>
            <div className='card'>
                <div className='card-header'>
                    <h3 className='card-title' style={{ textAlign: 'center' }}>Calidades</h3>
                </div>
                <div className='card-body mb-2'>
                    <SearchBar filterText={filterText} onFilter={(e) => setFilterText(e.target.value)} />
                    <ActionButtons onAgregar={handleAgregar} />
                    {showAgregar && <AgregarCalidad cerrar={() => setShowAgregar(false)} />}
                    <div className="card">
                        <div className="card-body">
                            <DataTable
                                columns={columns}
                                data={filteredData}
                                noHeader
                                responsive
                                pagination
                                highlightOnHover
                            />
                        </div>
                    </div>
                    {selected && showEditar && (
                        <EditarCalidad
                            calidad={selected}
                            cerrar={handleCloseEditar}
                            guardar={handleSaveEditar}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default Calidades