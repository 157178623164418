import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';

function EditarMedida({ medida, cerrar, guardar }) {
    const [botonDeshabilitado, setBotonDeshabilitado] = useState(false);
    const [medidaValue, setMedidaValue] = useState(medida.medida || '');
    const [ancho, setAncho] = useState(medida.ancho || 0);
    const [anchor, setAnchor] = useState(medida.anchor || 0);
    const [anchop, setAnchop] = useState(medida.anchop || 0);
    const [largo, setLargo] = useState(medida.largo || 0);
    const [largor, setLargor] = useState(medida.largor || 0);
    const [largop, setLargop] = useState(medida.largop || 0);
    const [plies, setPlies] = useState(medida.plies || 0);
    const [espesores, setEspesores] = useState([]);

    // Estados temporales para los campos de espesor
    const [tempEspesor, setTempEspesor] = useState(0);
    const [tempEspesorr, setTempEspesorr] = useState(0);
    const [tempEspesorp, setTempEspesorp] = useState(0);

    useEffect(() => {
        // Inicializar la lista de espesores con los valores existentes
        if (medida.espesor && medida.espesorr && medida.espesorp) {
            const existingEspesores = medida.espesor.map((espesor, index) => ({
                espesor: espesor,
                espesorr: medida.espesorr[index] || '',
                espesorp: medida.espesorp[index] || ''
            }));
            setEspesores(existingEspesores);
        }
    }, [medida]);

    const agregarEspesor = () => {
        if (tempEspesor && tempEspesorr && tempEspesorp) {
            setEspesores([
                ...espesores,
                { espesor: tempEspesor, espesorr: tempEspesorr, espesorp: tempEspesorp },
            ]);
            // Limpiar campos temporales después de agregar
            setTempEspesor(0);
            setTempEspesorr(0);
            setTempEspesorp(0);
        } else {
            alert('Por favor, complete todos los campos de espesor antes de agregar.');
        }
    };

    const eliminarEspesor = (index) => {
        const newEspesores = [...espesores];
        newEspesores.splice(index, 1);
        setEspesores(newEspesores);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setBotonDeshabilitado(true);

        const espesorArray = espesores.map(item => parseFloat(item.espesor));
        const espesorrArray = espesores.map(item => parseFloat(item.espesorr));
        const espesorpArray = espesores.map(item => parseFloat(item.espesorp));

        const editedMedida = {
            ...medida,
            medida: medidaValue,
            ancho,
            anchor,
            anchop,
            largo,
            largor,
            largop,
            espesor: espesorArray,
            espesorr: espesorrArray,
            espesorp: espesorpArray,
            plies,
        };

        guardar(editedMedida);
        cerrar();
    };

    const columns = [
        {
            name: 'Espesor',
            selector: row => row.espesor,
            sortable: true,
        },
        {
            name: 'Espesor Real',
            selector: row => row.espesorr,
            sortable: true,
        },
        {
            name: 'Espesor en Pulgadas',
            selector: row => row.espesorp? row.espesorp : 0,
            sortable: true,
        },
        {
            cell: (row, index) => (
                <button
                    type="button"
                    className="btn btn-outline-danger"
                    onClick={() => eliminarEspesor(index)}
                >
                    Eliminar
                </button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
    ];

    return (
        <div className='modal-overlay'>
            <div className='modal1'>
                <div className='card'>
                    <form onSubmit={handleSubmit}>
                        <div className='card-header'>
                            <h3 style={{ textAlign: 'center' }}>Editar Medida</h3>
                        </div>
                        <div className='card-body'>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Medida</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese medida"
                                  value={medidaValue}
                                  onChange={(e) => setMedidaValue(e.target.value)}
                                  required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Ancho</span>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Ingrese ancho"
                                  value={ancho}
                                  onChange={(e) => setAncho(e.target.value)}
                                  required
                                />
                                <span className='input-group-text'>Ancho Real</span>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Ingrese ancho real"
                                  value={anchor}
                                  onChange={(e) => setAnchor(e.target.value)}
                                  required
                                />
                                <span className='input-group-text'>Ancho en Pulgadas</span>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Ingrese ancho en pulgadas"
                                  value={anchop}
                                  onChange={(e) => setAnchop(e.target.value)}
                                  required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Largo</span>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Ingrese largo"
                                  value={largo}
                                  onChange={(e) => setLargo(e.target.value)}
                                  required
                                />
                                <span className='input-group-text'>Largo Real</span>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Ingrese largo real"
                                  value={largor}
                                  onChange={(e) => setLargor(e.target.value)}
                                  required
                                />
                                <span className='input-group-text'>Largo en Pulgadas</span>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Ingrese largo en pulgadas"
                                  value={largop}
                                  onChange={(e) => setLargop(e.target.value)}
                                  required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Plies</span>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Ingrese plies"
                                  value={plies}
                                  onChange={(e) => setPlies(e.target.value)}
                                  required
                                />
                            </div>

                            {/* Campos temporales para ingresar el nuevo espesor */}
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Espesor</span>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Ingrese espesor"
                                  value={tempEspesor}
                                  onChange={(e) => setTempEspesor(e.target.value)}
                                />
                                <span className='input-group-text'>Espesor Real</span>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Ingrese espesor real"
                                  value={tempEspesorr}
                                  onChange={(e) => setTempEspesorr(e.target.value)}
                                />
                                <span className='input-group-text'>Espesor en Pulgadas</span>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Ingrese espesor en pulgadas"
                                  value={tempEspesorp}
                                  onChange={(e) => setTempEspesorp(e.target.value)}
                                />
                                <button 
                                  type="button" 
                                  onClick={agregarEspesor} 
                                  className="btn btn-outline-primary"
                                >
                                  Agregar Espesor
                                </button>
                            </div>

                            {/* DataTable para mostrar los espesores */}
                            <div className='mt-4'>
                                <DataTable
                                  title="Lista de Espesores"
                                  columns={columns}
                                  data={espesores}
                                  highlightOnHover
                                  dense
                                  pagination
                                />
                            </div>

                        </div>
                        <div className='card-footer' style={{ textAlign: 'center' }}>
                            <button type="submit" className="btn btn-outline-success mb-3 mt-3" disabled={botonDeshabilitado}>Guardar</button>
                            <button type="button" className="btn btn-outline-danger mb-3 mt-3" onClick={cerrar}>Cancelar</button> 
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default EditarMedida;
